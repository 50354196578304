import * as React from "react";
import * as api from "src/api";
import { FormQuestionOptions } from "./FormQuestionOptions";
import { FormQuestionText } from "./FormQuestionText";
import { FormQuestionProps } from "./types";

export const FormQuestion = React.memo(
  <T extends api.t.FormQuestionFragment>(props: FormQuestionProps<T>) => {
    switch (props.question.__typename) {
      case "FormQuestionText": {
        return (
          <FormQuestionText
            {...props}
            onUpdate={(data) => props.onUpdate(data as Partial<T>)}
            question={props.question as api.t.FormQuestionTextFragment}
          />
        );
      }
      case "FormQuestionSingleChoice":
      case "FormQuestionMultiChoice": {
        return (
          <FormQuestionOptions
            {...props}
            onUpdate={(data) => props.onUpdate(data as Partial<T>)}
            question={
              props.question as
                | api.t.FormQuestionMultiChoiceFragment
                | api.t.FormQuestionSingleChoiceFragment
            }
          />
        );
      }
    }
  }
);
