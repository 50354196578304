import * as _ from "lodash";
import defaultConfig from "./default";
import * as configs from "./env";
import { Environment } from "./config";

const envName = process.env.NODE_ENV as Environment;
const config = configs[envName] || defaultConfig;

console.log("Current environment: ", envName);

function resolveValue(val: any) {
  if (_.isString(val)) {
    if (val.startsWith("@@")) {
      const varName = val.replace(/^@@/, "");
      return process.env[varName];
    }
    return val;
  } else if (_.isObject(val)) {
    return resolveProps(val);
  } else {
    return val;
  }
}

function resolveProps<T>(obj: T): T {
  const keys = Object.keys(obj);
  return keys.reduce(
    (newObj, key) => ({
      ...(newObj as any),
      [key]: resolveValue((obj as any)[key])
    }),
    obj
  );
}

export default resolveProps(_.merge(defaultConfig, config));
