import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { s } from "../../../localization";
import * as _ from "lodash";
import * as userApi from "../../../logic/user";
import { Redirect } from "react-router-dom";
import * as notification from "../../notification";
import { WithQuery } from "../../components/enhancers";
import * as routes from "../routes";
import logo from "../../images/base_logo_transparent_background.png";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

const LoginPanel = (props: WithQuery) => {
  const { query } = props;

  const [busy, setBusy] = React.useState(false);
  const [email, setEmail] = React.useState((query.email || "") as string);
  const [state, setState] = React.useState<"idle" | "requested">("idle");

  const requestAuthTicket = React.useCallback(async () => {
    try {
      setBusy(true);
      await userApi.requestAuthTicket(email);
      setState("requested");
    } catch (error) {
      alert("Ett fel uppstod.");
    } finally {
      setBusy(false);
    }
  }, [email]);

  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Enter") {
        requestAuthTicket();
        event.preventDefault();
      }
    },
    [requestAuthTicket]
  );

  const onChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setEmail(event.target.value);
    },
    []
  );

  switch (state) {
    case "requested": {
      return (
        <Typography align="center">
          {s("Tack, vi har skickat ett mejl till dig med länk för inloggning.")}
        </Typography>
      );
    }
    case "idle": {
      return (
        <Box p={2}>
          <Box style={{ textAlign: "center" }} mb={8}>
            <img
              src={logo}
              alt="Logo"
              style={{ width: 250, textAlign: "center" }}
            />
          </Box>
          {/* <Box mt={2}>
            <Typography align="center">{s("Logga in")}</Typography>
          </Box> */}
          <Box
            mt={4}
            component="form"
            style={{
              width: "100%", // Fix IE11 issue.
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label={s("E-postadress")}
                  type="email"
                  value={email}
                  onKeyDown={onKeyDown}
                  onChange={onChange}
                />
              </Grid>
            </Grid>
            <Box mt={3} mb={2}>
              <Button
                color="primary"
                fullWidth
                variant="contained"
                onClick={requestAuthTicket}
                // href="#"
                disabled={busy}
              >
                {!busy ? s("Logga in") : "..."}
              </Button>
            </Box>
          </Box>
        </Box>
      );
    }
  }
};

export const Login = (props: WithQuery) => {
  const classes = useStyles();
  return (
    <Container component="main" maxWidth="xs">
      <Box pt={16}>
        <LoginPanel {...props} />
      </Box>
    </Container>
  );
};
