import * as React from "react";
import { Box, IconButton, Typography } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import * as _ from "lodash";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import * as api from "../../../api";
import * as formsApi from "../../../logic/forms";
import { useParams } from "react-router-dom";
import { PaperEx } from "../../components";

// const data = [
//   {
//     name:
//       "Hur ofta har du under den senaste månaden blivit upprörd över något som skett helt oväntat?",
//     Sällan: 4000,
//     pv: 2400,
//     amt: 2400,
//   },
//   {
//     name: "Page B",
//     uv: 3000,
//     pv: 1398,
//     amt: 2210,
//   },
//   {
//     name: "Page C",
//     uv: 2000,
//     pv: 9800,
//     amt: 2290,
//   },
//   {
//     name: "Page D",
//     uv: 2780,
//     pv: 3908,
//     amt: 2000,
//   },
//   {
//     name: "Page E",
//     uv: 1890,
//     pv: 4800,
//     amt: 2181,
//   },
//   {
//     name: "Page F",
//     uv: 2390,
//     pv: 3800,
//     amt: 2500,
//   },
//   {
//     name: "Page G",
//     uv: 3490,
//     pv: 4300,
//     amt: 2100,
//   },
// ];
function CustomLabel({ x, y, stroke, value, width }: any) {
  if (value) {
    // No label if there is a value. Let the cell handle it.
    return null;
  }

  return (
    <text
      x={x}
      y={y}
      // Move slightly above axis
      dy={-10}
      // Center text
      dx={width / 2}
      fill={stroke}
      fontSize={15}
      textAnchor="middle"
    >
      N/A
    </text>
  );
}

export const Chart = () => {
  const { formId } = useParams<{ formId: string }>();

  const [
    summary,
    setSummary,
  ] = React.useState<api.t.FormSummaryFragment | null>(null);

  React.useEffect(() => {
    (async () => {
      const data = await formsApi.getFormSummary(formId);
      setSummary(data);
    })();
  }, [formId]);

  const onMenu = React.useCallback(() => {
    formsApi.openMenu(formId, {
      anchor: "#menu-btn",
      align: "right",
    });
  }, [formId]);

  if (!summary) {
    return null;
  }

  const data = summary.questions
    .filter(({ question }) => formsApi.isQuantQuestion(question))
    .map((q) => ({
      name: q.question.question,
      ...(q.options || []).reduce(
        (m, v) => ({
          ...m,
          [v.option.label]: v.count,
        }),
        {}
      ),
    }));

  const bars = _.uniq(
    _.flatten(
      summary.questions
        .filter(({ question }) => formsApi.isQuantQuestion(question))
        .map(({ question }) => {
          if (!formsApi.isQuantQuestion(question)) {
            return [];
          }
          return (question.options || []).map((o) => o.label);
        })
    )
  );

  const colors = ["#fb6107", "#256eff", "#80B192", "#c94277", "#2E2D4D"];

  return (
    <Box flex={1} height={600} mb={6}>
      <Box display="flex" flexDirection="row">
        <Box width={120} />
        <Box flex={1}>
          <Typography variant="h6" align="center">
            {summary.form.title}
          </Typography>
        </Box>
        <Box width={120} display="flex" justifyContent="flex-end">
          <IconButton id="menu-btn">
            <MenuIcon onClick={onMenu} />
          </IconButton>
        </Box>
      </Box>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 14,
            left: 0,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis width={20} />
          {data.length > 0 && (
            <Tooltip
              cursor={{ fill: "transparent" }}
              position={{ y: -100 }}
              content={(props) => {
                if (
                  !props.active ||
                  !props.payload!.some((x) => x.value! > 0)
                ) {
                  return null;
                }

                return (
                  <PaperEx maxWidth={180} minWidth={120} p={2} elevation={3}>
                    <Typography variant="subtitle2">{props.label}</Typography>
                    <Box mt={2}>
                      {props.payload!.map((x, index) => (
                        <Box
                          display="flex"
                          flexDirection="row"
                          mt={index > 0 ? 1 : 0}
                        >
                          <Box
                            width={16}
                            height={16}
                            style={{ backgroundColor: x.color }}
                            mr={1}
                          />
                          <Box flex={1} mr={2}>
                            <Typography variant="subtitle2">
                              {x.name}
                            </Typography>
                          </Box>
                          <Box fontSize={13} fontFamily="Monospace">
                            {x.value}
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </PaperEx>
                );
              }}
              // wrapperStyle={{ maxWidth: 200 }}
              // itemStyle={{ maxWidth: 200 }}
              // labelStyle={{ maxWidth: 200 }}
              // contentStyle={{ maxWidth: 200 }}
            />
          )}
          <Legend />
          {bars.map((bar, index) => (
            <Bar dataKey={bar} fill={colors[index % colors.length]} />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};
