import { Config } from "../config";

const config: Config = {
  api: {
    uri: "https://app.heatshape.com:8082/graphql",
  },
  web: {
    uri: "https://app.heatshape.com",
  },
  bugsnag: {
    apiKey: "",
    releaseStage: "dev",
  },
};

export default config;
