import gql from "graphql-tag";

export const groupType = gql`
  fragment GroupType on GroupType {
    id
    name
    primary
    parents {
      id
      name
    }
    kind
  }
`;

export const groupBase = gql`
  fragment GroupBase on Group {
    id
    name
    permissions
    type {
      ...GroupType
    }
  }
  ${groupType}
`;

export const group = gql`
  fragment Group on Group {
    __typename
    ...GroupBase
    parent {
      ...GroupBase
    }
    children {
      ...GroupBase
    }
  }
  ${groupBase}
`;

export const membershipType = gql`
  fragment MembershipType on MembershipType {
    id
    name
    permissions
    groupTypes {
      ...GroupType
    }
  }
  ${groupType}
`;

export const membership = gql`
  fragment Membership on Membership {
    __typename
    id
    group {
      ...Group
    }
    type {
      ...MembershipType
    }
  }
  ${group}
  ${membershipType}
`;

export const user = gql`
  fragment User on User {
    __typename
    id
    firstName
    lastName
    email
    memberships {
      ...Membership
    }
  }
  ${membership}
`;

export const formQuestionOption = gql`
  fragment FormQuestionOption on FormQuestionOption {
    id
    label
  }
`;

export const formQuestionSingleChoice = gql`
  fragment FormQuestionSingleChoice on FormQuestionSingleChoice {
    __typename
    id
    question
    options {
      ...FormQuestionOption
    }
  }
  ${formQuestionOption}
`;

export const formQuestionMultiChoice = gql`
  fragment FormQuestionMultiChoice on FormQuestionMultiChoice {
    __typename
    id
    question
    options {
      ...FormQuestionOption
    }
  }
  ${formQuestionOption}
`;

export const formQuestionText = gql`
  fragment FormQuestionText on FormQuestionText {
    __typename
    id
    question
  }
`;

export const formQuestion = gql`
  fragment FormQuestion on FormQuestion {
    __typename
    ... on FormQuestionMultiChoice {
      ...FormQuestionMultiChoice
    }
    ... on FormQuestionSingleChoice {
      ...FormQuestionSingleChoice
    }
    ... on FormQuestionText {
      ...FormQuestionText
    }
  }
  ${formQuestionMultiChoice}
  ${formQuestionSingleChoice}
  ${formQuestionText}
`;

export const formComponent = gql`
  fragment FormComponent on FormComponent {
    id
    title
    description
    questions {
      ...FormQuestion
    }
  }
  ${formQuestion}
`;

export const groupFormTarget = gql`
  fragment GroupFormTarget on GroupFormTarget {
    __typename
    id
    group {
      ...Group
    }
    type {
      ...MembershipType
    }
    includeChildGroups
  }
  ${group}
  ${membershipType}
`;

export const permission = gql`
  fragment Permission on Permission {
    type
    role
    target {
      __typename
      ... on Membership {
        ...Membership
      }
      ... on User {
        ...User
      }
    }
  }

  ${membership}
  ${user}
`;

export const form = gql`
  fragment Form on Form {
    id
    createdBy {
      ...User
    }
    createdAt
    archivedAt
    publishedAt

    startAt
    endAt
    title
    description
    locked
    components {
      ...FormComponent
    }

    targets {
      ... on GroupFormTarget {
        ...GroupFormTarget
      }
    }

    startAfter {
      form {
        id
        title
      }
      afterDays
    }

    status {
      pendingCount
      completedCount
    }

    permissions {
      ...Permission
    }
  }
  ${user}
  ${formComponent}
  ${groupFormTarget}
  ${permission}
`;

export const formDisplay = gql`
  fragment FormDisplay on Form {
    id
    title
  }
`;

export const singleAnswer = gql`
  fragment SingleChoiceAnswer on SingleChoiceAnswer {
    value
  }
`;

export const multiAnswer = gql`
  fragment MultiChoiceAnswer on MultiChoiceAnswer {
    values
  }
`;

export const answer = gql`
  fragment Answer on Answer {
    __typename
    id

    ... on SingleChoiceAnswer {
      ...SingleChoiceAnswer
    }

    ... on MultiChoiceAnswer {
      ...MultiChoiceAnswer
    }
  }

  ${multiAnswer}
  ${singleAnswer}
`;

export const formAnswers = gql`
  fragment FormAnswers on FormAnswers {
    id
    form {
      ...Form
    }
    answers {
      ...Answer
    }
  }
  ${form}
  ${answer}
`;

export const optionCount = gql`
  fragment OptionCount on OptionCount {
    option {
      ...FormQuestionOption
    }
    count
  }
  ${formQuestionOption}
`;

export const questionSummary = gql`
  fragment QuestionSummary on QuestionSummary {
    question {
      ... on FormQuestionSingleChoice {
        ...FormQuestionSingleChoice
      }
      ... on FormQuestionMultiChoice {
        ...FormQuestionMultiChoice
      }
      ... on FormQuestionText {
        ...FormQuestionText
      }
    }
    options {
      ...OptionCount
    }
    textAnswers
  }
  ${formQuestionText}
  ${formQuestionMultiChoice}
  ${formQuestionSingleChoice}
  ${optionCount}
`;

export const formSummary = gql`
  fragment FormSummary on FormSummary {
    form {
      ...Form
    }
    questions {
      ...QuestionSummary
    }
  }
  ${form}
  ${questionSummary}
`;
