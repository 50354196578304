import * as React from "react";
import { Box, BoxProps, Paper } from "@material-ui/core";

export interface PaperExProps {
  elevation?: number;
}

export const PaperEx = (props: PaperExProps & BoxProps) => {
  const { elevation, p, ...boxProps } = props;
  return (
    <Box {...boxProps}>
      <Paper elevation={elevation}>
        <Box p={p}>{props.children}</Box>
      </Paper>
    </Box>
  );
};
