import * as React from "react";
import bugsnag from "bugsnag-js";
import config from "./config";
//@ts-ignore
import createPlugin from "bugsnag-react";
import Client, { INotifyOpts } from "bugsnag-js/types/client";

let bugsnagClient: Client | undefined;

if (config.bugsnag.apiKey) {
  bugsnagClient = bugsnag({
    apiKey: config.bugsnag.apiKey,
    notifyReleaseStages: ["production", "staging"],
    releaseStage: config.bugsnag.releaseStage,
    autoCaptureSessions: false,
  });
}

const BugSnagErrorBoundary =
  bugsnagClient && bugsnagClient.use(createPlugin(React));

export const ErrorBoundary = (props: {
  children: any;
  FallbackComponent: any;
}) =>
  BugSnagErrorBoundary ? (
    <BugSnagErrorBoundary FallbackComponent={props.FallbackComponent}>
      {props.children}
    </BugSnagErrorBoundary>
  ) : (
    props.children
  );

// const bugsnagSetMeta = (key: string, value: any) => {
//   bugsnagClient.metaData = { ...bugsnagClient.metaData, [key]: value };
// };

export function tryNotify(error: Error, opts?: INotifyOpts) {
  if (bugsnagClient) {
    bugsnagClient.notify(error);
  }
}
