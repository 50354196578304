import { CancelFetch, FetchFunc } from "./fetch";
import {
  ApolloFetch,
  createApolloFetch,
  GraphQLRequest,
  FetchResult,
} from "apollo-fetch";
import { print } from "graphql";

export interface FailedRequest {
  uri: string;
  request: GraphQLRequest;
}

let failedRequest: FailedRequest | null = null;

export function setLastFailedRequest(request: FailedRequest): void {
  failedRequest = request;
}

export function getLastFailedRequest(): FailedRequest | null {
  return failedRequest;
}

export class GraphQLError extends Error {
  constructor(
    public message: string,
    public code: string,
    public extensions: any
  ) {
    super(message);
    Object.setPrototypeOf(this, GraphQLError.prototype);
  }
}

export const isGraphQLError = (error: Error): error is GraphQLError => {
  return (error as any).code !== undefined;
};

export type GqlFetchFunc = (
  request: GraphQLRequest,
  cancel?: CancelFetch
) => Promise<FetchResult>;

export interface FetchData {
  apiUrl: string;
  fetch: ApolloFetch;
}

export function emptyFetch(req: GraphQLRequest): Promise<FetchResult> {
  throw new Error("Fetch is not configured.");
}

export function withGraphQL(
  next: FetchFunc,
  uriGetter: () => Promise<string>
): GqlFetchFunc {
  let wrapFetch: FetchFunc;
  let uri = "";
  let apolloFetch: ApolloFetch | null;

  return async (request: GraphQLRequest, cancel?: CancelFetch) => {
    let currentUri = await uriGetter();

    if (currentUri !== uri) {
      apolloFetch = null;
    }

    if (apolloFetch === null) {
      uri = currentUri;
      apolloFetch = createApolloFetch({
        uri,
        customFetch: (info, init) => wrapFetch!(info, init),
      });
    }

    wrapFetch = (req, init) => next(req, init, cancel);

    let query: any = request.query;
    if (typeof query !== "string") {
      query = print(query);
    }

    try {
      const result = await apolloFetch({
        ...request,
        query,
      });

      if (result.errors) {
        throw new GraphQLError(
          result.errors[0].message,
          result.errors[0].extensions.code,
          ""
        );
      }

      return result;
    } catch (error) {
      setLastFailedRequest({
        uri,
        request,
      });

      if ((error as any).response) {
        // This is an error responded by the server, let's figure out if it's graphql or not
        const { data } = (error as any).response;
        if (data.errors) {
          // Hack because server doesn't respond with proper graphql structure
          // when user doesn't exist in underlying systems
          if (data.code === "no_data") {
            const { message = "", code, extensions } = data as any;
            throw new GraphQLError(message, code, extensions);
          }
          const [{ message, code, extensions }] = data.errors;
          throw new GraphQLError(message, code, extensions);
        }
      }

      console.log("An error occured while performing gql-fetch.", {
        uri,
        query: request.query,
        variables: request.variables,
      });

      throw error;
    }
  };
}

export function withDevLogging(next: GqlFetchFunc): GqlFetchFunc {
  return (request, cancel) => {
    let query: any = request.query;
    if (typeof query !== "string") {
      query = print(query);
    }

    console.log("GQL:", {
      ...request,
      query,
    });

    return next(request, cancel);
  };
}

export function nameParser(init: RequestInit) {
  // Try to parse the body to get more information about the request
  if (init.body && typeof init.body === "string") {
    try {
      const body: { query?: string } = JSON.parse(init.body);
      if (body.query && typeof body.query === "string") {
        const match = /^(query|mutation)\s*\w+(?=\s*({|\())/.exec(body.query);
        if (match) {
          return match[0];
        }
      }
    } catch (error) {
      // Couldn't parse body, ignore
    }
  }
  return null;
}
