import { Box, IconButton, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircle";

interface PageHeaderProps {
  title: string;
  subTitle: string;
  onAdd?: () => void;
}

export const PageHeader = (props: PageHeaderProps) => {
  return (
    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
      <Box sx={{ flex: 1 }}>
        <Typography variant="h4">{props.title}</Typography>
        <Box mt={1}>
          <Typography variant="subtitle1">{props.subTitle}</Typography>
        </Box>
      </Box>
      {props.onAdd && (
        <Box>
          <IconButton color="primary" onClick={props.onAdd}>
            <AddIcon style={{ fontSize: 35 }} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
