import * as React from "react";
import { render } from ".";
import { portal } from "./portal";

export interface DialogProps<T> {
  onClose: (result: T | "cancel") => void;
  dismissable?: boolean;
}

export async function show<R, P>(
  Component:
    | React.ComponentClass<P & DialogProps<R>>
    | React.FunctionComponent<P & DialogProps<R>>,
  props: Omit<P, "onClose">
): Promise<R | "cancel"> {
  return new Promise<R | "cancel">((resolve) => {
    const handle = portal.push(
      render(Component, {
        ...(props as P),
        onClose: (result: R | "cancel") => {
          portal.hide(handle);
          resolve(result);
        },
      })
    );
  });
}
