import { Box, IconButton, Tab, Tabs, Typography } from "@material-ui/core";
import { GridColDef } from "@material-ui/data-grid";
import * as React from "react";
import { s } from "../../../localization";
import { GridEx, PageHeader, PaperEx } from "../../components";
import MenuIcon from "@material-ui/icons/Menu";
import * as groupsApi from "../../../logic/groups";
import * as menuApi from "../../../ui/menu";
import * as api from "../../../api";
import { useEvent } from "../../../events";
import { produce } from "immer";
import { useKey } from "src/ui/hooks";

function getColumns(
  groupType: api.t.GroupTypeFragment,
  onMenu?: (groupId: string, options: Partial<menuApi.MenuOptions>) => void
) {
  let columns: GridColDef[] = [
    { field: "id", hide: true },
    { field: "name", headerName: "Namn", type: "string", flex: 1 },
  ];

  if (groupType.parents.length > 0) {
    columns.push({
      field: "parent",
      headerName: s("Hanteras av"), // groupType.parents.map((t) => t.name).join(", "),
      type: "string",
      flex: 1,
      valueGetter: (params) => {
        const parent: api.t.GroupFragment = params.row.parent;
        return parent ? parent.name : "";
      },
    });
  }

  if (onMenu) {
    columns.push({
      field: "",
      width: 60,
      ...({ disableClickEventBubbling: true } as any),
      disableColumnMenu: true,
      disableReorder: true,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const menuId = `menu-${params.row.id}`;
        return (
          <IconButton
            onClick={(e) => {
              onMenu(params.row.id, {
                anchor: `#${menuId}`,
                align: "right",
              });
              e.preventDefault();
              e.stopPropagation();
            }}
            id={menuId}
          >
            <MenuIcon />
          </IconButton>
        );
      },
    });
  }

  return columns;
}

interface ListGroupsProps {
  kind: api.t.GroupTypeKind;
  canAdd: boolean;
  onMenu?: (groupId: string, options: Partial<menuApi.MenuOptions>) => void;
  name: string;
}

export const ListGroups = (props: ListGroupsProps) => {
  const [activeTab, setActiveTab] = React.useState(0);
  const [groups, setGroups] = React.useState<api.t.GroupFragment[]>([]);
  const [groupTypes, setGroupTypes] = React.useState<api.t.GroupTypeFragment[]>(
    []
  );
  const [allGroups, setAllGroups] = React.useState<api.t.GroupFragment[]>([]);

  const refresh = React.useCallback(async () => {
    const data = await groupsApi.getGroups(true);
    const groups = data.groups.filter((g) => g.type.kind === props.kind);
    const groupTypes = data.groupTypes.filter((g) => g.kind === props.kind);
    setAllGroups(data.groups);
    setGroups(groups);
    // Do not show primary types as tabs for this user
    setGroupTypes(groupTypes.filter((t) => !t.primary));
  }, [props.kind]);

  const editGroup = React.useCallback(
    async (group: api.t.GroupFragment) => {
      if (!group.permissions.some((p) => p === api.t.GroupPermission.Update)) {
        return;
      }

      const result = await groupsApi.editGroup(group, groupTypes, allGroups);

      if (result === "cancel") {
        return;
      }
      refresh();
    },
    [groupTypes, groups]
  );

  const onChangeTab = React.useCallback(
    (event: React.ChangeEvent<{}>, tab: number) => {
      setActiveTab(tab);
    },
    [activeTab]
  );

  // const onAdd = React.useCallback(() => {
  //   editGroup({
  //     id: "",
  //     children: [],
  //     name: "",
  //     type: groupTypes[activeTab],
  //     permissions: [api.t.GroupPermission.Delete, api.t.GroupPermission.Update],
  //     __typename: "Group",
  //   });
  // }, [editGroup]);

  React.useEffect(() => {
    refresh();
  }, []);

  useEvent(
    groupsApi.groupDeleted,
    ({ groupId }) => {
      setGroups(groups.filter((g) => g.id !== groupId));
    },
    [groups]
  );

  useEvent(
    groupsApi.groupUpserted,
    ({ group }) => {
      setGroups(
        produce(groups, (draft) =>
          draft.map((g) => {
            if (g.id !== group.id) {
              return g;
            }
            return group;
          })
        )
      );
    },
    [groups]
  );

  const isShiftDown = useKey("Shift");

  const onAdd = React.useCallback(async () => {
    if (isShiftDown) {
      groupsApi.importGroups(props.name);
      return;
    }

    await editGroup({
      id: "",
      children: [],
      name: "",
      type: groupTypes[activeTab],
      permissions: [api.t.GroupPermission.Delete, api.t.GroupPermission.Update],
      __typename: "Group",
    });
  }, [groups, isShiftDown, activeTab, groupTypes, props.name]);

  if (groupTypes.length === 0) {
    return null;
  }

  return (
    <Box width={1}>
      <PageHeader
        title={props.name}
        subTitle={s(
          "Nedan listas alla poster som du har rätt att administrera."
        )}
        onAdd={onAdd}
      ></PageHeader>
      {/* <Typography variant="h4">{props.name}</Typography>
      <Box mt={1}>
        <Typography variant="subtitle1">
          {s("Nedan listas alla poster som du är kopplad till.", {
            grupp: props.name.toLowerCase(),
          })}
        </Typography>
      </Box> */}
      {groupTypes.length > 1 && (
        <Box mt={2}>
          <Tabs value={activeTab} onChange={onChangeTab}>
            {groupTypes.map((t) => (
              <Tab label={t.name} />
            ))}
          </Tabs>
        </Box>
      )}
      <PaperEx mt={2}>
        <GridEx
          onRowClick={editGroup}
          rows={groups.filter((g) => g.type.id === groupTypes[activeTab].id)}
          columns={getColumns(groupTypes[activeTab], props.onMenu)}
        />
      </PaperEx>
    </Box>
  );
};
