import {
  Box,
  IconButton,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { s } from "../../../localization";
import { GridColDef } from "@material-ui/data-grid";
import * as dateFns from "date-fns";
import * as api from "../../../api";
import { GridEx } from "../../components/GridEx";
import MenuIcon from "@material-ui/icons/Menu";
import * as formsApi from "../../../logic/forms";
import { useEvent } from "../../../eventemitter";
import { produce } from "immer";
import _ from "lodash";
import { PageHeader } from "src/ui/components";
// Toolbars
// https://stackoverflow.com/questions/66446880/material-ui-data-grid-how-pass-parameter-to-custom-toolbar

// const useStyles = makeStyles({
//   root: {
//     "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
//       outline: "none",
//     },
//     cursor: "pointer",
//   },
// });

const columns: GridColDef[] = [
  { field: "id", disableColumnMenu: true, hide: true },
  {
    field: "title",
    disableColumnMenu: true,
    headerName: "Namn",
    type: "string",
    flex: 0.7,
  },
  {
    disableColumnMenu: true,
    field: "description",
    sortable: false,
    headerName: "Beskrivning",
    type: "string",
    flex: 1,
  },
  {
    disableColumnMenu: true,
    field: "createdBy",
    headerName: "Skapad av",
    type: "string",
    width: 140,
  },
  {
    field: "startAt",
    headerName: "Startar",
    disableColumnMenu: true,
    type: "string",
    width: 120,
  },
  {
    field: "endAt",
    headerName: "Slutar",
    disableColumnMenu: true,
    type: "string",
    width: 120,
  },
  {
    field: "progress",
    headerName: "Status",
    disableColumnMenu: true,
    disableReorder: true,
    sortable: false,
    headerAlign: "right",
    align: "right",
    type: "string",
    width: 100,
  },
  // {
  //   field: "locked",
  //   headerName: "Publicerad",
  //   type: "boolean",
  //   width: 120,
  // },
  {
    field: "",
    width: 60,
    ...({ disableClickEventBubbling: true } as any),
    disableColumnMenu: true,
    disableReorder: true,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      const onMenu = (e: any) => {
        formsApi.openMenu(params.row.id, {
          anchor: `#menu-${params.row.id}`,
          align: "right",
        });
        e.preventDefault();
        e.stopPropagation();
      };
      return (
        <IconButton onClick={onMenu} id={`menu-${params.row.id}`}>
          <MenuIcon />
        </IconButton>
      );
    },
  },
];

const List = React.memo((props: { forms: api.t.FormFragment[] }) => {
  const { forms } = props;

  const onRowClick = React.useCallback((item: { id: string }) => {
    formsApi.editForm(item.id);
  }, []);

  const rows = forms.map((d) => ({
    ...d,
    startAt: dateFns.format(new Date(d.startAt), "yyyy-MM-dd"),
    endAt: d.endAt && dateFns.format(new Date(d.endAt), "yyyy-MM-dd"),
    createdBy: `${d.createdBy.firstName} ${d.createdBy.lastName}`,
    createdAt: dateFns.format(new Date(d.createdAt), "yyyy-MM-dd"),
    progress: d.publishedAt
      ? `${d.status.completedCount}/${d.status.completedCount +
          d.status.pendingCount}`
      : s("Ej publicerad"),
  }));

  return (
    <Paper>
      <GridEx onRowClick={onRowClick} rows={rows} columns={columns} />
    </Paper>
  );
});

function getForms(forms: api.t.FormFragment[], tab: number) {
  const data = forms.reduce(
    (p, v) => {
      if (v.archivedAt) {
        p[2].push(v);
      } else if (
        !v.endAt ||
        !dateFns.isAfter(
          dateFns.endOfDay(new Date()),
          dateFns.endOfDay(new Date(v.endAt))
        )
      ) {
        p[0].push(v);
      } else {
        p[1].push(v);
      }
      return p;
    },
    [[], [], []] as [
      api.t.FormFragment[],
      api.t.FormFragment[],
      api.t.FormFragment[]
    ]
  );

  return data[tab];
}

export const ListForms = () => {
  const [activeTab, setActiveTab] = React.useState(0);
  const [forms, setForms] = React.useState<api.t.FormFragment[]>([]);

  const onChangeTab = React.useCallback(
    (event: React.ChangeEvent<{}>, tab: number) => {
      setActiveTab(tab);
    },
    [activeTab]
  );

  const load = React.useCallback(async () => {
    setForms(await formsApi.getForms());
  }, []);

  const setArchivedAt = React.useCallback(
    (formId: string, archivedAt: string | null) => {
      setForms(
        produce(forms, (draft) => {
          draft.forEach((form) => {
            if (form.id === formId) {
              form.archivedAt = archivedAt;
            }
          });
        })
      );
    },
    [forms]
  );

  useEvent(
    formsApi.formDeleted,
    ({ formId, archive }) => {
      if (archive) {
        setArchivedAt(formId, new Date().toISOString());
      } else {
        setForms(forms.filter(({ id }) => formId !== id));
      }
    },
    [forms]
  );

  useEvent(
    formsApi.formUpdated,
    (event) => {
      setForms(
        produce(forms, (draft) => {
          draft.forEach((form) => {
            if (form.id === event.form.id) {
              _.assign(form, event.form);
            }
          });
        })
      );
    },
    [forms]
  );

  useEvent(
    formsApi.formRestored,
    ({ formId }) => {
      setArchivedAt(formId, null);
    },
    [forms]
  );

  React.useEffect(() => {
    load();
  }, []);

  const onAdd = React.useCallback(() => {
    formsApi.editForm("");
  }, []);

  return (
    <Box width={1}>
      <PageHeader
        title={s("Frågeformulär")}
        subTitle={s(
          "Här hittar du alla formulär som du har rättigheter att se."
        )}
        onAdd={onAdd}
      ></PageHeader>

      <Box mt={2}>
        <Tabs
          value={activeTab}
          // indicatorColor="primary"
          // textColor="primary"
          onChange={onChangeTab}
        >
          <Tab
            label={s("Aktuella ({count})", {
              count: getForms(forms, 0).length,
            })}
          />
          <Tab
            label={s("Avslutade ({count})", {
              count: getForms(forms, 1).length,
            })}
          />
          <Tab
            label={s("Borttagna ({count})", {
              count: getForms(forms, 2).length,
            })}
          />
        </Tabs>
      </Box>
      <Box mt={2}>
        <List forms={getForms(forms, activeTab)} />
      </Box>
    </Box>
  );
};
