import * as React from "react";
import * as t from "../types";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import * as userApi from "../logic/user";
import * as api from "../api";
import { s } from "../localization";
import logo from "./images/base_textlogo_transparent_background.png";

const useStyles = makeStyles((theme) => ({
  white: {
    color: "#fff",
  },
  appBar: {
    backgroundColor: "#fff",
    boxShadow: "1px 0px 10px rgb(0 0 0 / 22%) !important",
  },
  menu: {
    "& div": {
      // this is just an example, you can use vw, etc.
      width: "150px",
    },
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {},
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  // search: {
  //   position: "relative",
  //   borderRadius: theme.shape.borderRadius,
  //   backgroundColor: fade(theme.palette.common.white, 0.15),
  //   "&:hover": {
  //     backgroundColor: fade(theme.palette.common.white, 0.25),
  //   },
  //   marginRight: theme.spacing(2),
  //   marginLeft: 0,
  //   width: "100%",
  //   [theme.breakpoints.up("sm")]: {
  //     marginLeft: theme.spacing(3),
  //     width: "auto",
  //   },
  // },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

// navigator.serviceWorker.register("sw.js");

// function notify() {
//   if (!("Notification" in window)) {
//     alert("Notification API not supported");
//     return;
//   }

//   Notification.requestPermission()
//     .then(() => {
//       if (Notification.permission !== "granted") {
//         throw "Notification permission is not granted";
//       }
//     })
//     // .then(() => {
//     //   navigator.serviceWorker.ready.then(function(registration) {
//     //     registration.showNotification("Vibration Sample", {
//     //       body: "Buzz! Buzz!",
//     //       icon: "../images/touch/chrome-touch-icon-192x192.png",
//     //       vibrate: [200, 100, 200, 100, 200, 100, 200],
//     //       tag: "vibration-sample",
//     //     });
//     //   });
//     // })
//     .then(() => navigator.serviceWorker.getRegistration())
//     .then((reg) => {
//       reg!.showNotification("Hi there from the past!", {
//         // showTrigger: new TimestampTrigger(new Date().getTime() + 10 * 1000),
//       });
//     })
//     .catch((err) => {
//       alert("Notification Trigger API error: " + err);
//     });
// }
/*
class NavMenu extends React.Component<MenuProps, MenuState> {
  constructor(props: MenuProps) {
    super(props);
    this.state = {
      isOpen: false,
      items: null,
    };
  }

  private toggle = async () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen, items: null });
    if (!isOpen) {
      const items = await this.props.menu.loadItems();
      this.setState((state) => ({ ...state, items }));
    }
  };

  render() {
    return (
      <Dropdown isOpen={this.state.isOpen} toggle={this.toggle}>
        <DropdownToggle nav>{this.props.menu.name}</DropdownToggle>
        <DropdownMenu>
          {this.state.items ? (
            this.state.items.map((item, index) => (
              <React.Fragment key={index}>{item.component}</React.Fragment>
            ))
          ) : (
            <DropdownItem disabled>
              <div className="py-1">Loading...</div>
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
    );
  }
}
*/

export interface TopBarProps {
  session: userApi.Session;
}

function getOrganizations(user: api.t.UserFragment) {
  return (
    user.memberships
      .filter((m) => m.group.type.kind === api.t.GroupTypeKind.Organizational)
      .map((m) => m.group.name)
      .join(", ") || "Heatshape"
  );
}

export const TopBar = React.memo((props: TopBarProps) => {
  const classes = useStyles();

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState();

  const recordButtonPosition = (event: any) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };

  return (
    <React.Fragment>
      <AppBar
        position="static"
        className={classes.appBar}
        elevation={20}
        color="secondary"
      >
        <Container maxWidth="lg">
          <Toolbar>
            <a href="/">
              <img src={logo} />
            </a>
            {/* <Typography
              variant="h6"
              className={classes.title}
              component="a"
              href="/"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {getOrganizations(props.session.user)}
            </Typography> */}
            <div className={classes.grow} />
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <Typography variant="h6">
                {props.session.user.firstName}
              </Typography>
              <Typography
                color="textSecondary"
                variant="subtitle1"
                style={{ marginTop: -5 }}
              >
                {props.session.user.memberships
                  .filter(
                    (x) =>
                      x.group.type.kind === api.t.GroupTypeKind.Organizational
                  )
                  .map((x) => `${x.group.name} (${x.type.name})`)
                  .join(", ")}
              </Typography>
            </Box>
            <Box ml={1}>
              <IconButton
                edge="start"
                className={classes.menuButton}
                // color="inherit"
                aria-label="menu"
                aria-controls={"menu"}
                aria-haspopup="true"
                onClick={recordButtonPosition}
              >
                <AccountCircle fontSize="large" />
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        getContentAnchorEl={null}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      >
        <MenuItem
          onClick={() => {
            userApi.clearSession();
            setIsMenuOpen(false);
          }}
        >
          {s("Logga ut")}
        </MenuItem>
      </Menu>

      {/* <NavbarToggler onClick={this.toggle} /> */}
      {/* <NavbarBrand href="/">DAILY REPORT CARD</NavbarBrand> */}

      {/* <Collapse navbar>
          <Nav className="mr-auto" navbar>
            {getMenus().map(menu => (
              <NavMenu menu={menu} />
            ))}
          </Nav>
          <Nav right navbar>
            <NavItem>
              <div className="mt-2">Help</div>
            </NavItem>
          </Nav>
        </Collapse> */}
    </React.Fragment>
  );
});
