export function wait(period: number) {
  return new Promise((resolve) => setTimeout(resolve, period));
}

export function createDelay(delay: number) {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  return <T extends (...args: any[]) => Promise<any>>(callback: T): T => {
    const wrapped = ((...args: any[]) =>
      new Promise((resolve, reject) => {
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(async () => {
          resolve(await callback(...args));
        }, delay);
      })) as T;

    return wrapped;
  };
}
