export type Transient<T> = T & { __key: string };

export function getKey<T extends { id: string }>(entity: T): string {
  if (entity.id) {
    return entity.id;
  }

  const transient = entity as Transient<T>;
  if (!transient.__key) {
    throw new Error("No key available.");
  }

  return transient.__key;
}
