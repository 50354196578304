import {
  Box,
  Button,
  Checkbox,
  Chip,
  IconButton,
  Radio,
  TextField,
  TextFieldProps,
} from "@material-ui/core";
import * as _ from "lodash";
import produce from "immer";
import * as React from "react";
import * as api from "src/api";
import * as formsApi from "src/logic/forms";
import { getKey } from "src/logic/types";
import { removeInPlace, removeInPlaceIf } from "src/utils/data";
import UpIcon from "@material-ui/icons/ArrowUpward";
import DownIcon from "@material-ui/icons/ArrowDownward";
import DeleteIcon from "@material-ui/icons/Delete";
import { s } from "src/localization";
import { FormQuestionProps } from "./types";

const Option = React.memo(
  (props: {
    autoFocus?: boolean;
    type: api.t.FormQuestionType;
    option: api.t.FormQuestionOptionFragment;
    onUpdate: (data: Partial<api.t.FormQuestionOptionFragment>) => void;
    onDelete: () => void;
    onOrderUp?: () => void;
    onOrderDown?: () => void;
    onAddNext: () => void;
  }) => {
    const {
      onDelete,
      onUpdate,
      onOrderUp,
      onOrderDown,
      type,
      option,
      autoFocus,
    } = props;
    const Input =
      type === api.t.FormQuestionType.FormQuestionMultiChoice
        ? Checkbox
        : Radio;

    return (
      <Box flexDirection="row" display="flex" alignItems="center">
        <Input
          // checked={state.checkedB}
          // onChange={handleChange}
          checked={false}
          name="checkedB"
          color="primary"
        />

        <Box flex={1}>
          <TextInput
            autoFocus={!option.id && autoFocus}
            size="small"
            fullWidth
            variant="standard"
            placeholder={s("Ange ett svarsalternativ...")}
            value={option.label}
            onChange={(e: any) => onUpdate({ label: e.target.value })}
            onEnter={props.onAddNext}
          />
        </Box>

        <Box>
          <IconButton size="small" disabled={!onOrderUp} onClick={onOrderUp}>
            <UpIcon />
          </IconButton>
          <IconButton
            size="small"
            disabled={!onOrderDown}
            onClick={onOrderDown}
          >
            <DownIcon />
          </IconButton>
          <IconButton size="small" onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    );
  }
);

const TextInput = (props: TextFieldProps & { onEnter?: () => void }) => (
  <TextField
    {...props}
    onKeyDown={(e) => {
      if (e.keyCode === 13 || e.keyCode === 27) {
        const htmlElement = document.activeElement as HTMLElement;
        if (htmlElement && htmlElement.blur) {
          htmlElement.blur();
        }
      }

      if (props.onEnter && e.keyCode === 13) {
        props.onEnter();
      }
    }}
  />
);

export const FormQuestionOptions = React.memo(
  <
    T extends
      | api.t.FormQuestionMultiChoiceFragment
      | api.t.FormQuestionSingleChoiceFragment
  >(
    props: FormQuestionProps<T>
  ) => {
    const {
      question,
      onUpdate,
      onDelete,
      onOrderDown,
      onOrderUp,
      label,
    } = props;

    const { current: inputLabelProps } = React.useRef({
      shrink: true,
    });

    const onAddOption = React.useCallback(() => {
      onUpdate(
        produce(question, (draft) => {
          removeInPlaceIf(draft.options, (x) => x.label.trim() === "").push(
            formsApi.createOption()
          );
        })
      );
    }, [question]);

    const onChange = React.useCallback(
      (e: any) => {
        onUpdate({ question: e.target.value } as Partial<T>);
      },
      [onUpdate]
    );

    return (
      <Box mb={3}>
        <Box display="flex" flexDirection="row" mt={1}>
          <Box pt={1}>
            <Chip
              size="small"
              label={label}
              style={{ width: 25, fontWeight: "bold" }}
            />
          </Box>
          <Box ml={1} flex={1}>
            <TextInput
              autoFocus={!question.id}
              InputLabelProps={inputLabelProps}
              multiline
              rows={3}
              fullWidth
              variant="outlined"
              placeholder={s("Ange en fråga...")}
              value={question.question}
              onChange={onChange}
            />
          </Box>
          <Box ml={1}>
            <IconButton size="small" disabled={!onOrderUp} onClick={onOrderUp}>
              <UpIcon />
            </IconButton>
            <IconButton
              size="small"
              disabled={!onOrderDown}
              onClick={onOrderDown}
            >
              <DownIcon />
            </IconButton>
            <IconButton size="small" onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
        <Box ml={4} mr={5} mt={1}>
          {question.options.map((o, i) => (
            <Option
              key={getKey(o)}
              autoFocus={question.options.length > 1}
              type={
                question.__typename === "FormQuestionMultiChoice"
                  ? api.t.FormQuestionType.FormQuestionMultiChoice
                  : api.t.FormQuestionType.FormQuestionSingleChoice
              }
              option={o}
              onAddNext={onAddOption}
              onUpdate={(data) =>
                onUpdate(
                  produce(question, (draft) => {
                    _.assign(draft.options[i], data);
                  })
                )
              }
              onDelete={() =>
                onUpdate(
                  produce(question, (draft) => {
                    removeInPlace(i, draft.options);
                  })
                )
              }
              onOrderUp={
                i > 0
                  ? () => {
                      onUpdate(
                        produce(question, (draft) => {
                          const tmp = draft.options[i];
                          draft.options[i] = draft.options[i - 1];
                          draft.options[i - 1] = tmp;
                        })
                      );
                    }
                  : undefined
              }
              onOrderDown={
                i < question.options.length - 1
                  ? () => {
                      onUpdate(
                        produce(question, (draft) => {
                          const tmp = draft.options[i];
                          draft.options[i] = draft.options[i + 1];
                          draft.options[i + 1] = tmp;
                        })
                      );
                    }
                  : undefined
              }
            />
          ))}
          <Box>
            <Button size="medium" onClick={onAddOption}>
              {s("+ Lägg till svarsalternativ")}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
);
