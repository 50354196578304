import * as React from "react";

type EventListener<T> = (event: T) => void;

interface Event<T> {
  data: T;
  def: EventDef<T>;
}

interface EventDef<T> {
  (data: T): Event<T>;
  eventName: string;
}

let listeners: { [name: string]: EventListener<any>[] } = {};

export function createEvent<T>(name: string) {
  const eventDef: EventDef<T> = ((data: T) => ({
    data,
    def: eventDef,
  })) as any;
  eventDef.eventName = name;
  return eventDef;
}

export function emit<T>(event: Event<T>) {
  (listeners[event.def.eventName] || []).forEach((x) => x(event.data));
}

export function addListener<T>(
  eventDef: EventDef<T>,
  listener: EventListener<T>
) {
  listeners[eventDef.eventName] = [
    ...(listeners[eventDef.eventName] || []),
    listener,
  ];
}

export function removeListener<T>(listener: EventListener<T>) {
  listeners = Object.keys(listeners).reduce(
    (map, name) => ({
      ...map,
      [name]: listeners[name].filter((x) => x !== listener),
    }),
    {}
  );
}

export function useEvent<T>(
  eventDef: EventDef<T>,
  listener: EventListener<T>,
  deps: React.DependencyList
) {
  React.useEffect(() => {
    addListener(eventDef, listener);
    return () => {
      removeListener(listener);
    };
  }, [listener, ...deps]);
}
