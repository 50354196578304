import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Tooltip,
} from "@material-ui/core";
import * as React from "react";
import * as api from "../../../api";
import { s } from "../../../localization";
import { SelectForm } from "./SelectForm";

interface Value {
  afterDays: number | null;
  form: api.t.FormDisplayFragment | null;
}

interface StartAfterProps {
  value?: Value | null;
  parentFormId: string;
  onChange: (startAfter?: Value) => void;
}

export const StartAfter = (props: StartAfterProps) => {
  const onToggleStartAfter = React.useCallback(() => {
    if (!props.value) {
      props.onChange({
        form: null,
        afterDays: 0,
      });
    } else {
      props.onChange();
    }
  }, [props.onChange, props.value]);

  const onChangeDelay = React.useCallback(
    (e: { target: { value: string } }) => {
      if (props.value) {
        const { value } = e.target;
        props.onChange({
          ...props.value,
          afterDays: value !== "" ? parseInt(value, 10) : null,
        });
      }
    },
    [props.value]
  );

  const onSelectForm = React.useCallback(
    (form: api.t.FormDisplayFragment) => {
      if (props.value) {
        props.onChange({
          ...props.value,
          form,
        });
      }
    },
    [props.value]
  );

  const filterForms = React.useCallback(
    (form: api.t.FormDisplayFragment) => form.id !== props.parentFormId,
    [props.parentFormId]
  );

  return (
    <Box flexDirection="column" flex={1}>
      <FormControlLabel
        control={
          <Checkbox
            checked={!!props.value}
            onClick={onToggleStartAfter}
            color="primary"
            name="test"
          />
        }
        label={s("Starta efter annat formulär besvarats")}
      />
      {props.value && (
        <Box display="flex" flexDirection="row" flex={1} mt={1} mb={4}>
          <Box flex={0.7} mr={4}>
            <SelectForm
              filter={filterForms}
              form={props.value.form}
              onSelect={onSelectForm}
              label={s("Föregående formulär")}
            />
          </Box>
          <Box flex={0.3}>
            <Tooltip
              title={s(
                "Ange antal dagar i fördröjt utskick från att föregående formulär besvarats."
              )}
            >
              <TextField
                style={{ width: "100%" }}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                // inputProps={{
                //   style: { textAlign: "right" },
                // }}
                value={props.value.afterDays}
                variant="outlined"
                label={s("Fördröjning (dagar)")}
                placeholder={s("Antal dagar...")}
                onChange={onChangeDelay}
              />
            </Tooltip>
          </Box>
        </Box>
      )}
    </Box>
  );
};
