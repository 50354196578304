import * as React from "react";
import { ErrorBoundary } from "./bugsnag";
import { App } from "./ui/App";
import "./ui/styles/index.css";

const FallbackComponent = (props: { error: Error; info: any }) => {
  return (
    <main>
      <div className="start-box">
        <h1>Ooops</h1>
        <p>Something went wrong, reload the page and try again.</p>
      </div>
    </main>
  );
};

export class Root extends React.Component<any, any> {
  render() {
    return (
      <ErrorBoundary FallbackComponent={FallbackComponent}>
        <App />
      </ErrorBoundary>
    );
  }
}
