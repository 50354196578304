import * as React from "react";
import {
  Box,
  ClickAwayListener,
  Divider,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Popper,
  PopperPlacementType,
  Typography,
} from "@material-ui/core";
import { portal } from "./portal";
import { PaperEx, TextFieldEx } from "./components";
import { s } from "../localization";

interface ClickableItem {
  label: string;
  onClick: () => void;
}

export type Item = ClickableItem | "divider";

export interface ListOptions<T> {
  items: T[];
  getLabel: (item: T) => string;
  onClose: () => void;
  onClick: (item: T) => void;
  anchor: string;
  placement?: PopperPlacementType;
}

export const ItemList = <T,>(props: ListOptions<T>) => {
  const {
    placement = "bottom-start",
    anchor,
    items,
    getLabel,
    onClick,
    onClose,
  } = props;
  const [text, setText] = React.useState("");

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Popper
        open
        style={{ zIndex: 3000 }}
        placement={placement}
        anchorEl={document.body.querySelector(anchor)}
      >
        <PaperEx elevation={2} pb={1}>
          <TextFieldEx
            onExit={onClose}
            onChangeText={setText}
            size="small"
            autoFocus
          />

          <List>
            {items
              .filter(
                (item) =>
                  !text ||
                  getLabel(item)
                    .toUpperCase()
                    .indexOf(text.toUpperCase()) >= 0
              )
              .map((item) => (
                <ListItem
                  button
                  dense
                  onClick={() => {
                    onClick(item);
                    onClose();
                  }}
                >
                  <ListItemText primary={getLabel(item)} />
                </ListItem>
              ))}
          </List>
        </PaperEx>
      </Popper>
    </ClickAwayListener>
  );
};

export function showList<T>(options: {
  items: T[];
  getSubItems?: (item: T) => T[];
  getLabel: (item: T) => string;
  onClick: (item: T) => void;
  anchor: string;
  placement?: PopperPlacementType;
}) {
  const handle = portal.push(
    <ItemList {...options} onClose={() => portal.hide(handle)} />
  );
}

export interface MenuOptions {
  items: Item[];
  anchor: string;
  align?: "left" | "right";
  minWidth?: number;
}

const MenuListEmpty = (props: { options: MenuOptions }) => {
  const { align = "left" } = props.options;
  return (
    <MenuItem
      disabled
      style={{
        justifyContent: align === "left" ? "flex-start" : "flex-end",
      }}
      key="empty"
    >
      <em>{s("Inga val")}</em>
    </MenuItem>
  );
};

const MenuListItem = React.memo(
  (props: { item: Item; options: MenuOptions; onClick: () => void }) => {
    const {
      item,
      onClick,
      options: { align },
    } = props;

    if (item === "divider") {
      return <Divider />;
    }

    return (
      <MenuItem
        style={{
          justifyContent: align === "left" ? "flex-start" : "flex-end",
        }}
        key={item.label}
        onClick={() => {
          item.onClick();
          onClick();
        }}
      >
        {item.label}
      </MenuItem>
    );
  }
);

export function showMenu(options: MenuOptions) {
  const { align = "left" } = options;
  const handle = portal.push(
    <Menu
      id="menu"
      style={{ marginTop: 5 }}
      anchorEl={document.body.querySelector(options.anchor)}
      anchorOrigin={{ vertical: "bottom", horizontal: align }}
      getContentAnchorEl={null}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: align }}
      open={true}
      onClose={() => portal.hide(handle)}
      PaperProps={{
        style: {
          minWidth: options.minWidth,
        },
      }}
    >
      {options.items.length > 0 ? (
        options.items.map((item) => (
          <MenuListItem
            options={options}
            item={item}
            onClick={() => {
              portal.hide(handle);
            }}
          />
        ))
      ) : (
        <MenuListEmpty options={options} />
      )}
    </Menu>
  );
}

export interface DropProps<T> {
  onClose: (result: T | "cancel") => void;
}

export interface DropOptions {
  anchor: string;
  align?: "right" | "left";
  width?: number;
}

export async function showDrop<R, P extends DropProps<R>>(
  Component:
    | React.ComponentClass<P & DropProps<R>>
    | React.FunctionComponent<P>,
  props: Omit<P, "onClose">,
  options: DropOptions
): Promise<R | "cancel"> {
  const { anchor, align = "left", width = 200 } = options;
  return new Promise<R | "cancel">((resolve) => {
    const handle = portal.push(
      <ClickAwayListener
        onClickAway={() => {
          portal.hide(handle);
        }}
      >
        <Popper
          open
          style={{ zIndex: 3000, width, marginTop: 4 }}
          placement={align === "left" ? "bottom-start" : "bottom-end"}
          anchorEl={document.body.querySelector(anchor)}
        >
          <PaperEx elevation={6} pb={1}>
            <Component
              {...(props as P)}
              onClose={(result: R | "cancel") => {
                portal.hide(handle);
                resolve(result);
              }}
            />
          </PaperEx>
        </Popper>
      </ClickAwayListener>
    );
  });
}
