import * as React from "react";
import { Box, BoxProps, Button, PropTypes } from "@material-ui/core";

export interface ButtonExProps {
  onSubmit?: () => Promise<void>;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  size?: "small" | "medium" | "large";
  variant?: "text" | "outlined" | "contained";
  color?: PropTypes.Color;
  disabled?: boolean;
}

export const ButtonEx = (props: Omit<BoxProps, "color"> & ButtonExProps) => {
  const {
    onSubmit,
    color = "primary",
    size = "large",
    variant = "contained",
    onClick,
    disabled,
    minWidth,
    ...boxProps
  } = props;
  const [busy, setBusy] = React.useState(false);
  const buttonProps = { color, size, variant, disabled, minWidth };

  return (
    <Box {...boxProps}>
      <Button
        {...buttonProps}
        onClick={async (e) => {
          if (onSubmit) {
            try {
              setBusy(true);
              await onSubmit();
            } finally {
              setBusy(false);
            }
          }
          if (onClick && e) {
            onClick(e as React.MouseEvent<HTMLButtonElement>);
          }
        }}
      >
        {busy ? "..." : props.children}
      </Button>
    </Box>
  );
};
