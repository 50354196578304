import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Link,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { s } from "../../../localization";
import { PageProps } from "../Pages";
import { produce } from "immer";
import * as _ from "lodash";
import * as userApi from "../../../logic/user";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

interface Registration {
  email: string;
  firstName: string;
  lastName: string;
}

export const AccountRegistration = (props: PageProps) => {
  const [user, setUser] = React.useState<Registration>({
    email: "",
    firstName: "",
    lastName: "",
  });

  function update(props: Partial<Registration>) {
    setUser(produce(user, (draft) => _.assign(draft, props)));
  }

  const classes = useStyles();

  async function register() {
    try {
      await userApi.registerUser(user);
    } catch (error) {
      alert("Ett fel uppstod.");
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      {/* <CssBaseline /> */}
      <Box pt={10}>
        <Paper className={classes.root}>
          <Box p={2}>
            <Typography align="center" component="h1" variant="h4">
              {s("HEATSHAPE")}
            </Typography>
            <Box mt={2}>
              <Typography align="center">
                {s("Registrera användare")}
              </Typography>
            </Box>
            <Box
              mt={4}
              component="form"
              style={{
                width: "100%", // Fix IE11 issue.
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    label={s("Förnamn")}
                    type="text"
                    value={user.firstName}
                    onChange={(e) => {
                      update({ firstName: e.target.value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    label={s("Efternamn")}
                    type="text"
                    value={user.lastName}
                    onChange={(e) => {
                      update({ lastName: e.target.value });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label={s("E-postadress")}
                    type="email"
                    value={user.email}
                    onChange={(e) => {
                      update({ email: e.target.value });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox value="allowExtraEmails" color="primary" />
                    }
                    label={s("Jag godkänner användaravtalet.")}
                  />
                </Grid>
              </Grid>
              <Box mt={3} mb={2}>
                <Button
                  color="primary"
                  fullWidth
                  variant="contained"
                  onClick={register}
                  href="#"
                >
                  {s("Registrera")}
                </Button>
              </Box>
              <Grid container>
                <Grid item>
                  <Link href="#" variant="body2">
                    {s("Har du redan ett konto? Logga in")}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Container>
  );

  // return (
  //   <Box>
  //     <Paper>
  //       <Box mr={4}>
  //         <TextField
  //           label={s("E-postadress")}
  //           type="email"
  //           value={user.email}
  //           InputLabelProps={{
  //             shrink: true,
  //           }}
  //           onChange={(e) => {
  //             update({ email: e.target.value });
  //           }}
  //         />
  //       </Box>
  //       <Box display="flex" flexDirection="row" mb={3} mt={4}>
  //         <Box mr={4}>
  //           <TextField
  //             label={s("Förnamn")}
  //             type="text"
  //             value={user.firstName}
  //             InputLabelProps={{
  //               shrink: true,
  //             }}
  //             onChange={(e) => {
  //               update({ firstName: e.target.value });
  //             }}
  //           />
  //         </Box>
  //         <Box mr={4}>  //           <TextField
  //             label={s("Efternamn")}
  //             type="text"
  //             value={user.lastName}
  //             InputLabelProps={{
  //               shrink: true,
  //             }}
  //             onChange={(e) => {
  //               update({ lastName: e.target.value });
  //             }}
  //           />
  //         </Box>
  //       </Box>
  //     </Paper>
  //   </Box>
  // );
};
