import * as dialog from "../ui/dialog";
import { EditGroup } from "../ui/pages/groups/dialogs/EditGroup";
import * as api from "../api";
import * as eventsApi from "../events";
import gql from "graphql-tag";
import { EditMembership } from "../ui/pages/account/dialogs/EditMembership";
import * as menuApi from "../ui/menu";
import { s } from "../localization";
import * as _ from "lodash";
import { extId, importItems } from "./import";

export async function editGroup(
  group: api.t.GroupFragment,
  groupTypes: api.t.GroupTypeFragment[],
  groups: api.t.GroupFragment[]
) {
  const result = await dialog.show(EditGroup, {
    group,
    groupTypes,
    groups,
    canDelete: !!group.id && group.type.kind === api.t.GroupTypeKind.Course,
  });

  if (result === "cancel") {
    return "cancel";
  }

  if (result === "delete") {
    return await deleteGroup(group.id);
  }

  return await upsertGroup({
    id: result.id,
    name: result.name,
    typeId: result.type.id,
    parentId: result.parent?.id,
  });
}

export async function getGroups(includeDescendants?: boolean) {
  const { getGroups, getGroupTypes } = await api.fetch<
    api.t.GetGroupsQuery,
    api.t.GetGroupsQueryVariables
  >({
    query: gql`
      query GetGroups($includeDescendants: Boolean) {
        getGroups(includeDescendants: $includeDescendants) {
          ...Group
        }
        getGroupTypes(includeDescendants: $includeDescendants) {
          ...GroupType
        }
      }
      ${api.fragments.group}
      ${api.fragments.groupType}
    `,
    variables: {
      includeDescendants,
    },
  });

  return {
    groups: getGroups,
    groupTypes: getGroupTypes,
  };
}

export async function getGroupById(id: string) {
  const { getGroupById } = await api.fetch<
    api.t.GetGroupByIdQuery,
    api.t.GetGroupByIdQueryVariables
  >({
    query: gql`
      query GetGroupById($id: ID!) {
        getGroupById(id: $id) {
          ...Group
        }
      }
      ${api.fragments.group}
    `,
    variables: {
      id,
    },
  });

  return getGroupById;
}

export const groupUpserted = eventsApi.createEvent<{
  group: api.t.GroupFragment;
}>("GroupUpserted");

export async function upsertGroup(input: api.t.UpsertGroupInput) {
  const { upsertGroup } = await api.fetch<
    api.t.UpsertGroupMutation,
    api.t.UpsertGroupMutationVariables
  >({
    query: gql`
      mutation UpsertGroup($input: UpsertGroupInput!) {
        upsertGroup(input: $input) {
          ...Group
        }
      }
      ${api.fragments.group}
    `,
    variables: {
      input,
    },
  });

  eventsApi.emit(groupUpserted({ group: upsertGroup }));

  return upsertGroup;
}

export const groupDeleted = eventsApi.createEvent<{
  groupId: string;
}>("GroupDeleted");

export async function deleteGroup(groupId: string) {
  const { deleteGroup } = await api.fetch<
    api.t.DeleteGroupMutation,
    api.t.DeleteGroupMutationVariables
  >({
    query: gql`
      mutation DeleteGroup($input: DeleteGroupInput!) {
        deleteGroup(input: $input) {
          ...Group
        }
      }
      ${api.fragments.group}
    `,
    variables: {
      input: {
        groupId,
      },
    },
  });

  eventsApi.emit(groupDeleted({ groupId }));

  return deleteGroup;
}

export async function editMembership(membership: api.t.Membership) {
  const result = await dialog.show(EditMembership, {
    membership,
  });

  if (result === "cancel") {
    return "cancel";
  }

  if (result === "delete") {
    return "delete";
  }

  return result;
  // return await upsertGroup(result);
}

export async function getMembershipTypes() {
  const { getMembershipTypes } = await api.fetch<
    api.t.GetMembershipTypesQuery,
    api.t.GetMembershipTypesQueryVariables
  >({
    query: gql`
      query GetMembershipTypes {
        getMembershipTypes {
          ...MembershipType
        }
      }
      ${api.fragments.membershipType}
    `,
    variables: {},
  });

  return getMembershipTypes;
}

export async function openMenu(
  groupId: string,
  options: Partial<menuApi.MenuOptions>
) {
  const items: menuApi.Item[] = [];
  const group = await getGroupById(groupId);
  const { groups, groupTypes } = await getGroups(true);

  if (group.permissions.some((p) => p === api.t.GroupPermission.Update)) {
    items.push({
      label: s("Redigera"),
      onClick: () => {
        editGroup(group, groupTypes, groups);
      },
    });
  }

  if (group.permissions.some((p) => p === api.t.GroupPermission.Delete)) {
    if (items.length > 0) {
      items.push("divider" as "divider");
    }

    items.push({
      label: s("Ta bort"),
      onClick: () => {
        deleteGroup(groupId);
      },
    });
  }

  menuApi.showMenu({
    minWidth: 200,
    anchor: "left",
    ...options,
    items: [...items, ...(options.items ? options.items : [])],
  });
}

export function hasPermissions(
  group: api.t.GroupFragment,
  permissions: api.t.GroupPermission[]
) {
  const set = new Set(group.permissions);
  return !permissions.some((p) => !set.has(p));
}

export function getTypesByGroup(
  groups: api.t.GroupFragment[],
  type: api.t.MembershipTypeFragment[],
  exclude: {
    group: api.t.GroupFragment;
    type: api.t.MembershipTypeFragment;
  }[]
) {
  const msByGroup = _.groupBy(exclude, (x) => x.group.id);
  const typesByGroup = new Map(
    groups.map((g) => {
      const activeTypes = type.filter((t) =>
        t.groupTypes.some((gt) => g.type.id === gt.id)
      );

      if (!msByGroup[g.id]) {
        return [g.id, activeTypes];
      } else {
        return [
          g.id,
          activeTypes.filter(
            (t) => !msByGroup[g.id].some((x) => x.type.id === t.id)
          ),
        ];
      }
    })
  );

  return typesByGroup;
}

export async function importGroups(label: string) {
  importItems({
    onParse: (data) => ({
      id: extId(data.id),
      name: data.name,
      typeId: extId(data.typeId),
      parentId: extId(data.parentId),
    }),
    batchSize: 10,
    objectLabel: label.toLowerCase(),
    onBatchImport: async (batch: api.t.UpsertGroupInput[]) => {
      for (var group of batch) {
        await upsertGroup(group);
      }
      return batch.length;
    },
    requiredFields: ["id", "name", "parentId", "typeId"],
  });
}
