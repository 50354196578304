import axios, { AxiosResponse, CancelToken } from "axios";

export type FetchFunc = (
  info: RequestInfo,
  init: RequestInit,
  cancel?: CancelFetch
) => Promise<Response>;

export class ClientError extends Error {
  constructor(message: string, readonly innerError: Error) {
    super(message);
    Object.setPrototypeOf(this, ClientError.prototype);
  }
}

export class ServerError extends Error {
  constructor(message: string, readonly innerError: Error) {
    super(message);
    Object.setPrototypeOf(this, ServerError.prototype);
  }
}

export class NetworkError extends Error {
  constructor(
    message: string,
    readonly innerError: Error,
    readonly timeout: boolean
  ) {
    super(message);
    Object.setPrototypeOf(this, NetworkError.prototype);
  }
}

export class UserCancelError extends Error {
  constructor(message: string, readonly innerError: Error) {
    super(message);
    Object.setPrototypeOf(this, UserCancelError.prototype);
  }
}

const requestTimeout = 15000;

type Middleware = (
  info: RequestInfo,
  init: RequestInit,
  next: FetchFunc
) => Promise<Response>;

let middlewares: Middleware[] = [];

export function applyMiddleware(middleware: Middleware) {
  middlewares.push(middleware);
}

type CancelReason = "timeout" | "user";

export class CancelFetch {
  private _source = axios.CancelToken.source();
  private _reason?: CancelReason;

  cancel(reason: CancelReason) {
    this._reason = reason;
    this._source.cancel(reason);
  }

  get reason(): CancelReason | undefined {
    return this._reason;
  }

  get token(): CancelToken {
    return this._source.token;
  }
}

export async function defaultFetch(
  input: RequestInfo,
  init: RequestInit,
  cancel: CancelFetch = new CancelFetch()
): Promise<Response> {
  const headers = Object.keys(init.headers as any).reduce(
    (h, k) => ({
      ...h,
      [k.toLowerCase()]: (init.headers as any)[k],
    }),
    {}
  );

  const config = {
    url: input.toString(),
    method: init.method || ("GET" as any),
    data: init.body,
    headers,
    // Treat any status code outside 2xx as errors
    validateStatus: (status: number) => status >= 200 && status < 300,
    timeout: requestTimeout,
  };

  let result: AxiosResponse<any>;
  let timeoutTimer: NodeJS.Timer | null = null;
  try {
    // https://github.com/axios/axios/issues/647
    timeoutTimer = setTimeout(() => {
      timeoutTimer = null;
      cancel.cancel("timeout");
    }, requestTimeout);

    result = await axios.request({
      ...config,
      cancelToken: cancel.token,
    });
    // prettier-ignore
  } catch (error: any) {
    if (cancel.reason === "timeout") {
      throw new NetworkError(
        `Reached timeout after ${requestTimeout}ms.`,
        error,
        true
      );
    } else if (cancel.reason === "user") {
      throw new UserCancelError("User cancelled request.", error);
    } else if (error.response) {
      // This is an error responded by the server
      const { data } = error.response;
      const message = data && data.message;
      throw new ServerError(
        error.message + (message ? ` (${message})` : ""),
        error
      );
    } else if (error.request) {
      throw new NetworkError(error.message, error, false);
    } else {
      // This is a local error
      throw new ClientError(error.message, error);
    }
  } finally {
    if (timeoutTimer != null) {
      clearTimeout(timeoutTimer);
    }
  }

  // Convert the Axios style response into a `fetch` style response
  const responseBody =
    typeof result.data === `object` ? JSON.stringify(result.data) : result.data;

  const resultHeaders = new Headers();
  Object.keys(result.headers).forEach(([key, value]: any) => {
    resultHeaders.append(key, value);
  });

  return new Response(responseBody, {
    status: result.status,
    statusText: result.statusText,
    headers: resultHeaders,
  });
}

export function withRetry(next: FetchFunc, retries: number): FetchFunc {
  return async (info, init, cancel) => {
    let lastError;
    for (let i = 0; i < retries; i++) {
      try {
        return await next(info, init, cancel);
      } catch (error: any) {
        lastError = error;

        if (error instanceof UserCancelError) {
          // Do not retry if the user invoked the error
          break;
        }

        console.log(
          `An error occured while performing a network request. Retrying...`,
          error.message
        );
      }
    }
    throw lastError;
  };
}

export function withNetStatus(
  next: FetchFunc,
  timeout: number,
  onChange: (unstable: boolean) => void
): FetchFunc {
  return async (info, init, cancel) => {
    let unstable = false;
    let isCompleted = false;
    try {
      setTimeout(() => {
        if (!isCompleted) {
          unstable = true;
          onChange(unstable);
        }
      }, timeout);

      return await next(info, init, cancel);
    } finally {
      isCompleted = true;

      if (unstable) {
        unstable = false;
        onChange(unstable);
      }
    }
  };
}

export function withJwt(
  next: FetchFunc,
  jwtGetter: () => Promise<string | null>
): FetchFunc {
  return async (info, init, cancel) => {
    const token = await jwtGetter();
    if (token !== null) {
      init.headers = {
        ...init.headers,
        authorization: `Bearer ${token}`,
      };
    }
    return next(info, init, cancel);
  };
}

export function withAnalytics(
  next: FetchFunc,
  success: (name: string, duration: number) => void,
  failure: (name: string, timeout: boolean) => void,
  nameParsers: ((init: RequestInit) => string | null)[]
): FetchFunc {
  // Get the name of the graphql query

  return async (info, init, cancel) => {
    let completed = true;
    let timeout = false;
    let start = new Date();
    try {
      return await next(info, init, cancel);
    } catch (error) {
      completed = false;
      timeout = error instanceof NetworkError && error.timeout;
      throw error;
    } finally {
      let name: string | null = null;
      for (var parser of nameParsers) {
        name = parser(init);
        if (name) {
          break;
        }
      }

      const end = new Date();
      const duration = end.getTime() - start.getTime();

      name = name || "<unknown>";

      if (completed) {
        success(name, duration);
      } else {
        failure(name, timeout);
      }
    }
  };
}
