import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import * as React from "react";
import { s } from "../../localization";
import { DialogProps } from "../dialog";
import { ButtonEx } from "../components/SubmitButton";

export interface ConfirmDialogProps extends DialogProps<"cancel" | "confirm"> {
  title: string;
  description: string;
  buttonLabel: string;
}

export const ConfirmDialog = (props: ConfirmDialogProps) => {
  const cancel = React.useCallback(() => {
    props.onClose("cancel");
  }, [props.onClose]);

  const confirm = React.useCallback(() => {
    props.onClose("confirm");
  }, [props.onClose]);

  return (
    <Dialog
      open
      onClose={props.onClose}
      disableBackdropClick={!props.dismissable}
      disableEnforceFocus
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{props.description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonEx onClick={cancel} variant="text" color="primary">
          {s("Avbryt")}
        </ButtonEx>
        <ButtonEx onClick={confirm}>{props.buttonLabel}</ButtonEx>
      </DialogActions>
    </Dialog>
  );
};
