import * as events from "./eventemitter";
export * from "./eventemitter";
/*
export interface SignedInPayload {
  jwt: string;
}
export const signedIn = events.createEvent<SignedInPayload>("SignedIn");
export const signedOut = events.createEvent("SignedOut");
*/
export interface ApiErrorPayload {
  message: string;
  code?: string;
}

export const apiError = events.createEvent<ApiErrorPayload>("ApiError");
