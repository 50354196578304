import gql from "graphql-tag";
import { s } from "src/localization";
import {
  BulkImportDialog,
  BulkImportDialogProps,
} from "src/ui/dialogs/BulkImportDialog";
import { wait } from "src/utils/async";
import * as api from "../api";
import * as events from "../eventemitter";
import * as dialog from "../ui/dialog";
import { EditUser } from "../ui/pages/account/dialogs/EditUser";
import { extId, importItems } from "./import";

const jwtStorageKey = "jwt";

export interface Session {
  user: api.t.UserFragment;
  token: string;
}

export interface SessionUpdatePayload {
  session: Session | null;
}

export const sessionUpdate = events.createEvent<SessionUpdatePayload>(
  "SessionUpdate"
);

let session: Promise<Session | null> = Promise.resolve(null);

export async function loadSession() {
  session = new Promise(async (resolve, reject) => {
    // First check if there's a ticket in the url, if so use that one

    const token = await localStorage.getItem(jwtStorageKey);
    if (!token) {
      resolve(null);
    } else {
      resolve(await createSession(token));
    }
  });

  await session;

  return session;
}

export async function getSession() {
  return session;
}

async function createSession(token: string) {
  api.setJwt(token);

  try {
    const { me: user } = await api.fetch<api.t.MeQuery, api.t.MeQueryVariables>(
      {
        query: gql`
          query Me {
            me {
              ...User
            }
          }
          ${api.fragments.user}
        `,
        variables: {},
      }
    );

    if (user) {
      return {
        user,
        token,
      };
    }
  } catch (error) {
    clearSession(false);
  }

  return null;
}

export async function clearSession(clearStorage: boolean = true) {
  api.clearJwt();

  if (clearStorage) {
    localStorage.removeItem(jwtStorageKey);
  }

  session = Promise.resolve(null);

  events.emit(
    sessionUpdate({
      session: null,
    })
  );
}

export async function updateSession(token: string, persist: boolean) {
  session = createSession(token);

  await session;

  if (persist) {
    localStorage.setItem(jwtStorageKey, token);
  }

  events.emit(
    sessionUpdate({
      session: await session,
    })
  );

  return session;
}

export function isLoggedIn() {
  return session !== null;
}

export async function registerUser(data: {
  firstName: string;
  lastName: string;
  email: string;
}) {
  const { registerUser: user } = await api.fetch<
    api.t.RegisterUserMutation,
    api.t.RegisterUserMutationVariables
  >({
    query: gql`
      mutation RegisterUser($input: RegisterUserInput!) {
        registerUser(input: $input) {
          ...User
        }
      }
      ${api.fragments.user}
    `,
    variables: {
      input: data,
    },
  });

  return user;
}

export async function requestAuthTicket(email: string) {
  const { requestAuthTicket: succes } = await api.fetch<
    api.t.RequestAuthTicketMutation,
    api.t.RequestAuthTicketMutationVariables
  >({
    query: gql`
      mutation RequestAuthTicket($input: RequestAuthTicketInput!) {
        requestAuthTicket(input: $input)
      }
    `,
    variables: {
      input: {
        email,
      },
    },
  });

  return succes;
}

export async function login(data: { ticket: string; email: string }) {
  const {
    login: { token },
  } = await api.fetch<api.t.LoginMutation, api.t.LoginMutationVariables>({
    query: gql`
      mutation Login($input: LoginInput!) {
        login(input: $input) {
          token
        }
      }
    `,
    variables: {
      input: data,
    },
  });

  return token;
}

export async function getUsers(search?: string) {
  const { getUsers, getGroups, getMembershipTypes } = await api.fetch<
    api.t.GetUsersQuery,
    api.t.GetUsersQueryVariables
  >({
    query: gql`
      query GetUsers($search: String) {
        getUsers(search: $search) {
          ...User
        }
        getGroups(includeDescendants: true) {
          ...Group
        }
        getMembershipTypes {
          ...MembershipType
        }
      }
      ${api.fragments.user}
      ${api.fragments.group}
      ${api.fragments.membershipType}
    `,
    variables: {
      search,
    },
  });

  return {
    users: getUsers,
    groups: getGroups,
    membershipTypes: getMembershipTypes,
  };
}

export async function editUser(
  user: api.t.UserFragment,
  groups: api.t.GroupFragment[],
  membershipTypes: api.t.MembershipTypeFragment[]
) {
  const result = await dialog.show(EditUser, {
    user,
    groups,
    membershipTypes,
  });

  if (result === "cancel") {
    return "cancel";
  }

  if (result === "delete") {
    return "delete";
  }

  const input: api.t.UpsertUserInput = {
    id: result.id,
    firstName: result.firstName,
    lastName: result.lastName,
    email: result.email,
    memberships: result.memberships.map((x) => ({
      id: x.id,
      groupId: x.group.id,
      typeId: x.type.id,
    })),
  };

  return await upsertUser(input);
}

export async function upsertUser(input: api.t.UpsertUserInput) {
  const { upsertUser } = await api.fetch<
    api.t.UpsertUserMutation,
    api.t.UpsertUserMutationVariables
  >({
    query: gql`
      mutation UpsertUser($input: UpsertUserInput!) {
        upsertUser(input: $input) {
          ...User
        }
      }
      ${api.fragments.user}
    `,
    variables: {
      input,
    },
  });

  return upsertUser;
}

export async function addUser(
  groups: api.t.GroupFragment[],
  membershipTypes: api.t.MembershipTypeFragment[]
) {
  const user = await dialog.show(EditUser, {
    groups,
    membershipTypes,
    user: {
      __typename: "User",
      email: "",
      firstName: "",
      lastName: "",
      id: "",
      memberships: [],
    },
  });

  if (user === "cancel" || user === "delete") {
    return "cancel";
  }

  const input: api.t.UpsertUserInput = {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    memberships: user.memberships.map((x) => ({
      id: x.id,
      groupId: x.group.id,
      typeId: x.type.id,
    })),
  };

  return await upsertUser(input);
}

export async function importUsers() {
  importItems({
    onParse: (data) => ({
      id: extId(data.id),
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      memberships: [
        { id: "", groupId: extId(data.groupId), typeId: extId(data.typeId) },
      ],
    }),
    batchSize: 10,
    objectLabel: s("personer"),
    onBatchImport: async (batch: api.t.UpsertUserInput[]) => {
      for (var user of batch) {
        await upsertUser(user);
      }
      return batch.length;
    },
    requiredFields: [
      "id",
      "firstName",
      "lastName",
      "email",
      "groupId",
      "typeId",
    ],
  });
}
