import * as React from "react";
import { Box, BoxProps, Chip, Divider, Typography } from "@material-ui/core";
import * as _ from "lodash";
import { Role, SelectRoleEx, SelectRoleExProps } from "./SelectRoleEx";
import { ButtonEx } from "./SubmitButton";
import * as menuApi from "../menu";
import * as api from "../../api";
import { s } from "../../localization";

export interface RoleListEx<T extends Role> {
  selected: T[];
  kinds: api.t.GroupTypeKind[];
  groups: api.t.GroupFragment[];
  membershipTypes: api.t.MembershipTypeFragment[];
  onDelete: (item: T) => void;
  onAdd: (item: Role) => void;
  toAddTitle: string;
  title: string;
}

export const RoleListEx = <T extends Role>(props: RoleListEx<T>) => {
  const {
    selected,
    membershipTypes,
    onAdd,
    onDelete,
    toAddTitle,
    kinds,
  } = props;

  const groupGroups = React.useMemo(
    () =>
      _.groupBy(
        selected.filter((s) => kinds.indexOf(s.group.type.kind) >= 0),
        (x) => x.group.id
      ),
    [selected]
  );

  const groups = React.useMemo(
    () => props.groups.filter((g) => kinds.indexOf(g.type.kind) >= 0),
    [props.groups]
  );

  const { current: id } = React.useRef(
    `add-group-${new Date().valueOf().toString()}`
  );

  return (
    <Box display="flex" flex={1} flexDirection="column">
      <Box display="flex">
        <Box flex={1}>
          <Typography variant="body1">{props.title}</Typography>
        </Box>
        <ButtonEx
          color="primary"
          id={id}
          size="small"
          variant="text"
          onClick={async () => {
            const result = await menuApi.showDrop<
              Role,
              SelectRoleExProps<T> & BoxProps
            >(
              SelectRoleEx,
              {
                mt: 1,
                types: membershipTypes,
                groups,
                selected,
              },
              {
                anchor: `#${id}`,
                align: "right",
                width: 230,
              }
            );

            if (result !== "cancel") {
              onAdd(result);
            }
          }}
        >
          {toAddTitle}
        </ButtonEx>
      </Box>
      <Divider />

      <Box minHeight={25} display="flex" flexDirection="column">
        {Object.keys(groupGroups).length === 0 && (
          <Box fontStyle="italic" mt={1}>
            <Typography variant="subtitle2" color="secondary">
              {s("Inget valt. Tryck ovan till höger för att lägga till.")}
            </Typography>
          </Box>
        )}

        {Object.keys(groupGroups).map((id, index) => {
          const { group } = groupGroups[id][0];
          const types = groupGroups[id].map((x) => x.type);
          return (
            <>
              {/* {index > 0 && <Divider />} */}
              <Box
                flex={1}
                style={{ marginTop: 3 }}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <Box flex={1}>
                  <Typography variant="body2">{group.name}</Typography>
                </Box>
                {types.reverse().map((t) => (
                  <Chip
                    style={{ marginLeft: 5 }}
                    size="small"
                    label={t.name}
                    onDelete={() => {
                      onDelete(
                        selected.find(
                          (x) => x.group.id === group.id && x.type.id === t.id
                        )!
                      );
                    }}
                  />
                ))}
              </Box>
            </>
          );
        })}
      </Box>
    </Box>
  );
};
