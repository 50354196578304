import * as React from "react";
import cuid from "cuid";
import gql from "graphql-tag";
import slugify from "@sindresorhus/slugify";
import * as _ from "lodash";
import * as api from "../api";
import { Transient } from "./types";
import * as nav from "../ui/navigation";
import * as notifications from "../ui/notification";
import * as routes from "../ui/pages/routes";
import * as menuApi from "../ui/menu";
import * as eventsApi from "../events";
import * as dialog from "../ui/dialog";
import { s } from "../localization";
import { portal } from "../ui/portal";
import { ConfirmDialog } from "../ui/dialogs/ConfirmDialog";
import { ErrorDialog } from "src/ui/components/ErrorDialog";
import { ConfirmPublishDialog } from "src/ui/pages/forms/dialogs/ConfirmPublish";
import { ConfirmUnlockDialog } from "src/ui/pages/forms/dialogs/ConfirmUnlock";
// import { CSVDownload } from "src/ui/components";
import { Data } from "react-csv/components/CommonPropTypes";
import { CSVDownload } from "react-csv";
import { ExportCsvDialog } from "src/ui/dialogs/ExportCsvDialog";

export function createComponent(): Transient<api.t.FormComponentFragment> {
  return {
    __key: cuid(),
    __typename: "FormComponent",
    id: "",
    questions: [],
    title: "",
    description: "",
  };
}

export function createForm(
  createdBy: api.t.UserFragment
): Transient<api.t.FormFragment> {
  return {
    __key: cuid(),
    __typename: "Form",
    id: "",
    title: "",
    archivedAt: null,
    locked: false,
    description: "",
    components: [],
    createdAt: new Date(),
    createdBy,
    targets: [],
    startAt: new Date(),
    status: {
      pendingCount: 0,
      completedCount: 0,
    },
    permissions: [],
  };
}

export function createOption(): Transient<api.t.FormQuestionOptionFragment> {
  return {
    __key: cuid(),
    __typename: "FormQuestionOption",
    id: "",
    label: "",
  };
}

export function createQuestion(
  type:
    | api.t.FormQuestionType.FormQuestionMultiChoice
    | api.t.FormQuestionType.FormQuestionSingleChoice
    | api.t.FormQuestionType.FormQuestionText
):
  | Transient<api.t.FormQuestionMultiChoiceFragment>
  | Transient<api.t.FormQuestionSingleChoiceFragment>
  | Transient<api.t.FormQuestionTextFragment> {
  return {
    __key: cuid(),
    __typename: type,
    id: "",
    options: [createOption()],
    question: "",
  };
}

export function createGroupFormTarget(
  group: api.t.GroupFragment,
  type: api.t.MembershipTypeFragment
): Transient<api.t.GroupFormTargetFragment> {
  return {
    __key: cuid(),
    __typename: "GroupFormTarget",
    id: "",
    group,
    type,
    includeChildGroups: false,
  };
}

export function createAnswerInput(
  questionId: string
): Transient<api.t.AnswerInput> {
  return {
    __key: cuid(),
    questionId,
  };
}

export const formUpdated = eventsApi.createEvent<{
  form: api.t.FormFragment;
}>("FormUpdated");

export async function upsertForm(form: api.t.FormFragment) {
  const input: api.t.UpsertFormInput = {
    id: form.id,
    title: form.title,
    description: form.description,
    startAt: form.startAt,
    endAt: form.endAt,
    startAfter: form.startAfter && {
      formId: form.startAfter.form.id,
      afterDays: form.startAfter.afterDays,
    },
    components: form.components.map((c) => ({
      id: c.id,
      title: c.title,
      description: c.description,
      questions: c.questions.map((q) => ({
        id: q.id,
        question: q.question,
        type: q.__typename! as api.t.FormQuestionType,
        options:
          q.__typename !== "FormQuestionText"
            ? q.options.map((o) => ({
                id: o.id,
                label: o.label,
              }))
            : [],
      })),
    })),
    groupTargets: form.targets
      .filter((t) => t.__typename === "GroupFormTarget")
      .map((t) => ({
        id: t.id,
        groupId: t.group.id,
        typeId: t.type.id,
        includeChildGroups: t.includeChildGroups,
      })),

    permissions: form.permissions.map((p) => {
      if (p.target.__typename === "Membership") {
        return {
          type: p.type,
          role: p.role,
          targetMembership: {
            id: p.target.id,
            groupId: p.target.group.id,
            typeId: p.target.type.id,
          },
        };
      } else {
        return {
          type: p.type,
          role: p.role,
          targetUserId: p.target.id,
        };
      }
    }),
  };

  const { upsertForm } = await api.fetch<
    api.t.UpsertFormMutation,
    api.t.UpsertFormMutationVariables
  >({
    query: gql`
      mutation UpsertForm($input: UpsertFormInput!) {
        upsertForm(input: $input) {
          ...Form
        }
      }
      ${api.fragments.form}
    `,
    variables: {
      input,
    },
  });

  eventsApi.emit(formUpdated({ form: upsertForm }));

  return upsertForm;
}

export async function getForms() {
  const { getForms } = await api.fetch<
    api.t.GetFormsQuery,
    api.t.GetFormsQueryVariables
  >({
    query: gql`
      query GetForms {
        getForms {
          ...Form
        }
      }
      ${api.fragments.form}
    `,
    variables: {},
  });

  return getForms;
}

export async function getFormsDisplay() {
  const { getForms } = await api.fetch<
    api.t.GetFormsDisplayQuery,
    api.t.GetFormsDisplayQueryVariables
  >({
    query: gql`
      query GetFormsDisplay {
        getForms {
          ...FormDisplay
        }
      }
      ${api.fragments.formDisplay}
    `,
    variables: {},
  });

  return getForms;
}

export async function getFormToFillById(id: string) {
  const { getFormById } = await api.fetch<
    api.t.GetFormToFillByIdQuery,
    api.t.GetFormToFillByIdQueryVariables
  >({
    query: gql`
      query GetFormToFillById($id: ID!) {
        getFormById(id: $id) {
          id
          title
          components {
            ...FormComponent
          }
          status {
            completed {
              id
            }
            pending {
              id
            }
          }
        }
      }
      ${api.fragments.formComponent}
    `,
    variables: {
      id: id || "",
    },
  });

  return getFormById;
}

export async function getFormById(id: string) {
  const { getFormById, getGroups, getMembershipTypes } = await api.fetch<
    api.t.GetFormByIdQuery,
    api.t.GetFormByIdQueryVariables
  >({
    query: gql`
      query GetFormById($id: ID!) {
        getFormById(id: $id) {
          ...Form
        }
        getMembershipTypes {
          ...MembershipType
        }
        getGroups(includeDescendants: true) {
          ...Group
        }
      }
      ${api.fragments.form}
      ${api.fragments.group}
      ${api.fragments.membershipType}
    `,
    variables: {
      id: id || "",
    },
  });

  return {
    form: getFormById,
    groups: getGroups,
    membershipTypes: getMembershipTypes,
  };
}

export async function registerAnswers(formAnswers: api.t.FormAnswersInput) {
  const input: api.t.FormAnswersInput = {
    answers: formAnswers.answers.map((a) => ({
      questionId: a.questionId,
      multiChoice: a.multiChoice,
      singleChoice: a.singleChoice,
      text: a.text,
    })),
    formId: formAnswers.formId,
  };

  const { registerAnswers } = await api.fetch<
    api.t.RegisterAnswersMutation,
    api.t.RegisterAnswersMutationVariables
  >({
    query: gql`
      mutation RegisterAnswers($input: FormAnswersInput!) {
        registerAnswers(input: $input) {
          ...FormAnswers
        }
      }
      ${api.fragments.formAnswers}
    `,
    variables: {
      input,
    },
  });

  return upsertForm;
}

export function validateQuestion(
  answer: api.t.AnswerInput,
  question: api.t.FormQuestionFragment
) {
  switch (question.__typename) {
    case "FormQuestionMultiChoice": {
      return true;
    }
    case "FormQuestionSingleChoice": {
      return !!answer.singleChoice;
    }
    case "FormQuestionText": {
      return (
        answer.text !== "" && answer.text !== null && answer.text !== undefined
      );
    }
  }
  return false;
}

export function validateAnswers(
  answers: api.t.AnswerInput[],
  component: api.t.FormComponentFragment
) {
  const answerByQuestion = new Map(answers.map((a) => [a.questionId, a]));
  return !component.questions.some(
    (q) =>
      !validateQuestion(
        answerByQuestion.get(q.id) || createAnswerInput(q.id),
        q
      )
  );
}

export async function getFormSummary(formId: string) {
  const { getFormSummary } = await api.fetch<
    api.t.GetFormSummaryQuery,
    api.t.GetFormSummaryQueryVariables
  >({
    query: gql`
      query GetFormSummary($formId: ID!) {
        getFormSummary(formId: $formId) {
          ...FormSummary
        }
      }
      ${api.fragments.formSummary}
    `,
    variables: {
      formId,
    },
  });

  return getFormSummary;
}

export const formDeleted = eventsApi.createEvent<{
  formId: string;
  archive?: boolean;
}>("FormDeleted");

export async function deleteForm(formId: string) {
  const { form } = await getFormById(formId);
  const archive = !form.archivedAt;

  if (!archive) {
    const result = await dialog.show(ConfirmDialog, {
      title: s("Bekräfta borttagning"),
      description: s(
        'Den här åtgärden kommer att permanent ta bort formuläret "{form}". Du kommer inte kunna återställa efter att det tagits bort permanent. Vill du fortsätta?',
        {
          form: form.title,
        }
      ),
      buttonLabel: s("Ja, ta bort"),
    });

    if (result === "cancel") {
      return;
    }
  }

  const { deleteForm } = await api.fetch<
    api.t.DeleteFormMutation,
    api.t.DeleteFormMutationVariables
  >({
    query: gql`
      mutation DeleteForm($input: DeleteFormInput!) {
        deleteForm(input: $input) {
          ...Form
        }
      }
      ${api.fragments.form}
    `,
    variables: {
      input: {
        formId,
        archive,
      },
    },
  });

  eventsApi.emit(formDeleted({ formId, archive }));

  return deleteForm;
}

export const formRestored = eventsApi.createEvent<{
  formId: string;
}>("FormRestored");

export async function restoreForm(formId: string) {
  const { restoreForm } = await api.fetch<
    api.t.RestoreFormMutation,
    api.t.RestoreFormMutationVariables
  >({
    query: gql`
      mutation RestoreForm($input: RestoreFormInput!) {
        restoreForm(input: $input) {
          ...Form
        }
      }
      ${api.fragments.form}
    `,
    variables: {
      input: {
        formId,
      },
    },
  });

  eventsApi.emit(formRestored({ formId }));

  return deleteForm;
}

export async function unlockForm(form: api.t.FormFragment) {
  const result = await dialog.show(ConfirmUnlockDialog, {});

  if (result === "cancel") {
    return;
  }

  const { unlockForm } = await api.fetch<
    api.t.UnlockFormMutation,
    api.t.UnlockFormMutationVariables
  >({
    query: gql`
      mutation UnlockForm($input: UnlockFormInput!) {
        unlockForm(input: $input) {
          ...Form
        }
      }
      ${api.fragments.form}
    `,
    variables: {
      input: {
        formId: form.id,
      },
    },
  });

  eventsApi.emit(formUpdated({ form: unlockForm }));

  return unlockForm;
}

export async function publishForm(form: api.t.FormFragment) {
  if (form.targets.length === 0) {
    await dialog.show(ErrorDialog, {
      title: s("Målgrupp saknas"),
      description: s("Du måste ange målgrupp innan du kan publicera.", {
        form: form.title,
      }),
    });
    return;
  }

  const result = await dialog.show(ConfirmPublishDialog, {
    form,
  });

  if (result === "cancel") {
    return;
  }

  const { publishForm } = await api.fetch<
    api.t.PublishFormMutation,
    api.t.PublishFormMutationVariables
  >({
    query: gql`
      mutation PublishForm($input: PublishFormInput!) {
        publishForm(input: $input) {
          ...Form
        }
      }
      ${api.fragments.form}
    `,
    variables: {
      input: {
        formId: form.id,
      },
    },
  });

  eventsApi.emit(formUpdated({ form: publishForm }));

  return publishForm;
}

export function isQuantQuestion(
  question: api.t.FormQuestionFragment
): question is
  | api.t.FormQuestionMultiChoiceFragment
  | api.t.FormQuestionSingleChoiceFragment {
  return (
    question.__typename === "FormQuestionMultiChoice" ||
    question.__typename === "FormQuestionSingleChoice"
  );
}

function getQuantTableData(summary: api.t.FormSummaryFragment) {
  const { questions } = summary;

  const headers = _.uniq(
    _.flatten(
      questions.map(({ question }) => {
        if (!isQuantQuestion(question)) {
          return [];
        }
        return question.options.map((o) => o.label);
      })
    )
  );

  const data = questions.map((q) => {
    if (!isQuantQuestion(q.question)) {
      return [];
    }
    return (q.options || []).reduce(
      (m, v) => {
        m[headers.indexOf(v.option.label) + 1] = v.count.toString();
        return m;
      },
      [q.question.question]
    );
  });

  return [["", ...headers], ...data];
}

function getQualTableData(summary: api.t.FormSummaryFragment) {
  const { questions } = summary;

  const data = _.flatten(
    questions.map((q) => {
      if (isQuantQuestion(q.question)) {
        return [];
      }

      return (q.textAnswers || []).map((text) => [
        [q.question.question],
        [text],
      ]);
    })
  );

  return data;
}

export async function exportCsvTable(filename: string, data: string | Data) {
  const handle = portal.push(
    React.createElement(CSVDownload, {
      data,
      target: "_blank",
      filename,
    })
  );
  portal.hide(handle);
}

export async function exportCsv(formId: string) {
  const summary = await getFormSummary(formId);

  const tables = [
    {
      title: s("Kvantitativ data"),
      subTitle: summary.form.title,
      data: getQuantTableData(summary),
      filename: slugify(summary.form.title) + ".csv",
    },
    {
      title: s("Kvalitativ data"),
      subTitle: summary.form.title,
      data: getQualTableData(summary),
      filename: slugify(summary.form.title) + "-q.csv",
    },
  ];

  dialog.show(ExportCsvDialog, {
    tables: tables.filter((t) => t.data.length > 0),
  });
}

export async function editForm(formId: string) {
  nav.navigate(routes.editForm, {
    params: {
      formId,
    },
  });
}

export async function showChart(formId: string) {
  nav.navigate(routes.chart, {
    params: {
      formId,
    },
  });
}

export async function sendReminders(formId: string) {
  const { sendReminders } = await api.fetch<
    api.t.SendRemindersMutation,
    api.t.SendRemindersMutationVariables
  >({
    query: gql`
      mutation SendReminders($input: SendRemindersInput!) {
        sendReminders(input: $input)
      }
    `,
    variables: {
      input: {
        formId,
      },
    },
  });
  return sendReminders;
}

export async function openMenu(
  formId: string,
  options: Partial<menuApi.MenuOptions>
) {
  const items: menuApi.Item[] = [];
  const { form } = await getFormById(formId);

  if (form.archivedAt) {
    items.push({
      label: s("Återställ"),
      onClick: () => {
        restoreForm(formId);
      },
    });
  } else {
    if (!nav.isRoute(routes.editForm)) {
      items.push({
        label: s("Redigera"),
        onClick: () => {
          editForm(formId);
        },
      });
    }

    if (!nav.isRoute(routes.chart) && form.publishedAt) {
      items.push({
        label: s("Visa resultat"),
        onClick: () => {
          showChart(formId);
        },
      });
    }

    if (form.publishedAt) {
      items.push(
        ...[
          {
            label: s("Exportera CSV"),
            onClick: () => {
              exportCsv(formId);
            },
          },
          {
            label: s("Skicka påminnelse"),
            onClick: async () => {
              await sendReminders(formId);
              notifications.success(s("Skickade påminnelser"));
            },
          },
        ]
      );
    }
  }

  if (form.publishedAt) {
    if (items.length > 0) {
      items.push("divider" as "divider");
    }

    items.push({
      label: s("Lås upp"),
      onClick: () => {
        unlockForm(form);
      },
    });
  }

  if (items.length > 0) {
    items.push("divider" as "divider");
  }

  items.push({
    label: s("Ta bort"),
    onClick: () => {
      deleteForm(formId);
    },
  });

  menuApi.showMenu({
    minWidth: 200,
    anchor: "left",
    ...options,
    items: [...items, ...(options.items ? options.items : [])],
  });
}
