import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Typography,
  Box,
} from "@material-ui/core";
import * as React from "react";
import { s } from "../../localization";
import { DialogProps } from "../dialog";
import { ButtonEx } from "../components/SubmitButton";
import * as csvParser from "csv-string";
import { Alert } from "@material-ui/lab";

export type CsvData = string[][];

export interface Result<T> {
  error?: string;
  warning?: string;
  items?: T[];
}

export interface BulkImportDialogProps<T> extends DialogProps<"cancel"> {
  objectLabel: string;
  requiredFields: string[];
  onParse: (data: CsvData) => Promise<Result<T>>;
  onImport: (
    items: T[],
    onProgress: (progress: number) => void
  ) => Promise<void>;
}

export const BulkImportDialog = <T,>(props: BulkImportDialogProps<T>) => {
  const [result, setResult] = React.useState<Result<T> | null>(null);
  const [state, setState] = React.useState<"idle" | "processing">("idle");
  const [progress, setProgress] = React.useState<number | null>(null);

  const cancel = React.useCallback(() => {
    props.onClose("cancel");
  }, [props.onClose]);

  const confirm = React.useCallback(async () => {
    try {
      setState("processing");
      await props.onImport(result!.items!, setProgress);
      props.onClose("cancel");
    } catch (err) {
      setResult({
        error: s("Ett fel uppstod. {error}", {
          error: err instanceof Error ? err.toString() : "",
        }),
      });
    } finally {
      setState("idle");
    }
  }, [props.onClose, props.onImport, result]);

  const onFile = React.useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
        setState("processing");
        setResult(null);
        setProgress(null);
        await new Promise<void>((resolve) => {
          const reader = new FileReader();
          reader.onload = async (e: any) => {
            const text = e.target.result;
            const csv: CsvData = csvParser.parse(text);
            const result = await props.onParse(csv);
            setResult(result);
            resolve();
          };
          reader.readAsText(event.target.files![0]);
        });
      } finally {
        setState("idle");
      }
    },
    []
  );

  return (
    <Dialog
      open
      onClose={props.onClose}
      disableBackdropClick={!props.dismissable}
      disableEnforceFocus
    >
      <DialogTitle>
        {s("Importera {object}", { object: props.objectLabel })}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <Box>
            {s("Här kan du importera {object} till systemet.", {
              object: props.objectLabel,
            })}
          </Box>
          <Box mt={2}>{s("Följande kolumner krävs:.")}</Box>
          {props.requiredFields.map((field) => (
            <Box ml={1}>
              <Typography color="textPrimary" variant="subtitle2">
                {field}
              </Typography>
            </Box>
          ))}
        </DialogContentText>

        <Box mt={4} mb={2}>
          <input type="file" onChange={onFile} />
        </Box>

        {result && (
          <>
            {result.error && (
              <Box mt={2}>
                <Alert severity="error">{result.error}</Alert>
              </Box>
            )}
            {result.warning && (
              <Box mt={2}>
                <Alert severity="warning">{result.warning}</Alert>
              </Box>
            )}
            {result.items && (
              <Box mt={2}>
                <Alert severity="info">
                  {s("{count} poster att importera.", {
                    count: result.items.length,
                  })}
                </Alert>
              </Box>
            )}
          </>
        )}

        {state === "processing" && (
          <Box mt={2} mb={2}>
            <Typography>{s("Arbetar...")}</Typography>
            <LinearProgress
              variant={progress !== null ? "determinate" : "indeterminate"}
              value={progress || 0}
            />
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <ButtonEx onClick={cancel} variant="text" color="primary">
          {s("Avbryt")}
        </ButtonEx>
        <ButtonEx
          disabled={!result?.items || state === "processing"}
          onClick={confirm}
        >
          {s("Importera")}
        </ButtonEx>
      </DialogActions>
    </Dialog>
  );
};
