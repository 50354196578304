export interface Route {
  path: string;
  exact?: boolean;
}

export const home: Route = {
  path: "/",
  exact: true,
};

export const editForm: Route = {
  path: "/form/:formId?",
  exact: true,
};

export const fillForm: Route = {
  path: "/fill/:formId",
  exact: false,
};

export const chart: Route = {
  path: "/chart/:formId",
  exact: true,
};

export const users: Route = {
  path: "/users",
  exact: true,
};

export const login: Route = {
  path: "/login",
};

export const register: Route = {
  path: "/register",
};

export const groups: Route = {
  path: "/groups",
};

export const courses: Route = {
  path: "/courses",
};

export function sub(route: Route, subPath: string) {
  return `${route.path}${subPath}`;
}

export function getSub(path: string) {
  const parts = path.split("/");
  return parts[parts.length - 1];
}
