import {
  Box,
  BoxProps,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import * as React from "react";

export interface SelectExProps {
  label?: string;
  onChange: (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    child: React.ReactNode
  ) => void;
  value?: unknown;
}

export const SelectEx = (props: SelectExProps & Omit<BoxProps, "onChange">) => {
  const { onChange, value, label, children, ...boxProps } = props;
  return (
    <Box {...boxProps}>
      <FormControl variant="outlined" fullWidth>
        {label && <InputLabel shrink>{label}</InputLabel>}
        <Select value={value} onChange={onChange} children={children} />
      </FormControl>
    </Box>
  );
};
