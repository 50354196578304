import * as React from "react";
import { Box, Container, Typography } from "@material-ui/core";
import { Redirect, RouteComponentProps } from "react-router";
import * as qs from "qs";
import { TopBar } from "../TopBar";
import * as userApi from "../../logic/user";
import * as notification from "../notification";
import * as events from "../../events";
import * as routes from "../pages/routes";
import { SideNav } from "../SideNav";
import { s } from "../../localization";

type OmitType<A, B> = Pick<A, Exclude<keyof A, keyof B>>;

interface WithSession {
  session: userApi.Session;
}

export function withSession<P>(
  Component:
    | React.ComponentClass<P & WithSession>
    | React.FunctionComponent<P & WithSession>
) {
  return React.memo((props: P) => {
    const [session, setSession] = React.useState<
      userApi.Session | null | "pending"
    >("pending");

    const onSessionUpdate = React.useCallback(
      (event: userApi.SessionUpdatePayload) => {
        setSession(event.session);
      },
      []
    );

    React.useEffect(() => {
      (async () => {
        setSession(await userApi.getSession());
      })();

      events.addListener(userApi.sessionUpdate, onSessionUpdate);
      return () => {
        events.removeListener(onSessionUpdate);
      };
    }, []);

    if (session === null) {
      return <Redirect to={routes.login.path} />;
    }

    if (session === "pending") {
      return null;
    }

    return <Component {...props} session={session} />;
  });
}

export function withTopBar<P extends WithSession>(
  Component: React.ComponentClass<P> | React.FunctionComponent<P>
) {
  return (props: P) => (
    <>
      <TopBar session={props.session} />
      <Component {...props} />
    </>
  );
}

export function withSideNav<P extends WithSession>(
  Component: React.ComponentClass<P> | React.FunctionComponent<P>
) {
  return React.memo((props: P) => (
    <Container maxWidth="lg">
      <Box display="flex" flexDirection="row" pt={9}>
        <Box mr={5} width={220}>
          <SideNav session={props.session} />
        </Box>
        <Component {...props} />
      </Box>
    </Container>
  ));
}

export interface WithQuery {
  query: qs.ParsedQs;
}

export function withQuery<P>(
  Component:
    | React.ComponentClass<P & WithQuery>
    | React.FunctionComponent<P & WithQuery>
) {
  return React.memo((props: P) => {
    const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const compProps: P & WithQuery = React.useMemo(() => {
      return {
        ...props,
        query,
      };
    }, [props]);

    return <Component {...compProps} />;
  });
}
