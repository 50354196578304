import {
  Box,
  BoxProps,
  OutlinedInputProps,
  TextField,
} from "@material-ui/core";
import * as React from "react";

export interface TextFieldExProps {
  placeholder?: string;
  label?: React.ReactNode;
  value?: unknown;
  onChangeText: (text: string) => void;
  onExit?: () => void;
  autoFocus?: boolean;
  size?: "small" | "medium";
}

export const TextFieldEx = (props: TextFieldExProps & BoxProps) => {
  const {
    label,
    placeholder,
    value,
    autoFocus,
    onChangeText,
    size,
    onExit,
    ...boxProps
  } = props;

  const textFieldProps = { label, value, placeholder, autoFocus, size };

  return (
    <Box {...boxProps}>
      <TextField
        {...textFieldProps}
        onKeyDown={(e) => {
          if (e.keyCode === 13 || e.keyCode === 27) {
            const htmlElement = document.activeElement as HTMLElement;
            if (htmlElement && htmlElement.blur) {
              htmlElement.blur();
            }
            if (onExit) {
              onExit();
            }
          }
        }}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        fullWidth
        onChange={(e: any) => onChangeText(e.target.value)}
      />
    </Box>
  );
};
