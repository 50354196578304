import * as React from "react";
import * as menuApi from "../../menu";
import * as api from "../../../api";
import * as formsApi from "../../../logic/forms";
import { ListEx } from "../../components/ListEx";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { s } from "../../../localization";

interface SelectFormProps {
  onSelect: (form: api.t.FormDisplayFragment) => void;
  form: api.t.FormDisplayFragment | null;
  filter?: (form: api.t.FormDisplayFragment) => boolean;
  label: string;
}

interface ListProps {
  onClose: (value: api.t.FormDisplayFragment | "cancel") => void;
  forms: api.t.FormDisplayFragment[];
}

const List = (props: ListProps) => {
  return (
    <ListEx
      getLabel={(v) => v.title}
      onGetItems={async () => props.forms}
      onCancel={() => props.onClose("cancel")}
      onClick={(value) => props.onClose(value)}
    />
  );
};

export const SelectForm = React.memo((props: SelectFormProps) => {
  const onShowList = React.useCallback(() => {
    (async () => {
      const forms = await formsApi.getForms();
      const result = await menuApi.showDrop<
        api.t.FormDisplayFragment,
        ListProps
      >(
        List,
        {
          forms: props.filter ? forms.filter(props.filter) : forms,
        },
        {
          anchor: "#after-form",
        }
      );

      if (result !== "cancel") {
        props.onSelect(result);
      }
    })();
  }, [props.onSelect]);

  return (
    <TextField
      style={{ width: "100%" }}
      id="after-form"
      InputLabelProps={{
        shrink: true,
      }}
      autoComplete="off"
      onClick={onShowList}
      value={props.form ? props.form.title : ""}
      variant="outlined"
      label={props.label}
      placeholder={s("Välj formulär...")}
      inputProps={{
        style: { caretColor: "transparent", cursor: "pointer" },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton size="small">
              <ArrowDropDownIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
});
