import * as React from "react";
import { portal } from "./portal";
import { Redirect, matchPath } from "react-router-dom";
import { Route } from "./pages/routes";

export interface NavOptions {
  query?: { [key: string]: string };
  params?: { [key: string]: any };
}

const Redirector = (props: {
  route: Route;
  options?: NavOptions;
  onDone: () => void;
}) => {
  const { route, options, onDone } = props;

  requestAnimationFrame(onDone);
  // React.useEffect(onDone, []);

  let search;
  let { path } = route;

  if (options) {
    const { query, params } = options;
    if (query) {
      const keys = Object.keys(query);
      if (keys.length > 0) {
        search = `?${keys
          .map((key) => `${key}=${encodeURI(query[key])}`)
          .join("&")}`;
      }
    }

    if (params) {
      const keys = Object.keys(params);
      keys.forEach((key) => {
        path = path.replace(new RegExp(`:${key}\\??`), params[key].toString());
      });
    }
  }

  return (
    <Redirect
      to={{
        pathname: path,
        search,
      }}
      push
    />
  );
};

export function navigate(route: Route, options?: Partial<NavOptions>) {
  const handle = portal.push(
    <Redirector
      route={route}
      options={options}
      onDone={() => {
        portal.hide(handle);
      }}
    />
  );
}

export function isRoute(route: Route) {
  return matchPath(window.location.pathname, {
    path: route.path,
    exact: true,
    strict: false,
  });
}
