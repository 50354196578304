import * as React from "react";
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import { PageRouter } from "./pages/Pages";
import classnames from "classnames";
import { addListener, removeListener } from "../eventemitter";
import * as events from "../events";
import { PortalOutput } from "./components/Portal";
import { portal } from "./portal";
import { createMuiTheme, CssBaseline, ThemeProvider } from "@material-ui/core";
import * as notification from "./notification";
import * as userApi from "../logic/user";
import { IntroGuide } from "./Guide";
import muiTheme from "./styles/theme";
import { WithQuery, withQuery } from "./components/enhancers";

const history = createBrowserHistory({});

const theme = createMuiTheme(muiTheme);

function onApiError(event: events.ApiErrorPayload) {
  alert("An error occured. Please try again later.");
}

async function loadSession(options: {
  email?: string;
  ticket?: string;
  persist?: boolean;
}) {
  const { email, ticket, persist } = options;
  if (email && ticket) {
    const token = await userApi.login({
      ticket: ticket as string,
      email: email as string,
    });

    if (token) {
      return userApi.updateSession(token, !!persist);
    } else {
      notification.error("Ogiltig inloggning.");
    }
  } else {
    return await userApi.loadSession();
  }
}

export const App = withQuery((props: WithQuery) => {
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      const { query } = props;

      await loadSession(query);

      setLoaded(true);
    })();

    addListener(events.apiError, onApiError);
    return () => {
      removeListener(onApiError);
    };
  }, []);

  return (
    <div
      id="page"
      className={classnames({
        container: true,
      })}
    >
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <React.Fragment>
            {loaded && <PageRouter />}
            <PortalOutput portal={portal} />
            <PortalOutput portal={notification.portal} />
            {/* <IntroGuide
              isOpen={isGuideOpen}
              onClose={() => setIsGuideOpen(false)}
            /> */}
          </React.Fragment>
        </ThemeProvider>
      </Router>
    </div>
  );
});
