import { createMuiTheme, ThemeOptions } from "@material-ui/core";

const defaultTheme = createMuiTheme();

const theme: ThemeOptions = {
  overrides: {
    MuiListSubheader: {
      sticky: {
        backgroundColor: "#fff",
        color: "#3891A6",
        textTransform: "uppercase",
        lineHeight: "30px",
        borderBottom: "1px solid #ccc",
      },
    },
    MuiInputLabel: {
      outlined: {
        backgroundColor: "#fff",
        paddingLeft: 10,
        paddingRight: 2,
      },
    },
    MuiPaper: {
      root: {
        // boxShadow: "none !important",
      },
      elevation0: {
        border: "1px solid #DBDFEA",
      },
      elevation1: {
        boxShadow: "none !important",
      },
    },
    MuiChip: {
      label: {
        textOverflow: "display",
      },
    },
    MuiNativeSelect: {
      root: {
        ...defaultTheme.typography.caption,
        textTransform: "uppercase",
        paddingLeft: "8px",
      },
    },
    MuiCircularProgress: {
      circle: {
        color: "#b2ff59",
      },
    },
    MuiTableCell: {
      root: {
        padding: "16px 0px",
      },
      head: {
        padding: "8px 0px",
      },
    },
    MuiButton: {
      root: {
        outline: "0px !important",
        minWidth: "0px",
      },
      outlined: {
        borderRadius: "0",
      },
    },
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: "1rem",
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "12px 14px",
      },
      adornedEnd: {
        paddingRight: "0px",
      },
    },
  },
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontSize: 12,
    fontFamily: "Poppins, sans-serif",
    h4: {
      fontSize: 36,
      fontWeight: "bold",
      color: "#364A63",
    },
    h5: {
      fontSize: 28,
      fontWeight: "bold",
      color: "#364A63",
    },
    // h6: {
    //   textTransform: "uppercase",
    //   fontWeight: "bold",
    //   color: "#444",
    // },
  },
  shape: {
    borderRadius: 4,
  },
  palette: {
    background: {
      default: "#fdfdff",
    },
    primary: {
      //      main: "#ef3340",
      main: "#3891A6",
    },
    secondary: {
      // main: "#ef3340",
      main: "#b2b2b2",
    },
    success: {
      // main: "#06D694",
      main: "#BCED09",
    },
  },
};

export default theme;
