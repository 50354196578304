import {
  Box,
  Button,
  Divider,
  Paper,
  TextField,
  Typography,
  Chip,
  Accordion,
  AccordionSummary,
  IconButton,
  TextFieldProps,
  Grid,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import * as React from "react";
import * as api from "../../../api";
import { s } from "../../../localization";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuIcon from "@material-ui/icons/Menu";
import produce from "immer";
import { removeInPlace, removeInPlaceIf } from "../../../utils/data";
import * as _ from "lodash";
import { getKey } from "../../../logic/types";
import * as formsApi from "../../../logic/forms";
import * as menuApi from "../../menu";
import * as nav from "../../navigation";
import * as permissions from "../../permissions";
import * as routes from "../routes";
import { ButtonEx } from "../../components/SubmitButton";
import { Role, RoleListEx } from "../../components";
import { useParams } from "react-router-dom";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import svLocale from "date-fns/locale/sv";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { StartAfter } from "./StartAfter";
import { useEvent } from "../../../eventemitter";
import * as dialog from "../../dialog";
import { ErrorDialog } from "../../components/ErrorDialog";
import { ConfirmPublishDialog } from "./dialogs/ConfirmPublish";
import * as dateFns from "date-fns";
import { LockArea } from "./LockArea";
import { FormQuestion } from "./FormQuestion";

const PublishedChip = withStyles((theme) => ({
  root: {
    borderColor: theme.palette.warning.dark,
    color: theme.palette.grey[900],
    backgroundColor: theme.palette.warning.light,
  },
}))(Chip);

const TextInput = (props: TextFieldProps & { onEnter?: () => void }) => (
  <TextField
    {...props}
    onKeyDown={(e) => {
      if (e.keyCode === 13 || e.keyCode === 27) {
        const htmlElement = document.activeElement as HTMLElement;
        if (htmlElement && htmlElement.blur) {
          htmlElement.blur();
        }
      }

      if (props.onEnter && e.keyCode === 13) {
        props.onEnter();
      }
    }}
  />
);

export const FormComponent = React.memo(
  (props: {
    isLocked: boolean;
    component: api.t.FormComponentFragment;
    onUpdate: (
      component: api.t.FormComponentFragment,
      data: Partial<api.t.FormComponentFragment>
    ) => void;
    onDelete: (component: api.t.FormComponentFragment) => void;
  }) => {
    const { component, onDelete, onUpdate } = props;

    const addQuestion = React.useCallback(
      (type: api.t.FormQuestionType) => {
        onUpdate(
          component,
          produce(component, (draft) => {
            draft.questions.push(formsApi.createQuestion(type));
          })
        );
      },
      [component]
    );

    const onAddQuestion = React.useCallback(() => {
      menuApi.showMenu({
        items: [
          {
            label: s("Flervalsfråga"),
            onClick: () => {
              addQuestion(api.t.FormQuestionType.FormQuestionMultiChoice);
            },
          },
          {
            label: s("Envalsfråga"),
            onClick: () => {
              addQuestion(api.t.FormQuestionType.FormQuestionSingleChoice);
            },
          },
          {
            label: s("Fritextfråga"),
            onClick: () => {
              addQuestion(api.t.FormQuestionType.FormQuestionText);
            },
          },
        ],
        anchor: `#add-question-${getKey(component)}`,
      });
    }, [component]);

    return (
      <Box border={1} borderColor="secondary.main">
        <Paper>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box pl={1} display="flex" flexDirection="row">
                <Typography variant="h6">
                  {s("Frågeavsnitt") +
                    (component.title ? " - " + component.title : "")}
                </Typography>
              </Box>
              <Box flex={1} />
              {!props.isLocked && (
                <IconButton size="small">
                  <DeleteIcon
                    onClick={(e) => {
                      onDelete(component);
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  />
                </IconButton>
              )}
            </AccordionSummary>

            <LockArea isLocked={props.isLocked}>
              <Box pb={3}>
                <Box px={3}>
                  <Box mb={3}>
                    <TextInput
                      autoFocus={!component.id}
                      variant="standard"
                      fullWidth
                      value={component.title}
                      placeholder={s("Ange en rubrik för avsnittet...")}
                      onChange={(e: any) =>
                        onUpdate(component, { title: e.target.value })
                      }
                    />
                  </Box>
                </Box>

                <Divider />

                <Box px={3} mt={3} ml={2} maxWidth={700} width="100%">
                  <Typography variant="subtitle2">{s("Frågor")}</Typography>
                  {component.questions.map((q, i) => (
                    <FormQuestion
                      key={getKey(q)}
                      label={(i + 1).toString()}
                      question={q}
                      onUpdate={(data) =>
                        onUpdate(
                          component,
                          produce(component, (draft) => {
                            _.assign(draft.questions[i], data);
                          })
                        )
                      }
                      onDelete={() =>
                        onUpdate(
                          component,
                          produce(component, (draft) => {
                            removeInPlace(i, draft.questions);
                          })
                        )
                      }
                      onOrderUp={
                        i > 0
                          ? () => {
                              onUpdate(
                                component,
                                produce(component, (draft) => {
                                  const tmp = draft.questions[i];
                                  draft.questions[i] = draft.questions[i - 1];
                                  draft.questions[i - 1] = tmp;
                                })
                              );
                            }
                          : undefined
                      }
                      onOrderDown={
                        i < component.questions.length - 1
                          ? () => {
                              onUpdate(
                                component,
                                produce(component, (draft) => {
                                  const tmp = draft.questions[i];
                                  draft.questions[i] = draft.questions[i + 1];
                                  draft.questions[i + 1] = tmp;
                                })
                              );
                            }
                          : undefined
                      }
                    />
                  ))}
                  <Box mt={3}>
                    <Button
                      id={`add-question-${getKey(component)}`}
                      size="small"
                      onClick={onAddQuestion}
                      variant="text"
                      color="primary"
                    >
                      {s("+ Lägg till fråga")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </LockArea>
          </Accordion>
        </Paper>
      </Box>
    );
  }
);

interface Data {
  form: api.t.FormFragment;
  groups: api.t.GroupFragment[];
  membershipTypes: api.t.MembershipTypeFragment[];
}

const PermissionChip = React.memo(
  (props: {
    permission: api.t.PermissionFragment;
    onDelete: (permission: api.t.PermissionFragment) => void;
  }) => {
    const p = props.permission;
    const isOwner = p.role === api.t.PermissionRole.Owner;
    const onDelete = React.useCallback(() => {
      props.onDelete(p);
    }, [p]);

    const title =
      p.target.__typename === "Membership"
        ? p.target.group.name
        : [p.target.firstName, p.target.lastName].join(" ");

    const tooltip = isOwner
      ? title + " - " + s("Ägare")
      : title + " - " + s("Kan redigera");

    return (
      <Tooltip title={tooltip}>
        <Chip
          key={p.target.id}
          variant={isOwner ? "default" : "outlined"}
          style={{ marginLeft: 5 }}
          size="small"
          label={title}
          onDelete={isOwner ? undefined : onDelete}
        />
      </Tooltip>
    );
  }
);

interface EditFormProps {
  data: Data;
}

const _EditForm = (props: EditFormProps) => {
  const [initial, setInitial] = React.useState<api.t.FormFragment>(
    props.data.form
  );
  const [status, setStatus] = React.useState<"idle" | "saving">("idle");
  const [autoSave, setAutoSave] = React.useState(false);
  const [data, setData] = React.useState<Data>(props.data);

  const changed = !_.isEqual(initial, data.form);

  React.useEffect(() => {
    setData(props.data);
    setInitial(props.data.form);
  }, [props.data]);

  React.useEffect(() => {
    if (autoSave) {
      onSave();
      setAutoSave(false);
    }
  }, [autoSave]);

  const setForm = React.useCallback(
    (cb: (form: api.t.FormFragment, draft: api.t.FormFragment) => void) => {
      setData((data) =>
        produce(data, (draft) => {
          cb(data.form, draft.form);
        })
      );
    },
    []
  );

  const onUpdateComponent = React.useCallback(
    (
      component: api.t.FormComponentFragment,
      componentData: Partial<api.t.FormComponentFragment>
    ) =>
      setForm((form, draft) => {
        _.assign(
          draft.components[form.components.indexOf(component)],
          componentData
        );
      }),
    []
  );

  const onDeleteComponent = React.useCallback(
    (component: api.t.FormComponentFragment) =>
      setForm((data, draft) => {
        removeInPlace(data.components.indexOf(component), draft.components);
      }),
    []
  );

  const onAddRole = React.useCallback(
    (role: Role) =>
      setForm((data, draft) => {
        draft.targets.push(
          formsApi.createGroupFormTarget(role.group, role.type)
        );
      }),
    []
  );

  const onDeleteRole = React.useCallback(
    (target: api.t.GroupFormTargetFragment) =>
      setForm((data, draft) => {
        removeInPlace(data.targets.indexOf(target), draft.targets);
      }),
    []
  );

  const onChangeDescription = React.useCallback((e: any) => {
    const { value } = e.target;
    setForm((data, draft) => {
      draft.description = value;
    });
  }, []);

  const onChangeTitle = React.useCallback((e: any) => {
    const { value } = e.target;
    setForm((data, draft) => {
      draft.title = value;
    });
  }, []);

  const onChangeStart = React.useCallback(
    (value: MaterialUiPickersDate | null) => {
      setForm((data, draft) => {
        draft.startAt = value;
      });
    },
    []
  );

  const onChangeEnd = React.useCallback(
    (value: MaterialUiPickersDate | null) => {
      setForm((data, draft) => {
        draft.endAt = value;
      });
    },
    []
  );

  const onSetStartAfter = React.useCallback(
    (value?: {
      form: api.t.FormDisplayFragment | null;
      afterDays: number | null;
    }) => {
      setForm((data, draft) => {
        draft.startAfter = value && {
          form: value.form as any,
          afterDays: value.afterDays as any,
        };
      });
    },
    []
  );

  const onAddFormComponent = React.useCallback(() => {
    setForm((data, draft) => {
      draft.components.push(formsApi.createComponent());
    });

    requestAnimationFrame(() => {
      window.scrollTo({
        left: 0,
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    });
  }, []);

  const onSave = React.useCallback(async () => {
    try {
      setStatus("saving");
      const form = produce(data!.form, (draft) => {
        draft.components.forEach((c) => {
          c.questions.forEach((q) => {
            if (q.__typename !== "FormQuestionText") {
              removeInPlaceIf(q.options, (x) => x.label.trim() === "");
            }
          });
          removeInPlaceIf(c.questions, (x) => {
            if (x.question.trim() === "") {
              return true;
            }
            if (x.__typename !== "FormQuestionText") {
              return x.options.length === 0;
            }
            return false;
          });
        });
        removeInPlaceIf(draft.components, (x) => x.questions.length === 0);
      });

      return await formsApi.upsertForm({
        ...form,
        // A little hacky
        startAfter:
          (form.startAfter &&
            form.startAfter.form !== null &&
            form.startAfter.afterDays !== null &&
            form.startAfter) ||
          null,
      });
    } finally {
      setStatus("idle");
    }
  }, [data.form]);

  const onSaveAndClose = React.useCallback(async () => {
    await onSave();

    nav.navigate(routes.home);
  }, [onSave]);

  const onMenu = React.useCallback(() => {
    formsApi.openMenu(data.form.id, {
      anchor: "#menu-btn",
      align: "right",
    });
  }, [data.form.id]);

  useEvent(
    formsApi.formDeleted,
    ({ formId }) => {
      if (data.form.id === formId) {
        nav.navigate(routes.home);
      }
    },
    [data.form.id]
  );

  const onPickSharing = React.useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const target = await permissions.pickSharing(
        {
          groups,
          membershipTypes,
          selected: [],
        },
        {
          anchor: "#sharing",
          align: "right",
        }
      );

      if (target !== "cancel" && target.__typename !== undefined) {
        const t: api.t.UserFragment | api.t.MembershipFragment | null =
          target.__typename === "Membership"
            ? {
                ...target,
                __typename: "Membership",
                group: target.group,
                id: target.id,
                type: target.type,
              }
            : target.__typename === "User"
            ? {
                ...target,
                __typename: "User",
                id: target.id,
                firstName: target.firstName,
              }
            : null;

        if (t !== null) {
          setForm((data, draft) => {
            const permission: api.t.PermissionFragment = {
              role: api.t.PermissionRole.Writer,
              type:
                target.__typename === "Membership"
                  ? api.t.PermissionType.Group
                  : api.t.PermissionType.User,
              target: t,
            };
            draft.permissions.push(permission);
          });

          setAutoSave(true);
        }
      }

      e.stopPropagation();
      e.preventDefault();
    },
    [data.groups, data.membershipTypes, onSave]
  );

  const onDeleteSharing = React.useCallback(
    (permission: api.t.PermissionFragment) => {
      setForm((data, draft) => {
        removeInPlaceIf(
          draft.permissions,
          (p) => p.target.id === permission.target.id
        );
      });

      setAutoSave(true);
    },
    [onSave]
  );

  const onPublish = React.useCallback(async () => {
    const form = changed ? await onSave() : data.form;
    await formsApi.publishForm(form);
  }, [data.form, changed]);

  const { groups, membershipTypes, form } = data;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={svLocale}>
      <Box width={1} mb={6}>
        <Box display="flex">
          <Box
            flex={1}
            display="flex"
            flexDirection="row"
            alignItems="flex-end"
            mb={2}
          >
            <Typography variant="h4">{s("Redigera formulär")}</Typography>
            {status === "saving" && (
              <Box flex={1} ml={2} style={{ paddingBottom: 1 }}>
                <Typography color="textSecondary" variant="subtitle1">
                  {s("Sparar...")}
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            pb={1}
            alignItems="flex-end"
            display="flex"
            justifyContent="flex-end"
          >
            <Box alignItems="center" display="flex">
              <Box
                flex={1}
                style={{ marginTop: 3 }}
                display="flex"
                flexDirection="row"
                alignItems="center"
                mr={1}
              >
                <Box flex={1}>
                  <Typography variant="body2">
                    {s("Kan redigeras av")}
                  </Typography>
                </Box>

                {data.form.permissions.map((p) => {
                  return (
                    <PermissionChip
                      key={p.target.id}
                      permission={p}
                      onDelete={onDeleteSharing}
                    />
                  );
                })}
              </Box>

              <Box mr={1}>
                <Button
                  id="sharing"
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={onPickSharing}
                >
                  {s("Dela")}
                </Button>
              </Box>

              <Divider orientation="vertical" flexItem />

              <Box ml={1}>
                {form.publishedAt ? (
                  <PublishedChip
                    variant="outlined"
                    // style={{ marginLeft: 5 }}
                    size="medium"
                    label={s("Publicerad {date}", {
                      date: dateFns.format(
                        new Date(form.publishedAt),
                        "yyyy-MM-dd"
                      ),
                    })}
                  />
                ) : (
                  <Button
                    id="sharing"
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={onPublish}
                  >
                    {s("Publicera")}
                  </Button>
                )}
              </Box>

              <IconButton id="menu-btn">
                <MenuIcon onClick={onMenu} />
              </IconButton>
            </Box>
          </Box>
        </Box>

        <Box mb={3} border={1} borderColor="secondary.main">
          <Paper>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box pl={1}>
                  <Typography variant="h6">
                    {s("Allmänna inställningar")}
                  </Typography>
                </Box>
              </AccordionSummary>
              <LockArea isLocked={form.publishedAt}>
                <Box px={3} pb={3} pt={2}>
                  <Grid container spacing={3}>
                    <Grid container item md={6}>
                      <TextInput
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        fullWidth
                        label={s("Rubrik")}
                        value={form.title}
                        placeholder={s("Ange en rubrik...")}
                        onChange={onChangeTitle}
                      />
                    </Grid>
                    <Grid container item sm={6} md={3}>
                      <KeyboardDatePicker
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputVariant="outlined"
                        label={s("Startdatum")}
                        disableToolbar
                        variant="inline"
                        format="yyyy-MM-dd"
                        value={form.startAt}
                        onChange={onChangeStart}
                        autoOk
                      />
                    </Grid>
                    <Grid container item sm={6} md={3}>
                      <KeyboardDatePicker
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputVariant="outlined"
                        label={s("Slutdatum")}
                        disableToolbar
                        variant="inline"
                        format="yyyy-MM-dd"
                        value={form.endAt}
                        onChange={onChangeEnd}
                        autoOk
                        minDate={form.startAt}
                      />
                    </Grid>
                    {/* </Grid>
              <Grid container> */}
                    <Grid container item md={12}>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={form.description}
                        variant="outlined"
                        fullWidth
                        label={s("Beskrivning")}
                        placeholder={s("Ange en beskrivning...")}
                        multiline
                        rows={3}
                        onChange={onChangeDescription}
                      />
                    </Grid>
                    <Grid container item md={12}>
                      <StartAfter
                        onChange={onSetStartAfter}
                        value={form.startAfter}
                        parentFormId={form.id}
                      />
                    </Grid>
                  </Grid>

                  <Box my={3}>
                    <Grid container spacing={10}>
                      <Grid container item md={6}>
                        <RoleListEx
                          kinds={[
                            api.t.GroupTypeKind.Course,
                            api.t.GroupTypeKind.Organizational,
                          ]}
                          toAddTitle={s("+ Lägg till målgrupp")}
                          selected={data.form.targets}
                          groups={groups}
                          membershipTypes={membershipTypes}
                          onAdd={onAddRole}
                          onDelete={onDeleteRole}
                          title={s("Målgrupp")}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </LockArea>
            </Accordion>
          </Paper>
        </Box>

        {form.components.map((c, i) => (
          <Box mt={i > 0 ? 3 : 0}>
            <FormComponent
              isLocked={form.publishedAt}
              key={getKey(c)}
              component={c}
              onUpdate={onUpdateComponent}
              onDelete={onDeleteComponent}
            />
          </Box>
        ))}

        {!form.publishedAt && (
          <Box flexDirection="row" display="flex">
            <Button
              variant="text"
              color="primary"
              size="medium"
              onClick={onAddFormComponent}
            >
              {s("+ Lägg till frågeavsnitt")}
            </Button>
            <Box flex={1} />
            <ButtonEx
              minWidth={100}
              disabled={!changed}
              mt={2}
              onSubmit={onSaveAndClose}
            >
              {s("Spara")}
            </ButtonEx>
          </Box>
        )}
      </Box>
    </MuiPickersUtilsProvider>
  );
};

export const EditForm = React.memo(() => {
  const { formId } = useParams<{ formId: string }>();
  const [data, setData] = React.useState<null | Data>(null);

  React.useEffect(() => {
    (async () => {
      const data = await formsApi.getFormById(formId);
      setData(data);
    })();
  }, [formId]);

  useEvent(
    formsApi.formUpdated,
    (event) => {
      if (event.form.id === formId) {
        setData((data) => {
          if (data === null) {
            return null;
          }
          return {
            ...data,
            form: event.form,
          };
        });
      }
    },
    [formId]
  );

  if (!data) {
    return null;
  }

  return <_EditForm data={data} />;
});
