import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import * as React from "react";
import { CSVLink } from "react-csv";
import { Data } from "react-csv/components/CommonPropTypes";
import { s } from "../../localization";
import { DialogProps } from "../dialog";
import { ButtonEx } from "../components/SubmitButton";

interface CsvTable {
  data: Data;
  title: string;
  subTitle: string;
  filename: string;
}

export interface ExportCsvDialogProps extends DialogProps<"cancel"> {
  tables: CsvTable[];
}

export const ExportCsvDialog = (props: ExportCsvDialogProps) => {
  const cancel = React.useCallback(() => {
    props.onClose("cancel");
  }, [props.onClose]);

  return (
    <Dialog
      open
      onClose={props.onClose}
      disableBackdropClick={!props.dismissable}
      disableEnforceFocus
    >
      <DialogTitle>{s("Exportera CSV")}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          {s("Här kan du ladda ned data i CSV format.")}
        </DialogContentText>

        <Box mt={4}>
          {props.tables.length > 0 ? (
            <List>
              {props.tables.map((table, index) => (
                <>
                  {index === 0 && <Divider />}

                  <ListItem>
                    <ListItemText
                      primary={table.title}
                      secondary={table.subTitle}
                    />
                    <Box width={100} />
                    <ListItemSecondaryAction>
                      <CSVLink data={table.data} filename={table.filename}>
                        <Typography variant="button">
                          {s("Ladda ned")}
                        </Typography>
                      </CSVLink>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </>
              ))}
            </List>
          ) : (
            <Alert severity="info">
              {s("Det finns ingen data att ladda ned.")}
            </Alert>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <ButtonEx onClick={cancel} variant="text" color="primary">
          {s("Stäng")}
        </ButtonEx>
      </DialogActions>
    </Dialog>
  );
};
