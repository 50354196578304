import _ from "lodash";
import { s } from "src/localization";
import {
  BulkImportDialog,
  BulkImportDialogProps,
} from "src/ui/dialogs/BulkImportDialog";
import * as dialog from "../ui/dialog";

interface ImportOptions<T> {
  objectLabel: string;
  requiredFields: string[];
  batchSize: number;
  onParse: (fields: { [key: string]: string }) => T;
  onBatchImport: (items: T[]) => Promise<number>;
}

export const extId = (id: string) => `ext:${id}`;

function got(strings: string[], text: string) {
  return strings.indexOf(text) >= 0;
}

function gotFields(headers: string[], fields: string[]) {
  for (var field of fields) {
    if (!got(headers, field)) {
      return s("Header '{name}' saknas.", { name: field });
    }
  }
  return null;
}

export async function importItems<T>(options: ImportOptions<T>) {
  return await dialog.show<"cancel", BulkImportDialogProps<T>>(
    BulkImportDialog,
    {
      requiredFields: options.requiredFields,
      objectLabel: options.objectLabel,
      onParse: async (data) => {
        const [headers, ...rows] = data;

        const error = gotFields(headers, options.requiredFields);
        if (error) {
          return { error };
        }

        const objects = rows.map((row) =>
          row.reduce(
            (o, v, i) => ({
              ...o,
              [headers[i]]: v,
            }),
            options.requiredFields.reduce(
              (o, f) => ({
                [f]: "",
              }),
              {}
            )
          )
        );

        const items: T[] = objects.map(options.onParse);
        return { items };
      },
      onImport: async (items, onProgress) => {
        let batches = _.chunk(items, options.batchSize);
        let delta = 100 / batches.length;
        let progress = 0;
        for (var batch of batches) {
          onProgress((progress += delta));
          await options.onBatchImport(batch);
        }
        window.location.reload();
      },
    }
  );
}
