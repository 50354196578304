import * as React from "react";
import cuid from "cuid";

export interface ContentHandle {
  content: any;
  id: string;
}

export class Portal {
  private output?: PortalOutput;

  setOutput(output: PortalOutput) {
    this.output = output;
  }

  push(content: any) {
    if (!this.output) {
      throw new Error("Unitialized output.");
    }
    const handle = { id: cuid(), content };
    this.output.push(handle);
    return handle;
  }

  hide(handle: ContentHandle) {
    if (!this.output) {
      throw new Error("Unitialized output.");
    }
    this.output.remove(handle);
  }
}

interface PortalOutputProps {
  portal: Portal;
}

export class PortalOutput extends React.Component<
  PortalOutputProps,
  { handles: ContentHandle[] }
> {
  constructor(props: PortalOutputProps) {
    super(props);
    this.state = {
      handles: [],
    };

    props.portal.setOutput(this);
  }

  push(handle: ContentHandle) {
    requestAnimationFrame(() =>
      this.setState((state) => ({ handles: [...state.handles, handle] }))
    );
  }

  remove(handle: ContentHandle) {
    this.setState((state) => ({
      handles: state.handles.filter((c) => c.id !== handle.id),
    }));
  }

  removeContent(content: any) {
    this.setState((state) => ({
      handles: state.handles.filter((c) => c.content !== content),
    }));
  }

  render() {
    return this.state.handles.map((handle) => (
      <React.Fragment key={handle.id}>{handle.content}</React.Fragment>
    ));
  }
}
