import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { s } from "src/localization";
import { ButtonEx } from "src/ui/components";
import { DialogProps } from "src/ui/dialog";
import * as api from "src/api";
import { Alert } from "@material-ui/lab";

export interface ConfirmUnlockProps extends DialogProps<"cancel" | "confirm"> {}

export const ConfirmUnlockDialog = (props: ConfirmUnlockProps) => {
  const cancel = React.useCallback(() => {
    props.onClose("cancel");
  }, [props.onClose]);

  const confirm = React.useCallback(() => {
    props.onClose("confirm");
  }, [props.onClose]);

  return (
    <Dialog
      open
      onClose={props.onClose}
      disableBackdropClick={!props.dismissable}
      disableEnforceFocus
    >
      <DialogTitle>{s("Bekräfta upplåsning")}</DialogTitle>
      <DialogContent dividers>
        <Box mb={3}>
          <DialogContentText>
            {s("Vill du låsa upp formuläret för redigering?")}
          </DialogContentText>
        </Box>
        <Box mb={2}>
          <Alert severity="warning">
            {s(
              "OBS: Ändringar i formulärets frågeavsnitt efter upplåsning kan orsaka svårtolkad svarsstatistik om mottagare redan hunnit skicka in svar."
            )}
          </Alert>
        </Box>

        <Box mb={1}>
          <Alert severity="warning">
            {s(
              "OBS: Att låsa upp ett formulär för redigering innebär att målgruppen inte längre kan lämna in svar för formuläret. Svar kan återigen skickas in när formuläret publicerats på nytt."
            )}
          </Alert>
        </Box>
      </DialogContent>
      <DialogActions>
        <ButtonEx onClick={cancel} variant="text" color="primary">
          {s("Avbryt")}
        </ButtonEx>
        <ButtonEx onClick={confirm}>{s("Ja, lås upp")}</ButtonEx>
      </DialogActions>
    </Dialog>
  );
};
