import * as React from "react";
import { Box } from "@material-ui/core";
import { GridColDef } from "@material-ui/data-grid";
import { s } from "../../../localization";
import { GridEx, PageHeader, PaperEx } from "../../components";
import * as usersApi from "../../../logic/user";
import * as api from "../../../api";
import { useKey } from "src/ui/hooks";

const columns: GridColDef[] = [
  { field: "id", hide: true },
  { field: "name", headerName: "Namn", type: "string", width: 200 },
  { field: "roles", headerName: "Roller", type: "string", flex: 1 },
];

interface Row {
  id: string;
  name: string;
  roles: string;
  user: api.t.UserFragment;
}

export const ListUsers = () => {
  const [users, setUsers] = React.useState<api.t.UserFragment[]>([]);
  const [groups, setGroups] = React.useState<api.t.GroupFragment[]>([]);
  const [membershipTypes, setMembershipTypes] = React.useState<
    api.t.MembershipTypeFragment[]
  >([]);

  const refresh = React.useCallback(async () => {
    const { users, groups, membershipTypes } = await usersApi.getUsers();
    setUsers(users);
    setGroups(groups);
    setMembershipTypes(membershipTypes);
  }, []);

  const onRowClick = React.useCallback(
    async (row: Row) => {
      const { user } = row;
      const result = await usersApi.editUser(user, groups, membershipTypes);

      if (result === "cancel" || result === "delete") {
        return;
      }
      refresh();
    },
    [users, groups, membershipTypes]
  );

  const isShiftDown = useKey("Shift");

  const onAdd = React.useCallback(async () => {
    if (isShiftDown) {
      usersApi.importUsers();
      return;
    }

    const result = await usersApi.addUser(groups, membershipTypes);
    if (result === "cancel") {
      return;
    }
    refresh();
  }, [groups, membershipTypes, isShiftDown]);

  React.useEffect(() => {
    refresh();
  }, []);

  const rows: Row[] = users.map((user) => ({
    id: user.id,
    name: `${user.firstName} ${user.lastName}`,
    roles: user.memberships
      .filter((m) => m.group.type.kind === api.t.GroupTypeKind.Organizational)
      .map((m) => `${m.group.name} (${m.type.name})`)
      .join(", "),
    user,
  }));

  return (
    <Box width={1}>
      <PageHeader
        title={s("Personer")}
        subTitle={s(
          "Nedan listas alla personer som du har rätt att administrera."
        )}
        onAdd={onAdd}
      ></PageHeader>
      <PaperEx mt={2}>
        <GridEx onRowClick={onRowClick} rows={rows} columns={columns} />
      </PaperEx>
    </Box>
  );
};
