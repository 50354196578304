import { Snackbar } from "@material-ui/core";
import * as React from "react";
import { Portal } from "./components/Portal";
import MuiAlert from "@material-ui/lab/Alert";

export const portal = new Portal();

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Notification = (props: {
  message: string;
  onClose: () => void;
  severity: "success" | "error";
}) => {
  const [open, setOpen] = React.useState(true);

  const onClose = React.useCallback(() => {
    setOpen(false);
    props.onClose();
  }, [props.onClose]);

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      message={props.message}
    >
      <Alert
        variant="standard"
        onClose={props.onClose}
        severity={props.severity}
      >
        {props.message}
      </Alert>
    </Snackbar>
  );
};

export function error(message: string) {
  const handle = portal.push(
    <Notification
      message={message}
      onClose={() => portal.hide(handle)}
      severity="error"
    />
  );
}

export function success(message: string) {
  const handle = portal.push(
    <Notification
      message={message}
      onClose={() => portal.hide(handle)}
      severity="success"
    />
  );
}
