import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Route, Switch } from "react-router";
import { Login } from "./account/Login";
import { ListUsers } from "./account/ListUsers";
import { ListGroups } from "./groups/ListGroups";
import { AccountRegistration } from "./account/Register";
import {
  withTopBar,
  withQuery,
  withSession,
  withSideNav,
} from "../components/enhancers";
import * as routes from "./routes";
import { Error404 } from "./Error404";
import { ListForms } from "./forms/ListForms";
import { Box, Container, Typography } from "@material-ui/core";
import { EditForm } from "./forms/EditForm";
import { Chart } from "./charts/Chart";
import { FillForm } from "./forms/FillForm";
import * as api from "../../api";
import * as groupsApi from "../../logic/groups";
import { s } from "../../localization";

export interface PageProps extends RouteComponentProps<any> {}

interface Page {
  route: routes.Route;
  component: React.ComponentType<PageProps>;
}

function withContainer<P>(
  Component: React.ComponentClass<P> | React.FunctionComponent<P>
) {
  return (props: P) => (
    <>
      <Container maxWidth="lg">
        <Component {...props} />
      </Container>
    </>
  );
}

const pages: Page[] = [
  {
    route: routes.home,
    component: withSession(withTopBar(withSideNav(ListForms))),
    // component: withSession(withNav(withQuery(Habits))),
  },
  {
    route: routes.editForm,
    component: withSession(withTopBar(withSideNav(EditForm))),
    // component: withSession(withNav(withQuery(Habits))),
  },
  {
    route: routes.fillForm,
    component: withSession(FillForm),
    // component: withSession(withNav(withQuery(Habits))),
  },
  {
    route: routes.chart,
    component: withSession(withTopBar(withSideNav(Chart))),
    // component: withSession(withNav(withQuery(Habits))),
  },
  {
    route: routes.groups,
    component: withSession(
      withTopBar(
        withSideNav(() => (
          <ListGroups
            canAdd={false}
            kind={api.t.GroupTypeKind.Organizational}
            name={s("Organisation")}
            onMenu={groupsApi.openMenu}
          />
        ))
      )
    ),
  },
  {
    route: routes.courses,
    component: withSession(
      withTopBar(
        withSideNav(() => (
          <ListGroups
            canAdd
            kind={api.t.GroupTypeKind.Course}
            name={s("Utbildningar")}
            onMenu={groupsApi.openMenu}
          />
        ))
      )
    ),
  },
  {
    route: routes.users,
    component: withSession(withTopBar(withSideNav(ListUsers))),
    // component: withSession(withNav(withQuery(Habits))),
  },
  {
    route: routes.login,
    component: withQuery(Login),
  },
  {
    route: routes.register,
    component: withQuery(AccountRegistration),
  },
];

export const PageRouter = () => (
  <>
    <Switch>
      {pages.map((page, index) => {
        const Component = page.component;
        return (
          <Route
            key={index}
            path={page.route.path}
            exact={page.route.exact}
            component={Component}
          />
        );
      })}
      <Route component={Error404} />
    </Switch>
    <Box mt={4}>
      <Typography variant="subtitle2" color="textSecondary" align="center">
        Powered by HEATSHAPE
        <br />© Heatshape 2021
      </Typography>
    </Box>
  </>
);
