import { Box } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import * as React from "react";

const Container = withStyles(() => ({
  root: {
    position: "relative",
  },
}))(Box);

const Overlay = withStyles(() => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255,255,255,0.6)",
    zIndex: 1,
  },
}))(Box);

export const LockArea = (
  props: React.PropsWithChildren<{ isLocked: boolean }>
) => {
  return (
    <Container>
      {props.children}
      {props.isLocked && <Overlay />}
    </Container>
  );
};
