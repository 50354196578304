import * as React from "react";
import * as _ from "lodash";
import * as api from "../../../../api";
import * as groupsApi from "../../../../logic/groups";
import { ButtonEx, SelectEx, TextFieldEx } from "../../../components";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from "@material-ui/core";
import produce from "immer";
import { s } from "../../../../localization";
import { DialogProps } from "../../../dialog";

export interface EditGroupProps
  extends DialogProps<api.t.GroupFragment | "delete"> {
  group: api.t.GroupFragment;
  groupTypes: api.t.GroupTypeFragment[];
  groups: api.t.GroupFragment[];
  canDelete: boolean;
}

function getTitle(props: EditGroupProps) {
  const { group } = props;
  const grupp = group.type.name.toLowerCase();
  if (group.id) {
    return s("Redigera {grupp}", { grupp });
  } else {
    return s("Lägg till {grupp}", { grupp });
  }
}

export const EditGroup = React.memo((props: EditGroupProps) => {
  const { groups } = props;

  const parentTypes = new Set(props.group.type.parents.map((t) => t.id));
  const parentGroups = groups.filter(
    (g) => parentTypes.has(g.type.id) && g.id !== props.group.id
  );

  const [group, setGroup] = React.useState<api.t.GroupFragment>(() => {
    if (parentGroups.length === 1) {
      return {
        ...props.group,
        parent: parentGroups[0],
      };
    } else {
      return props.group;
    }
  });

  const changed = !_.isEqual(props.group, group);
  const canSave = changed && group.name && group.parent;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open
      onClose={props.onClose}
      disableBackdropClick={!props.dismissable}
    >
      <DialogTitle>{getTitle(props)}</DialogTitle>
      <DialogContent dividers>
        {/* <DialogContentText>{s("Redigera namn eller typ.")}</DialogContentText> */}

        <Box display="flex" flexDirection="row" mt={2} mb={2}>
          <TextFieldEx
            flex={1}
            label={s("Namn")}
            value={group.name}
            onChangeText={(name) => {
              setGroup(
                produce(group, (draft) => {
                  draft.name = name;
                })
              );
            }}
          />
          {/* {groupTypes.length > 1 && (
            <SelectEx
              label={s("Typ")}
              flex={0.3}
              ml={2}
              value={group.type.id}
              onChange={(e) => {
                setGroup(
                  produce(group, (draft) => {
                    draft.type = groupTypes.filter(
                      (x) => x.id === e.target.value
                    )[0];
                  })
                );
              }}
            >
              {groupTypes.map((t) => (
                <MenuItem key={t.id} value={t.id}>
                  {t.name}
                </MenuItem>
              ))}
            </SelectEx>
          )} */}
        </Box>

        {group.type.parents.length > 0 && (
          <SelectEx
            label={group.type.parents.map((t) => t.name).join(", ")}
            value={group.parent ? group.parent.id : undefined}
            onChange={(e) => {
              setGroup(
                produce(group, (draft) => {
                  draft.parent = groups.filter(
                    (x) => x.id === e.target.value
                  )[0];
                })
              );
            }}
          >
            {/* <MenuItem value={undefined}>
              <em>{s("Inget")}</em>
            </MenuItem> */}
            {groups
              .filter((x) => parentTypes.has(x.type.id))
              .filter((x) => x.id !== group.id)
              // .filter((x) =>
              //   groupsApi.hasPermissions(x, [
              //     api.t.GroupPermission.Update,
              //     api.t.GroupPermission.Delete,
              //   ])
              // )
              .map((t) => (
                <MenuItem key={t.id} value={t.id}>
                  {t.name}
                </MenuItem>
              ))}
          </SelectEx>
        )}
      </DialogContent>
      <DialogActions>
        {props.canDelete && (
          <ButtonEx onClick={() => props.onClose("delete")} variant="text">
            {s("Ta bort")}
          </ButtonEx>
        )}
        <Box flex={1} />
        <ButtonEx onClick={() => props.onClose("cancel")} variant="text">
          {s("Avbryt")}
        </ButtonEx>
        <ButtonEx
          disabled={!canSave}
          onSubmit={async () => {
            props.onClose(group);
          }}
        >
          {s("Spara")}
        </ButtonEx>
      </DialogActions>
    </Dialog>
  );
});
