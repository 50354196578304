import * as React from "react";
import * as data from "../utils/data";

export function render<P>(
  Component: React.ComponentClass<P> | React.ComponentType<P>,
  props: P
) {
  return <Component {...props} />;
}

export function connectDataState<T, P, R>(
  state: data.State<T>,
  Component: React.ComponentClass<P> | React.FunctionComponent<P>,
  resolver: (d: T) => R
) {
  return (props: Omit<P, keyof R>) => {
    const [d, setData] = React.useState(state.data);

    React.useEffect(() => {
      state.listeners.push(setData);
      return () => {
        state.listeners = state.listeners.filter((x) => x !== setData);
      };
    }, []);

    const innerProps = ({
      ...props,
      ...resolver(d),
    } as any) as P;

    return <Component {...innerProps} />;
  };
}
