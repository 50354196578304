import {
  Box,
  BoxProps,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
} from "@material-ui/core";
import {
  DataGrid,
  GridColumns,
  GridToolbarContainer,
} from "@material-ui/data-grid";
import * as React from "react";
import { s } from "../../localization";
import { ButtonEx } from "./SubmitButton";
import { TextFieldEx } from "./TextFieldEx";
import SearchIcon from "@material-ui/icons/Search";

export interface ListExProps<T> {
  rows: T[];
  columns: GridColumns;
  onRowClick: (row: T) => void;
  onAdd?: () => void;
  checkboxSelection?: boolean;
}

const useStyles = makeStyles({
  root: {
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
      outline: "none",
    },
    "&.MuiDataGrid-root .MuiDataGrid-colCellTitle": {
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    cursor: "pointer",
  },
});

interface ToolbarProps {
  onAdd?: () => void;
  // onSearch?: ()
}

const Toolbar = (props: ToolbarProps) => {
  // const [search, setSearch] = React.useState("");
  return (
    <GridToolbarContainer>
      {/* <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      /> */}
      {props.onAdd && (
        <ButtonEx
          size="small"
          color="primary"
          variant="text"
          onClick={props.onAdd}
        >
          {s("+ Lägg till")}
        </ButtonEx>
      )}
    </GridToolbarContainer>
  );
};

export const GridEx = <T,>(props: ListExProps<T> & BoxProps) => {
  const {
    onRowClick,
    onAdd,
    rows,
    columns,
    checkboxSelection,
    ...boxProps
  } = props;
  const classes = useStyles();

  return (
    <Box {...boxProps}>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          components={{
            Toolbar,
          }}
          componentsProps={{ toolbar: { onAdd } }}
          rows={rows}
          columns={columns}
          pageSize={5}
          checkboxSelection={checkboxSelection}
          disableSelectionOnClick
          onRowClick={(e) => onRowClick(e.row as T)}
          className={classes.root}
        />
      </div>
    </Box>
  );
};
