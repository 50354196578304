import {
  Box,
  BoxProps,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";
import * as React from "react";
import { createDelay } from "../../utils/async";
import { TextFieldEx } from "./TextFieldEx";
import * as _ from "lodash";

export interface ListExProps<T> {
  onGetItems: (text: string) => Promise<T[]>;
  getLabel: (item: T) => string;
  getGroup?: (item: T) => string;
  onCancel: () => void;
  onClick: (item: T) => void;
  filter?: boolean;
}

interface Identity {
  id: string;
}

export const ListEx = <T extends Identity>(
  props: ListExProps<T> & Omit<BoxProps, "onClick">
) => {
  const {
    filter,
    getGroup,
    getLabel,
    onCancel,
    onClick,
    onGetItems,
    ...boxProps
  } = props;
  const [text, setText] = React.useState("");
  const [searching, setSearching] = React.useState(false);
  const [listItems, setListItems] = React.useState<React.ReactNode[]>([]);

  const takeLatest = createDelay(300);

  const createItems = React.useCallback(
    (items: T[]) => {
      return items
        .filter(
          (item) =>
            !text ||
            getLabel(item)
              .toUpperCase()
              .indexOf(text.toUpperCase()) >= 0
        )
        .map((item) => (
          <ListItem
            key={item.id}
            button
            dense
            onClick={() => {
              onClick(item);
            }}
          >
            <ListItemText primary={getLabel(item)} />
          </ListItem>
        ));
    },
    [onClick, getLabel, text]
  );

  React.useEffect(() => {
    takeLatest(async () => {
      try {
        setSearching(true);
        const items = await onGetItems(text);

        console.log(">>>>>>>>>>>", items);

        if (getGroup) {
          const groups = _.groupBy(items, getGroup);
          setListItems(
            _.flatten(
              Object.keys(groups).map((group) => {
                return (
                  <li key={group}>
                    <ul>
                      {[
                        <ListSubheader>{group}</ListSubheader>,
                        ...createItems(groups[group]),
                      ]}
                    </ul>
                  </li>
                );
              })
            )
          );
        } else {
          setListItems(createItems(items));
        }
      } finally {
        setSearching(false);
      }
    })();
  }, [text, onGetItems]);

  return (
    <Box {...boxProps} display="flex" flexDirection="column">
      {filter && (
        <TextFieldEx
          onExit={onCancel}
          onChangeText={setText}
          size="small"
          autoFocus
        />
      )}

      <Box flex={1} style={{ overflow: "auto" }}>
        <List>{listItems}</List>
      </Box>
    </Box>
  );
};
