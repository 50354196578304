import * as React from "react";
import * as api from "../../../../api";
import * as menu from "../../../menu";
import { Box, Typography } from "@material-ui/core";
import { s } from "../../../../localization";
import SwipeableViews from "react-swipeable-views";
import { ListEx } from "../../../components/ListEx";
import * as _ from "lodash";
import * as groupsApi from "../../../../logic/groups";
import * as userApi from "../../../../logic/user";

export interface PickSharingProps {
  groups: api.t.GroupFragment[];
  membershipTypes: api.t.MembershipTypeFragment[];
  selected: api.t.PermissionFragment[];
}

type Item = api.t.GroupFragment | api.t.UserFragment;

export const PickSharing = React.memo(
  (
    props: PickSharingProps &
      menu.DropProps<api.t.UserFragment | api.t.MembershipFragment>
  ) => {
    const { groups, selected, membershipTypes, onClose, ...blockProps } = props;

    const [index, setIndex] = React.useState(0);
    const [group, setGroup] = React.useState<api.t.GroupFragment | null>(null);

    const selectedMemberships = React.useMemo(() => {
      const result: api.t.MembershipFragment[] = selected
        .filter((p) => p.target.__typename === "Membership")
        .map((p) => {
          if (p.target.__typename !== "Membership") {
            throw new Error("Unexpected");
          }
          return p.target;
        });
      return result;
    }, [selected]);

    const typesByGroup = React.useMemo(
      () =>
        groupsApi.getTypesByGroup(groups, membershipTypes, selectedMemberships),
      [groups, membershipTypes, selected]
    );

    const activeGroups = React.useMemo(
      () => groups.filter((g) => typesByGroup.get(g.id)!.length > 0),
      [typesByGroup]
    );

    const onCancel = React.useCallback(() => {
      onClose("cancel");
    }, [onClose]);

    const onSelectItem = React.useCallback(
      (item: Item) => {
        if (item.__typename === "Group") {
          setIndex(index + 1);
          setGroup(item);
        } else if (item.__typename === "User") {
          onClose(item);
        }
      },
      [onClose]
    );

    const onSelectGroupType = React.useCallback(
      (type) => {
        if (group?.__typename !== "Group") {
          throw new Error("Unexpected typename");
        }
        onClose({
          __typename: "Membership",
          id: "",
          type,
          group,
        });
      },
      [onClose, group]
    );

    const onGetItems = React.useCallback(
      async (text: string) => {
        const { users } = await userApi.getUsers(text);
        return [...activeGroups, ...users];
      },
      [activeGroups]
    );
    const onGetTypes = React.useCallback(
      async () => (group ? typesByGroup.get(group.id)! : []),
      [group, typesByGroup]
    );

    const onGetGroup = React.useCallback((item: Item) => {
      if (item.__typename === "User") {
        return s("Personer");
      } else if (item.__typename === "Group") {
        return s("Grupper");
      }
      return "";
    }, []);

    const onGetLabel = React.useCallback((item: Item) => {
      if (item.__typename === "User") {
        return [item.firstName, item.lastName].join(" ");
      } else if (item.__typename === "Group") {
        return item.name;
      }
      return "";
    }, []);

    return (
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        style={{ minHeight: 200 }}
      >
        <Box>
          <Typography display="inline" variant="h6">
            {s("Dela med personer och grupper")}
          </Typography>
        </Box>

        <SwipeableViews index={index}>
          <ListEx
            onGetItems={onGetItems}
            getGroup={onGetGroup}
            getLabel={onGetLabel}
            onCancel={onCancel}
            filter
            onClick={onSelectItem}
            height={180}
          />
          <ListEx
            onGetItems={onGetTypes}
            getLabel={(item) => item.name}
            onCancel={onCancel}
            onClick={onSelectGroupType}
            height={180}
          />
        </SwipeableViews>
      </Box>
    );
  }
);
