import {
  Box,
  createStyles,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { s } from "../localization";
import * as userApi from "../logic/user";
import UsersIcon from "@material-ui/icons/Group";
import GroupIcon from "@material-ui/icons/AccountTree";
import CourseIcon from "@material-ui/icons/MenuBook";
import ListAltIcon from "@material-ui/icons/ListAlt";
import * as routes from "./pages/routes";
import { Link, useLocation } from "react-router-dom";

export interface SideNavProps {
  session: userApi.Session;
}

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
  })
);

type IconType = typeof ListAltIcon;

interface MenuItem {
  route: routes.Route;
  Icon: IconType;
}

const menuItems: { [group: string]: { [label: string]: MenuItem } } = {
  [s("Formulär")]: {
    // [s("Mina formulär")]: {
    //   route: routes.myForms,
    //   Icon: ListAltIcon,
    // },
    [s("Frågeformulär")]: {
      route: routes.home,
      Icon: ListAltIcon,
    },
  },
  [s("Administration")]: {
    // [s("Ditt konto")]: {
    //   route: routes.login,
    //   Icon: AccountIcon,
    // },
    [s("Personer")]: {
      route: routes.users,
      Icon: UsersIcon,
    },
    [s("Organisation")]: {
      route: routes.groups,
      Icon: GroupIcon,
    },
    [s("Utbildningar")]: {
      route: routes.courses,
      Icon: CourseIcon,
    },
  },
};

export const SideNav = (props: SideNavProps) => {
  const location = useLocation();

  return (
    <Box>
      <List>
        {Object.keys(menuItems).map((header) => {
          return (
            <>
              <ListSubheader disableSticky>
                {header.toUpperCase()}
              </ListSubheader>
              {Object.keys(menuItems[header]).map((label, index) => {
                const item = menuItems[header][label];

                return (
                  <ListItem
                    button
                    key={label}
                    selected={item.route.path === location.pathname}
                    {...({ component: Link } as any)}
                    to={item.route.path}
                  >
                    <ListItemIcon>
                      <item.Icon />
                    </ListItemIcon>
                    <ListItemText primary={label} />
                  </ListItem>
                );
              })}
            </>
          );
        })}
      </List>
    </Box>
  );
};

// export const SideNav = (props: SideNavProps) => {
//   const classes = useStyles();
//   return (
//     <Drawer
//       className={classes.drawer}
//       variant="permanent"
//       classes={{
//         paper: classes.drawerPaper,
//       }}
//       anchor="left"
//     >
//       <div className={classes.toolbar} />
//       <Divider />
//       <List>
//         {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
//           <ListItem button key={text}>
//             <ListItemIcon>
//               {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
//             </ListItemIcon>
//             <ListItemText primary={text} />
//           </ListItem>
//         ))}
//       </List>
//       <Divider />
//       <List>
//         {["All mail", "Trash", "Spam"].map((text, index) => (
//           <ListItem button key={text}>
//             <ListItemIcon>
//               {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
//             </ListItemIcon>
//             <ListItemText primary={text} />
//           </ListItem>
//         ))}
//       </List>
//     </Drawer>
//   );
// };
