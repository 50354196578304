import * as React from "react";
import * as _ from "lodash";
import { s } from "../localization";

export function removeInPlaceIf<T>(list: T[], predicate: (item: T) => boolean) {
  for (let i = 0; i < list.length; i++) {
    if (predicate(list[i])) {
      list.splice(i--, 1);
    }
  }
  return list;
}

export function removeInPlace<T>(index: number, list: T[]) {
  list.splice(index, 1);
  return list;
}

export function toggle<T>(item: T, list: T[]) {
  const index = list.indexOf(item);

  if (index >= 0) {
    removeInPlace(index, list);
  } else {
    list.push(item);
  }
  return list;
}

export function getRecurringLabels(count: number) {
  const labels = [
    s("Varje"),
    s("Varannan"),
    s("Var tredje"),
    s("Var fjärde"),
    s("Var femte"),
  ];

  return _.range(1, count + 1).map((i) => {
    const n = i - 1;
    if (n < labels.length - 1) {
      return labels[n];
    }
    return s("Var {number}:e", { number: i });
  });
}

interface Diff<T> {
  toInsert: [];
  toUpdate: [];
  toDelete: [];
}

export function getDiff<T extends { id: string }>(original: T[], updated: T[]) {
  const originalMap = new Map(original.map((x) => [x.id, x]));
  const updatedMap = new Map(updated.map((x) => [x.id, x]));
  const toDelete = original.filter((x) => !updatedMap.has(x.id));
  const toUpdate = updated.filter((x) => {
    if (!originalMap.has(x.id)) {
      return false;
    }
    return !_.isEqual(originalMap.get(x.id), x);
  });
  const toInsert = updated.filter((x) => x.id === "");

  return {
    toInsert,
    toUpdate,
    toDelete,
  };
}

export type DataStateListener<T> = (data: T) => void;

export interface State<T> {
  data: T;
  listeners: DataStateListener<T>[];
}

export function updateState<T>(state: State<T>, data: T) {
  state.data = data;
  state.listeners.forEach((l) => l(data));
}
