import { withGraphQL } from "../utils/fetch-gql";
import { defaultFetch, CancelFetch, withJwt } from "../utils/fetch";
import config from "../config";
import * as t from "../__gql__/graphql";
import * as fragments from "./fragments";
export { fragments, t };

function createFetch() {
  return withGraphQL(defaultFetch, async () => config.api.uri);
}

let gqlFetch = createFetch();

export function setJwt(token: string) {
  gqlFetch = withGraphQL(
    withJwt(defaultFetch, async () => token),
    async () => config.api.uri
  );
}

export function clearJwt() {
  gqlFetch = createFetch();
}

interface GraphQLRequest<V extends object> {
  query?: any;
  variables?: V;
  operationName?: string;
}

export async function fetch<R, V extends object>(
  request: GraphQLRequest<V>,
  cancel?: CancelFetch
) {
  const { data } = await gqlFetch(request, cancel);
  return data as R;
}
