import * as React from "react";
import { Link as RouterLink, LinkProps } from "react-router-dom";

const Link = (props: LinkProps) => <RouterLink {...(props as never)} />;

export class Error404 extends React.Component {
  render() {
    return (
      <div className="page-wrap d-flex flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center">
              <span className="display-1 d-block">404</span>
              <div className="mb-4 lead">
                The page you are looking for was not found.
              </div>
              <Link to="/">Back to home</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
