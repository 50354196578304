import * as menu from "./menu";
import * as api from "../api";
import {
  PickSharing,
  PickSharingProps,
} from "./pages/forms/dialogs/PickSharing";

export function pickSharing(
  props: PickSharingProps,
  options: menu.DropOptions
) {
  return menu.showDrop<
    api.t.UserFragment | api.t.MembershipFragment,
    PickSharingProps &
      menu.DropProps<api.t.UserFragment | api.t.MembershipFragment>
  >(PickSharing, props, {
    ...options,
    width: 350,
  });
}
