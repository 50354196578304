import * as React from "react";
import * as _ from "lodash";
import * as api from "../../../../api";
import { ButtonEx, Role, TextFieldEx } from "../../../components";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import produce from "immer";
import { s } from "../../../../localization";
import { DialogProps } from "../../../dialog";
import { removeInPlace } from "../../../../utils/data";
import { RoleListEx } from "../../../components/RoleListEx";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface EditUserProps
  extends DialogProps<api.t.UserFragment | "delete"> {
  user: api.t.UserFragment;
  groups: api.t.GroupFragment[];
  membershipTypes: api.t.MembershipTypeFragment[];
}

export const EditUser = React.memo((props: EditUserProps) => {
  const { groups, membershipTypes } = props;
  const [user, setUser] = React.useState(props.user);
  const changed = !_.isEqual(props.user, user);

  const onAddMembership = React.useCallback((role: Role) => {
    setUser((user) =>
      produce(user, (draft) => {
        draft.memberships.push({
          __typename: "Membership",
          id: "",
          ...role,
        });
      })
    );
  }, []);

  const onDeleteMembership = React.useCallback(
    (membership: api.t.MembershipFragment) => {
      setUser((user) =>
        produce(user, (draft) => {
          removeInPlace(
            draft.memberships.findIndex(
              (m) =>
                m.group.id === membership.group.id &&
                m.type.id === membership.type.id
            ),
            draft.memberships
          );
        })
      );
    },
    []
  );

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open
      onClose={props.onClose}
      disableBackdropClick={!props.dismissable}
      disableEnforceFocus
    >
      <DialogTitle>
        {user.id ? s("Redigera person") : s("Registrera person")}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{s("Personens uppgifter.")}</DialogContentText>

        <Box display="flex" flexDirection="row" mt={2} mb={4}>
          <TextFieldEx
            flex={1}
            label={s("Förnamn")}
            value={user.firstName}
            onChangeText={(name) => {
              setUser(
                produce(user, (draft) => {
                  draft.firstName = name;
                })
              );
            }}
          />
          <TextFieldEx
            ml={2}
            flex={1}
            label={s("Efternamn")}
            value={user.lastName}
            onChangeText={(name) => {
              setUser(
                produce(user, (draft) => {
                  draft.lastName = name;
                })
              );
            }}
          />
        </Box>

        <TextFieldEx
          flex={1}
          label={s("E-postadress")}
          value={user.email}
          onChangeText={(value) => {
            setUser(
              produce(user, (draft) => {
                draft.email = value;
              })
            );
          }}
        />

        <Box mb={4} mt={4}>
          <RoleListEx
            toAddTitle={s("+ Lägg till roll")}
            kinds={[api.t.GroupTypeKind.Organizational]}
            selected={user.memberships}
            groups={groups}
            membershipTypes={membershipTypes}
            onAdd={onAddMembership}
            onDelete={onDeleteMembership}
            title={s("Roller")}
          />
        </Box>

        <Box mb={4}>
          <RoleListEx
            toAddTitle={s("+ Lägg till utbildning")}
            kinds={[api.t.GroupTypeKind.Course]}
            selected={user.memberships}
            groups={groups}
            membershipTypes={membershipTypes}
            onAdd={onAddMembership}
            onDelete={onDeleteMembership}
            title={s("Utbildningar")}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {/* <ButtonEx onClick={() => props.onClose("delete")} color="default">
          {s("Ta bort")}
        </ButtonEx> */}
        <Box flex={1} />
        <ButtonEx onClick={() => props.onClose("cancel")} variant="text">
          {s("Avbryt")}
        </ButtonEx>
        <ButtonEx
          disabled={!changed}
          onSubmit={async () => {
            props.onClose(user);
          }}
        >
          {s("Spara")}
        </ButtonEx>
      </DialogActions>
    </Dialog>
  );
});
