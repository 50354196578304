import {
  Box,
  Button,
  Checkbox,
  Chip,
  IconButton,
  Radio,
  TextField,
  TextFieldProps,
} from "@material-ui/core";
import * as _ from "lodash";
import produce from "immer";
import * as React from "react";
import * as api from "src/api";
import * as formsApi from "src/logic/forms";
import { getKey } from "src/logic/types";
import { removeInPlace, removeInPlaceIf } from "src/utils/data";
import UpIcon from "@material-ui/icons/ArrowUpward";
import DownIcon from "@material-ui/icons/ArrowDownward";
import DeleteIcon from "@material-ui/icons/Delete";
import { s } from "src/localization";
import { FormQuestionProps } from "./types";

const TextInput = (props: TextFieldProps & { onEnter?: () => void }) => (
  <TextField
    {...props}
    onKeyDown={(e) => {
      if (e.keyCode === 13 || e.keyCode === 27) {
        const htmlElement = document.activeElement as HTMLElement;
        if (htmlElement && htmlElement.blur) {
          htmlElement.blur();
        }
      }

      if (props.onEnter && e.keyCode === 13) {
        props.onEnter();
      }
    }}
  />
);

export const FormQuestionText = React.memo(
  <T extends api.t.FormQuestionTextFragment>(props: FormQuestionProps<T>) => {
    const {
      question,
      onUpdate,
      onDelete,
      onOrderDown,
      onOrderUp,
      label,
    } = props;

    const { current: inputLabelProps } = React.useRef({
      shrink: true,
    });

    const onChange = React.useCallback(
      (e: any) => {
        onUpdate({ question: e.target.value } as Partial<T>);
      },
      [onUpdate]
    );

    return (
      <Box mb={3}>
        <Box display="flex" flexDirection="row" mt={1}>
          <Box pt={1}>
            <Chip
              size="small"
              label={label}
              style={{ width: 25, fontWeight: "bold" }}
            />
          </Box>
          <Box ml={1} flex={1}>
            <TextInput
              autoFocus={!question.id}
              InputLabelProps={inputLabelProps}
              multiline
              rows={3}
              fullWidth
              variant="outlined"
              placeholder={s("Ange en fråga...")}
              value={question.question}
              onChange={onChange}
            />
          </Box>
          <Box ml={1}>
            <IconButton size="small" disabled={!onOrderUp} onClick={onOrderUp}>
              <UpIcon />
            </IconButton>
            <IconButton
              size="small"
              disabled={!onOrderDown}
              onClick={onOrderDown}
            >
              <DownIcon />
            </IconButton>
            <IconButton size="small" onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    );
  }
);
