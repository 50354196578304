import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};


export type Answer = {
  id: Scalars['ID'];
};

export type AnswerInput = {
  questionId: Scalars['ID'];
  singleChoice?: Maybe<Scalars['ID']>;
  multiChoice?: Maybe<Array<Scalars['ID']>>;
  text?: Maybe<Scalars['String']>;
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}


export type DeleteFormInput = {
  formId: Scalars['ID'];
  archive: Scalars['Boolean'];
};

export type DeleteGroupInput = {
  groupId: Scalars['ID'];
};

export type Form = {
  __typename?: 'Form';
  id: Scalars['ID'];
  createdBy: User;
  createdAt: Scalars['DateTime'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  startAt: Scalars['DateTime'];
  endAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  locked: Scalars['Boolean'];
  components: Array<FormComponent>;
  targets: Array<FormTarget>;
  startAfter?: Maybe<StartAfter>;
  status: FormStatus;
  permissions: Array<Permission>;
};

export type FormAnswers = {
  __typename?: 'FormAnswers';
  id: Scalars['ID'];
  form: Form;
  answers: Array<Answer>;
};

export type FormAnswersInput = {
  formId: Scalars['ID'];
  answers: Array<AnswerInput>;
};

export type FormComponent = {
  __typename?: 'FormComponent';
  id: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  questions: Array<FormQuestion>;
};

export type FormComponentInput = {
  id: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  questions: Array<FormQuestionInput>;
};

export type FormQuestion = {
  id: Scalars['ID'];
  question: Scalars['String'];
};

export type FormQuestionInput = {
  id: Scalars['ID'];
  type: FormQuestionType;
  question: Scalars['String'];
  options: Array<FormQuestionOptionsInput>;
};

export type FormQuestionMultiChoice = FormQuestion & {
  __typename?: 'FormQuestionMultiChoice';
  id: Scalars['ID'];
  question: Scalars['String'];
  options: Array<FormQuestionOption>;
};

export type FormQuestionOption = {
  __typename?: 'FormQuestionOption';
  id: Scalars['ID'];
  label: Scalars['String'];
};

export type FormQuestionOptionsInput = {
  id: Scalars['ID'];
  label: Scalars['String'];
};

export type FormQuestionSingleChoice = FormQuestion & {
  __typename?: 'FormQuestionSingleChoice';
  id: Scalars['ID'];
  question: Scalars['String'];
  options: Array<FormQuestionOption>;
};

export type FormQuestionText = FormQuestion & {
  __typename?: 'FormQuestionText';
  id: Scalars['ID'];
  question: Scalars['String'];
};

export enum FormQuestionType {
  FormQuestionSingleChoice = 'FormQuestionSingleChoice',
  FormQuestionMultiChoice = 'FormQuestionMultiChoice',
  FormQuestionText = 'FormQuestionText'
}

export type FormReminder = {
  __typename?: 'FormReminder';
  id: Scalars['ID'];
};

export type FormStatus = {
  __typename?: 'FormStatus';
  pending: Array<User>;
  pendingCount: Scalars['Int'];
  completed: Array<User>;
  completedCount: Scalars['Int'];
};

export type FormSummary = {
  __typename?: 'FormSummary';
  form: Form;
  questions: Array<QuestionSummary>;
};

export type FormTarget = {
  id: Scalars['ID'];
};

export type Group = {
  __typename?: 'Group';
  id: Scalars['ID'];
  externalId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  type: GroupType;
  parent?: Maybe<Group>;
  children: Array<Group>;
  permissions: Array<GroupPermission>;
  memberships: Array<Membership>;
  primary: Scalars['Boolean'];
};

export type GroupFormTarget = FormTarget & {
  __typename?: 'GroupFormTarget';
  id: Scalars['ID'];
  group: Group;
  type: MembershipType;
  includeChildGroups: Scalars['Boolean'];
};

export type GroupFormTargetInput = {
  id: Scalars['ID'];
  groupId: Scalars['ID'];
  typeId: Scalars['ID'];
  includeChildGroups: Scalars['Boolean'];
};

export enum GroupPermission {
  Update = 'Update',
  Delete = 'Delete'
}

export type GroupType = {
  __typename?: 'GroupType';
  id: Scalars['ID'];
  name: Scalars['String'];
  parents: Array<GroupType>;
  kind: GroupTypeKind;
  primary: Scalars['Boolean'];
};

export enum GroupTypeKind {
  Course = 'Course',
  Organizational = 'Organizational'
}

export type LoginInput = {
  email: Scalars['String'];
  ticket: Scalars['String'];
};

export type LoginResult = {
  __typename?: 'LoginResult';
  token?: Maybe<Scalars['String']>;
};

export type Membership = {
  __typename?: 'Membership';
  id: Scalars['ID'];
  group: Group;
  user: User;
  type: MembershipType;
};

export type MembershipInput = {
  id: Scalars['ID'];
  groupId: Scalars['ID'];
  typeId: Scalars['ID'];
};

export type MembershipType = {
  __typename?: 'MembershipType';
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions: Array<GroupPermission>;
  groupTypes: Array<GroupType>;
};

export type MultiChoiceAnswer = Answer & {
  __typename?: 'MultiChoiceAnswer';
  id: Scalars['ID'];
  question: FormQuestionMultiChoice;
  values: Array<Scalars['ID']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']>;
  registerUser: User;
  login: LoginResult;
  requestAuthTicket: Scalars['Boolean'];
  upsertForm: Form;
  upsertGroup: Group;
  deleteGroup: Group;
  upsertUser: User;
  deleteForm: Form;
  restoreForm: Form;
  publishForm: Form;
  unlockForm: Form;
  registerAnswers: FormAnswers;
  sendReminders: Scalars['Boolean'];
};


export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationRequestAuthTicketArgs = {
  input: RequestAuthTicketInput;
};


export type MutationUpsertFormArgs = {
  input: UpsertFormInput;
};


export type MutationUpsertGroupArgs = {
  input: UpsertGroupInput;
};


export type MutationDeleteGroupArgs = {
  input: DeleteGroupInput;
};


export type MutationUpsertUserArgs = {
  input: UpsertUserInput;
};


export type MutationDeleteFormArgs = {
  input: DeleteFormInput;
};


export type MutationRestoreFormArgs = {
  input: RestoreFormInput;
};


export type MutationPublishFormArgs = {
  input: PublishFormInput;
};


export type MutationUnlockFormArgs = {
  input: UnlockFormInput;
};


export type MutationRegisterAnswersArgs = {
  input: FormAnswersInput;
};


export type MutationSendRemindersArgs = {
  input: SendRemindersInput;
};

export type OptionCount = {
  __typename?: 'OptionCount';
  option: FormQuestionOption;
  count: Scalars['Int'];
};

export type Permission = {
  __typename?: 'Permission';
  type: PermissionType;
  role: PermissionRole;
  target: PermissionTarget;
};

export type PermissionInput = {
  type: PermissionType;
  role: PermissionRole;
  targetMembership?: Maybe<MembershipInput>;
  targetUserId?: Maybe<Scalars['ID']>;
};

export enum PermissionRole {
  Owner = 'Owner',
  Writer = 'Writer'
}

export type PermissionTarget = Membership | User;

export enum PermissionType {
  Group = 'Group',
  User = 'User'
}

export type PublishFormInput = {
  formId: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']>;
  me?: Maybe<User>;
  getForms: Array<Form>;
  getFormById: Form;
  getGroups: Array<Group>;
  getGroupTypes: Array<GroupType>;
  getGroupById: Group;
  getUser: User;
  getUsers: Array<User>;
  getMembershipTypes: Array<MembershipType>;
  getFormSummary: FormSummary;
};


export type QueryGetFormByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetGroupsArgs = {
  includeDescendants?: Maybe<Scalars['Boolean']>;
};


export type QueryGetGroupTypesArgs = {
  includeDescendants?: Maybe<Scalars['Boolean']>;
};


export type QueryGetGroupByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetUserArgs = {
  id: Scalars['ID'];
};


export type QueryGetUsersArgs = {
  search?: Maybe<Scalars['String']>;
};


export type QueryGetFormSummaryArgs = {
  formId: Scalars['ID'];
};

export type QuestionSummary = {
  __typename?: 'QuestionSummary';
  question: FormQuestion;
  options?: Maybe<Array<OptionCount>>;
  textAnswers?: Maybe<Array<Scalars['String']>>;
};

export type RegisterUserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
};

export type RequestAuthTicketInput = {
  email: Scalars['String'];
};

export type RestoreFormInput = {
  formId: Scalars['ID'];
};

export type SendRemindersInput = {
  formId: Scalars['ID'];
};

export type SingleChoiceAnswer = Answer & {
  __typename?: 'SingleChoiceAnswer';
  id: Scalars['ID'];
  question: FormQuestionSingleChoice;
  value: Scalars['ID'];
};

export type StartAfter = {
  __typename?: 'StartAfter';
  form: Form;
  afterDays: Scalars['Int'];
};

export type StartAfterInput = {
  formId: Scalars['ID'];
  afterDays: Scalars['Int'];
};

export type TextAnswer = Answer & {
  __typename?: 'TextAnswer';
  id: Scalars['ID'];
  question: FormQuestionText;
  value: Scalars['String'];
};

export type UnlockFormInput = {
  formId: Scalars['ID'];
};


export type UpsertFormInput = {
  id: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  endAt?: Maybe<Scalars['DateTime']>;
  components: Array<FormComponentInput>;
  groupTargets: Array<GroupFormTargetInput>;
  startAfter?: Maybe<StartAfterInput>;
  permissions: Array<PermissionInput>;
};

export type UpsertGroupInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  typeId: Scalars['ID'];
  parentId?: Maybe<Scalars['ID']>;
};

export type UpsertUserInput = {
  id?: Maybe<Scalars['ID']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  memberships: Array<MembershipInput>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  externalId?: Maybe<Scalars['ID']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  memberships: Array<Membership>;
};

export const FormDisplay = gql`
    fragment FormDisplay on Form {
  id
  title
}
    `;
export const GroupType = gql`
    fragment GroupType on GroupType {
  id
  name
  primary
  parents {
    id
    name
  }
  kind
}
    `;
export const GroupBase = gql`
    fragment GroupBase on Group {
  id
  name
  permissions
  type {
    ...GroupType
  }
}
    ${GroupType}`;
export const Group = gql`
    fragment Group on Group {
  __typename
  ...GroupBase
  parent {
    ...GroupBase
  }
  children {
    ...GroupBase
  }
}
    ${GroupBase}`;
export const MembershipType = gql`
    fragment MembershipType on MembershipType {
  id
  name
  permissions
  groupTypes {
    ...GroupType
  }
}
    ${GroupType}`;
export const Membership = gql`
    fragment Membership on Membership {
  __typename
  id
  group {
    ...Group
  }
  type {
    ...MembershipType
  }
}
    ${Group}
${MembershipType}`;
export const User = gql`
    fragment User on User {
  __typename
  id
  firstName
  lastName
  email
  memberships {
    ...Membership
  }
}
    ${Membership}`;
export const FormQuestionOption = gql`
    fragment FormQuestionOption on FormQuestionOption {
  id
  label
}
    `;
export const FormQuestionMultiChoice = gql`
    fragment FormQuestionMultiChoice on FormQuestionMultiChoice {
  __typename
  id
  question
  options {
    ...FormQuestionOption
  }
}
    ${FormQuestionOption}`;
export const FormQuestionSingleChoice = gql`
    fragment FormQuestionSingleChoice on FormQuestionSingleChoice {
  __typename
  id
  question
  options {
    ...FormQuestionOption
  }
}
    ${FormQuestionOption}`;
export const FormQuestionText = gql`
    fragment FormQuestionText on FormQuestionText {
  __typename
  id
  question
}
    `;
export const FormQuestion = gql`
    fragment FormQuestion on FormQuestion {
  __typename
  ... on FormQuestionMultiChoice {
    ...FormQuestionMultiChoice
  }
  ... on FormQuestionSingleChoice {
    ...FormQuestionSingleChoice
  }
  ... on FormQuestionText {
    ...FormQuestionText
  }
}
    ${FormQuestionMultiChoice}
${FormQuestionSingleChoice}
${FormQuestionText}`;
export const FormComponent = gql`
    fragment FormComponent on FormComponent {
  id
  title
  description
  questions {
    ...FormQuestion
  }
}
    ${FormQuestion}`;
export const GroupFormTarget = gql`
    fragment GroupFormTarget on GroupFormTarget {
  __typename
  id
  group {
    ...Group
  }
  type {
    ...MembershipType
  }
  includeChildGroups
}
    ${Group}
${MembershipType}`;
export const Permission = gql`
    fragment Permission on Permission {
  type
  role
  target {
    __typename
    ... on Membership {
      ...Membership
    }
    ... on User {
      ...User
    }
  }
}
    ${Membership}
${User}`;
export const Form = gql`
    fragment Form on Form {
  id
  createdBy {
    ...User
  }
  createdAt
  archivedAt
  publishedAt
  startAt
  endAt
  title
  description
  locked
  components {
    ...FormComponent
  }
  targets {
    ... on GroupFormTarget {
      ...GroupFormTarget
    }
  }
  startAfter {
    form {
      id
      title
    }
    afterDays
  }
  status {
    pendingCount
    completedCount
  }
  permissions {
    ...Permission
  }
}
    ${User}
${FormComponent}
${GroupFormTarget}
${Permission}`;
export const SingleChoiceAnswer = gql`
    fragment SingleChoiceAnswer on SingleChoiceAnswer {
  value
}
    `;
export const MultiChoiceAnswer = gql`
    fragment MultiChoiceAnswer on MultiChoiceAnswer {
  values
}
    `;
export const Answer = gql`
    fragment Answer on Answer {
  __typename
  id
  ... on SingleChoiceAnswer {
    ...SingleChoiceAnswer
  }
  ... on MultiChoiceAnswer {
    ...MultiChoiceAnswer
  }
}
    ${SingleChoiceAnswer}
${MultiChoiceAnswer}`;
export const FormAnswers = gql`
    fragment FormAnswers on FormAnswers {
  id
  form {
    ...Form
  }
  answers {
    ...Answer
  }
}
    ${Form}
${Answer}`;
export const OptionCount = gql`
    fragment OptionCount on OptionCount {
  option {
    ...FormQuestionOption
  }
  count
}
    ${FormQuestionOption}`;
export const QuestionSummary = gql`
    fragment QuestionSummary on QuestionSummary {
  question {
    ... on FormQuestionSingleChoice {
      ...FormQuestionSingleChoice
    }
    ... on FormQuestionMultiChoice {
      ...FormQuestionMultiChoice
    }
    ... on FormQuestionText {
      ...FormQuestionText
    }
  }
  options {
    ...OptionCount
  }
  textAnswers
}
    ${FormQuestionSingleChoice}
${FormQuestionMultiChoice}
${FormQuestionText}
${OptionCount}`;
export const FormSummary = gql`
    fragment FormSummary on FormSummary {
  form {
    ...Form
  }
  questions {
    ...QuestionSummary
  }
}
    ${Form}
${QuestionSummary}`;
export const UpsertForm = gql`
    mutation UpsertForm($input: UpsertFormInput!) {
  upsertForm(input: $input) {
    ...Form
  }
}
    ${Form}`;
export const GetForms = gql`
    query GetForms {
  getForms {
    ...Form
  }
}
    ${Form}`;
export const GetFormsDisplay = gql`
    query GetFormsDisplay {
  getForms {
    ...FormDisplay
  }
}
    ${FormDisplay}`;
export const GetFormToFillById = gql`
    query GetFormToFillById($id: ID!) {
  getFormById(id: $id) {
    id
    title
    components {
      ...FormComponent
    }
    status {
      completed {
        id
      }
      pending {
        id
      }
    }
  }
}
    ${FormComponent}`;
export const GetFormById = gql`
    query GetFormById($id: ID!) {
  getFormById(id: $id) {
    ...Form
  }
  getMembershipTypes {
    ...MembershipType
  }
  getGroups(includeDescendants: true) {
    ...Group
  }
}
    ${Form}
${MembershipType}
${Group}`;
export const RegisterAnswers = gql`
    mutation RegisterAnswers($input: FormAnswersInput!) {
  registerAnswers(input: $input) {
    ...FormAnswers
  }
}
    ${FormAnswers}`;
export const GetFormSummary = gql`
    query GetFormSummary($formId: ID!) {
  getFormSummary(formId: $formId) {
    ...FormSummary
  }
}
    ${FormSummary}`;
export const DeleteForm = gql`
    mutation DeleteForm($input: DeleteFormInput!) {
  deleteForm(input: $input) {
    ...Form
  }
}
    ${Form}`;
export const RestoreForm = gql`
    mutation RestoreForm($input: RestoreFormInput!) {
  restoreForm(input: $input) {
    ...Form
  }
}
    ${Form}`;
export const UnlockForm = gql`
    mutation UnlockForm($input: UnlockFormInput!) {
  unlockForm(input: $input) {
    ...Form
  }
}
    ${Form}`;
export const PublishForm = gql`
    mutation PublishForm($input: PublishFormInput!) {
  publishForm(input: $input) {
    ...Form
  }
}
    ${Form}`;
export const SendReminders = gql`
    mutation SendReminders($input: SendRemindersInput!) {
  sendReminders(input: $input)
}
    `;
export const GetGroups = gql`
    query GetGroups($includeDescendants: Boolean) {
  getGroups(includeDescendants: $includeDescendants) {
    ...Group
  }
  getGroupTypes(includeDescendants: $includeDescendants) {
    ...GroupType
  }
}
    ${Group}
${GroupType}`;
export const GetGroupById = gql`
    query GetGroupById($id: ID!) {
  getGroupById(id: $id) {
    ...Group
  }
}
    ${Group}`;
export const UpsertGroup = gql`
    mutation UpsertGroup($input: UpsertGroupInput!) {
  upsertGroup(input: $input) {
    ...Group
  }
}
    ${Group}`;
export const DeleteGroup = gql`
    mutation DeleteGroup($input: DeleteGroupInput!) {
  deleteGroup(input: $input) {
    ...Group
  }
}
    ${Group}`;
export const GetMembershipTypes = gql`
    query GetMembershipTypes {
  getMembershipTypes {
    ...MembershipType
  }
}
    ${MembershipType}`;
export const Me = gql`
    query Me {
  me {
    ...User
  }
}
    ${User}`;
export const RegisterUser = gql`
    mutation RegisterUser($input: RegisterUserInput!) {
  registerUser(input: $input) {
    ...User
  }
}
    ${User}`;
export const RequestAuthTicket = gql`
    mutation RequestAuthTicket($input: RequestAuthTicketInput!) {
  requestAuthTicket(input: $input)
}
    `;
export const Login = gql`
    mutation Login($input: LoginInput!) {
  login(input: $input) {
    token
  }
}
    `;
export const GetUsers = gql`
    query GetUsers($search: String) {
  getUsers(search: $search) {
    ...User
  }
  getGroups(includeDescendants: true) {
    ...Group
  }
  getMembershipTypes {
    ...MembershipType
  }
}
    ${User}
${Group}
${MembershipType}`;
export const UpsertUser = gql`
    mutation UpsertUser($input: UpsertUserInput!) {
  upsertUser(input: $input) {
    ...User
  }
}
    ${User}`;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "FormQuestion": [
      "FormQuestionMultiChoice",
      "FormQuestionSingleChoice",
      "FormQuestionText"
    ],
    "FormTarget": [
      "GroupFormTarget"
    ],
    "PermissionTarget": [
      "Membership",
      "User"
    ],
    "Answer": [
      "MultiChoiceAnswer",
      "SingleChoiceAnswer",
      "TextAnswer"
    ]
  }
};
      export default result;
    
export type GroupTypeFragment = (
  { __typename?: 'GroupType' }
  & Pick<GroupType, 'id' | 'name' | 'primary' | 'kind'>
  & { parents: Array<(
    { __typename?: 'GroupType' }
    & Pick<GroupType, 'id' | 'name'>
  )> }
);

export type GroupBaseFragment = (
  { __typename?: 'Group' }
  & Pick<Group, 'id' | 'name' | 'permissions'>
  & { type: (
    { __typename?: 'GroupType' }
    & GroupTypeFragment
  ) }
);

export type GroupFragment = (
  { __typename: 'Group' }
  & { parent?: Maybe<(
    { __typename?: 'Group' }
    & GroupBaseFragment
  )>, children: Array<(
    { __typename?: 'Group' }
    & GroupBaseFragment
  )> }
  & GroupBaseFragment
);

export type MembershipTypeFragment = (
  { __typename?: 'MembershipType' }
  & Pick<MembershipType, 'id' | 'name' | 'permissions'>
  & { groupTypes: Array<(
    { __typename?: 'GroupType' }
    & GroupTypeFragment
  )> }
);

export type MembershipFragment = (
  { __typename: 'Membership' }
  & Pick<Membership, 'id'>
  & { group: (
    { __typename?: 'Group' }
    & GroupFragment
  ), type: (
    { __typename?: 'MembershipType' }
    & MembershipTypeFragment
  ) }
);

export type UserFragment = (
  { __typename: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
  & { memberships: Array<(
    { __typename?: 'Membership' }
    & MembershipFragment
  )> }
);

export type FormQuestionOptionFragment = (
  { __typename?: 'FormQuestionOption' }
  & Pick<FormQuestionOption, 'id' | 'label'>
);

export type FormQuestionSingleChoiceFragment = (
  { __typename: 'FormQuestionSingleChoice' }
  & Pick<FormQuestionSingleChoice, 'id' | 'question'>
  & { options: Array<(
    { __typename?: 'FormQuestionOption' }
    & FormQuestionOptionFragment
  )> }
);

export type FormQuestionMultiChoiceFragment = (
  { __typename: 'FormQuestionMultiChoice' }
  & Pick<FormQuestionMultiChoice, 'id' | 'question'>
  & { options: Array<(
    { __typename?: 'FormQuestionOption' }
    & FormQuestionOptionFragment
  )> }
);

export type FormQuestionTextFragment = (
  { __typename: 'FormQuestionText' }
  & Pick<FormQuestionText, 'id' | 'question'>
);

type FormQuestion_FormQuestionMultiChoice_Fragment = (
  { __typename: 'FormQuestionMultiChoice' }
  & FormQuestionMultiChoiceFragment
);

type FormQuestion_FormQuestionSingleChoice_Fragment = (
  { __typename: 'FormQuestionSingleChoice' }
  & FormQuestionSingleChoiceFragment
);

type FormQuestion_FormQuestionText_Fragment = (
  { __typename: 'FormQuestionText' }
  & FormQuestionTextFragment
);

export type FormQuestionFragment = FormQuestion_FormQuestionMultiChoice_Fragment | FormQuestion_FormQuestionSingleChoice_Fragment | FormQuestion_FormQuestionText_Fragment;

export type FormComponentFragment = (
  { __typename?: 'FormComponent' }
  & Pick<FormComponent, 'id' | 'title' | 'description'>
  & { questions: Array<(
    { __typename?: 'FormQuestionMultiChoice' }
    & FormQuestion_FormQuestionMultiChoice_Fragment
  ) | (
    { __typename?: 'FormQuestionSingleChoice' }
    & FormQuestion_FormQuestionSingleChoice_Fragment
  ) | (
    { __typename?: 'FormQuestionText' }
    & FormQuestion_FormQuestionText_Fragment
  )> }
);

export type GroupFormTargetFragment = (
  { __typename: 'GroupFormTarget' }
  & Pick<GroupFormTarget, 'id' | 'includeChildGroups'>
  & { group: (
    { __typename?: 'Group' }
    & GroupFragment
  ), type: (
    { __typename?: 'MembershipType' }
    & MembershipTypeFragment
  ) }
);

export type PermissionFragment = (
  { __typename?: 'Permission' }
  & Pick<Permission, 'type' | 'role'>
  & { target: (
    { __typename: 'Membership' }
    & MembershipFragment
  ) | (
    { __typename: 'User' }
    & UserFragment
  ) }
);

export type FormFragment = (
  { __typename?: 'Form' }
  & Pick<Form, 'id' | 'createdAt' | 'archivedAt' | 'publishedAt' | 'startAt' | 'endAt' | 'title' | 'description' | 'locked'>
  & { createdBy: (
    { __typename?: 'User' }
    & UserFragment
  ), components: Array<(
    { __typename?: 'FormComponent' }
    & FormComponentFragment
  )>, targets: Array<(
    { __typename?: 'GroupFormTarget' }
    & GroupFormTargetFragment
  )>, startAfter?: Maybe<(
    { __typename?: 'StartAfter' }
    & Pick<StartAfter, 'afterDays'>
    & { form: (
      { __typename?: 'Form' }
      & Pick<Form, 'id' | 'title'>
    ) }
  )>, status: (
    { __typename?: 'FormStatus' }
    & Pick<FormStatus, 'pendingCount' | 'completedCount'>
  ), permissions: Array<(
    { __typename?: 'Permission' }
    & PermissionFragment
  )> }
);

export type FormDisplayFragment = (
  { __typename?: 'Form' }
  & Pick<Form, 'id' | 'title'>
);

export type SingleChoiceAnswerFragment = (
  { __typename?: 'SingleChoiceAnswer' }
  & Pick<SingleChoiceAnswer, 'value'>
);

export type MultiChoiceAnswerFragment = (
  { __typename?: 'MultiChoiceAnswer' }
  & Pick<MultiChoiceAnswer, 'values'>
);

type Answer_MultiChoiceAnswer_Fragment = (
  { __typename: 'MultiChoiceAnswer' }
  & Pick<MultiChoiceAnswer, 'id'>
  & MultiChoiceAnswerFragment
);

type Answer_SingleChoiceAnswer_Fragment = (
  { __typename: 'SingleChoiceAnswer' }
  & Pick<SingleChoiceAnswer, 'id'>
  & SingleChoiceAnswerFragment
);

type Answer_TextAnswer_Fragment = (
  { __typename: 'TextAnswer' }
  & Pick<TextAnswer, 'id'>
);

export type AnswerFragment = Answer_MultiChoiceAnswer_Fragment | Answer_SingleChoiceAnswer_Fragment | Answer_TextAnswer_Fragment;

export type FormAnswersFragment = (
  { __typename?: 'FormAnswers' }
  & Pick<FormAnswers, 'id'>
  & { form: (
    { __typename?: 'Form' }
    & FormFragment
  ), answers: Array<(
    { __typename?: 'MultiChoiceAnswer' }
    & Answer_MultiChoiceAnswer_Fragment
  ) | (
    { __typename?: 'SingleChoiceAnswer' }
    & Answer_SingleChoiceAnswer_Fragment
  ) | (
    { __typename?: 'TextAnswer' }
    & Answer_TextAnswer_Fragment
  )> }
);

export type OptionCountFragment = (
  { __typename?: 'OptionCount' }
  & Pick<OptionCount, 'count'>
  & { option: (
    { __typename?: 'FormQuestionOption' }
    & FormQuestionOptionFragment
  ) }
);

export type QuestionSummaryFragment = (
  { __typename?: 'QuestionSummary' }
  & Pick<QuestionSummary, 'textAnswers'>
  & { question: (
    { __typename?: 'FormQuestionMultiChoice' }
    & FormQuestionMultiChoiceFragment
  ) | (
    { __typename?: 'FormQuestionSingleChoice' }
    & FormQuestionSingleChoiceFragment
  ) | (
    { __typename?: 'FormQuestionText' }
    & FormQuestionTextFragment
  ), options?: Maybe<Array<(
    { __typename?: 'OptionCount' }
    & OptionCountFragment
  )>> }
);

export type FormSummaryFragment = (
  { __typename?: 'FormSummary' }
  & { form: (
    { __typename?: 'Form' }
    & FormFragment
  ), questions: Array<(
    { __typename?: 'QuestionSummary' }
    & QuestionSummaryFragment
  )> }
);

export type UpsertFormMutationVariables = Exact<{
  input: UpsertFormInput;
}>;


export type UpsertFormMutation = (
  { __typename?: 'Mutation' }
  & { upsertForm: (
    { __typename?: 'Form' }
    & FormFragment
  ) }
);

export type GetFormsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFormsQuery = (
  { __typename?: 'Query' }
  & { getForms: Array<(
    { __typename?: 'Form' }
    & FormFragment
  )> }
);

export type GetFormsDisplayQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFormsDisplayQuery = (
  { __typename?: 'Query' }
  & { getForms: Array<(
    { __typename?: 'Form' }
    & FormDisplayFragment
  )> }
);

export type GetFormToFillByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetFormToFillByIdQuery = (
  { __typename?: 'Query' }
  & { getFormById: (
    { __typename?: 'Form' }
    & Pick<Form, 'id' | 'title'>
    & { components: Array<(
      { __typename?: 'FormComponent' }
      & FormComponentFragment
    )>, status: (
      { __typename?: 'FormStatus' }
      & { completed: Array<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )>, pending: Array<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )> }
    ) }
  ) }
);

export type GetFormByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetFormByIdQuery = (
  { __typename?: 'Query' }
  & { getFormById: (
    { __typename?: 'Form' }
    & FormFragment
  ), getMembershipTypes: Array<(
    { __typename?: 'MembershipType' }
    & MembershipTypeFragment
  )>, getGroups: Array<(
    { __typename?: 'Group' }
    & GroupFragment
  )> }
);

export type RegisterAnswersMutationVariables = Exact<{
  input: FormAnswersInput;
}>;


export type RegisterAnswersMutation = (
  { __typename?: 'Mutation' }
  & { registerAnswers: (
    { __typename?: 'FormAnswers' }
    & FormAnswersFragment
  ) }
);

export type GetFormSummaryQueryVariables = Exact<{
  formId: Scalars['ID'];
}>;


export type GetFormSummaryQuery = (
  { __typename?: 'Query' }
  & { getFormSummary: (
    { __typename?: 'FormSummary' }
    & FormSummaryFragment
  ) }
);

export type DeleteFormMutationVariables = Exact<{
  input: DeleteFormInput;
}>;


export type DeleteFormMutation = (
  { __typename?: 'Mutation' }
  & { deleteForm: (
    { __typename?: 'Form' }
    & FormFragment
  ) }
);

export type RestoreFormMutationVariables = Exact<{
  input: RestoreFormInput;
}>;


export type RestoreFormMutation = (
  { __typename?: 'Mutation' }
  & { restoreForm: (
    { __typename?: 'Form' }
    & FormFragment
  ) }
);

export type UnlockFormMutationVariables = Exact<{
  input: UnlockFormInput;
}>;


export type UnlockFormMutation = (
  { __typename?: 'Mutation' }
  & { unlockForm: (
    { __typename?: 'Form' }
    & FormFragment
  ) }
);

export type PublishFormMutationVariables = Exact<{
  input: PublishFormInput;
}>;


export type PublishFormMutation = (
  { __typename?: 'Mutation' }
  & { publishForm: (
    { __typename?: 'Form' }
    & FormFragment
  ) }
);

export type SendRemindersMutationVariables = Exact<{
  input: SendRemindersInput;
}>;


export type SendRemindersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendReminders'>
);

export type GetGroupsQueryVariables = Exact<{
  includeDescendants?: Maybe<Scalars['Boolean']>;
}>;


export type GetGroupsQuery = (
  { __typename?: 'Query' }
  & { getGroups: Array<(
    { __typename?: 'Group' }
    & GroupFragment
  )>, getGroupTypes: Array<(
    { __typename?: 'GroupType' }
    & GroupTypeFragment
  )> }
);

export type GetGroupByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetGroupByIdQuery = (
  { __typename?: 'Query' }
  & { getGroupById: (
    { __typename?: 'Group' }
    & GroupFragment
  ) }
);

export type UpsertGroupMutationVariables = Exact<{
  input: UpsertGroupInput;
}>;


export type UpsertGroupMutation = (
  { __typename?: 'Mutation' }
  & { upsertGroup: (
    { __typename?: 'Group' }
    & GroupFragment
  ) }
);

export type DeleteGroupMutationVariables = Exact<{
  input: DeleteGroupInput;
}>;


export type DeleteGroupMutation = (
  { __typename?: 'Mutation' }
  & { deleteGroup: (
    { __typename?: 'Group' }
    & GroupFragment
  ) }
);

export type GetMembershipTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMembershipTypesQuery = (
  { __typename?: 'Query' }
  & { getMembershipTypes: Array<(
    { __typename?: 'MembershipType' }
    & MembershipTypeFragment
  )> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & UserFragment
  )> }
);

export type RegisterUserMutationVariables = Exact<{
  input: RegisterUserInput;
}>;


export type RegisterUserMutation = (
  { __typename?: 'Mutation' }
  & { registerUser: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type RequestAuthTicketMutationVariables = Exact<{
  input: RequestAuthTicketInput;
}>;


export type RequestAuthTicketMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'requestAuthTicket'>
);

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResult' }
    & Pick<LoginResult, 'token'>
  ) }
);

export type GetUsersQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
}>;


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { getUsers: Array<(
    { __typename?: 'User' }
    & UserFragment
  )>, getGroups: Array<(
    { __typename?: 'Group' }
    & GroupFragment
  )>, getMembershipTypes: Array<(
    { __typename?: 'MembershipType' }
    & MembershipTypeFragment
  )> }
);

export type UpsertUserMutationVariables = Exact<{
  input: UpsertUserInput;
}>;


export type UpsertUserMutation = (
  { __typename?: 'Mutation' }
  & { upsertUser: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);
