import * as React from "react";
import { BoxProps } from "@material-ui/core";
import * as _ from "lodash";
import * as api from "../../api";
import SwipeableViews from "react-swipeable-views";
import { ListEx } from "./ListEx";
import * as groupsApi from "../../logic/groups";
import { s } from "src/localization";

export interface Role {
  group: api.t.GroupFragment;
  type: api.t.MembershipTypeFragment;
}

export interface SelectRoleExProps<T extends Role> {
  groups: api.t.GroupFragment[];
  types: api.t.MembershipTypeFragment[];
  selected: T[];
  onClose: (role: Role | "cancel") => void;
}

export const SelectRoleEx = <T extends Role>(
  props: SelectRoleExProps<T> & BoxProps
) => {
  const [index, setIndex] = React.useState(0);
  const [group, setGroup] = React.useState<api.t.GroupFragment | null>(null);
  const { groups, selected, types, onClose, ...blockProps } = props;

  // const msByGroup = _.groupBy(selected, (x) => x.group.id);
  // const typesByGroup = new Map(
  //   groups.map((g) => {
  //     const activeTypes = types.filter((t) =>
  //       t.groupTypes.some((gt) => g.type.id === gt.id)
  //     );

  //     if (!msByGroup[g.id]) {
  //       return [g.id, activeTypes];
  //     } else {
  //       return [
  //         g.id,
  //         activeTypes.filter(
  //           (t) => !msByGroup[g.id].some((x) => x.type.id === t.id)
  //         ),
  //       ];
  //     }
  //   })
  // );

  const typesByGroup = React.useMemo(
    () => groupsApi.getTypesByGroup(groups, types, selected),
    [groups, types, selected]
  );

  const activeGroups = React.useMemo(
    () =>
      _.sortBy(
        groups.filter((g) => typesByGroup.get(g.id)!.length > 0),
        (x) => (x.type.kind === api.t.GroupTypeKind.Course ? 0 : 1)
      ),
    [typesByGroup]
  );

  const onCancel = React.useCallback(() => {
    props.onClose("cancel");
  }, [props.onClose]);

  const onSelectGroup = React.useCallback(
    (group: api.t.GroupFragment) => {
      const types = typesByGroup.get(group.id);
      if (types?.length === 1) {
        onClose({
          group,
          type: types[0],
        });
      } else {
        setIndex(index + 1);
        setGroup(group);
      }
    },
    [index]
  );

  const onSelectType = React.useCallback(
    (type: api.t.MembershipTypeFragment) => {
      onClose({
        group: group!,
        type,
      });
    },
    [group]
  );

  const onGetGroups = React.useCallback(async () => activeGroups, [
    activeGroups,
  ]);

  const onGetTypes = React.useCallback(
    async () => (group ? typesByGroup.get(group.id)! : []),
    [group, typesByGroup]
  );

  const onGetGroupType = React.useCallback((item: api.t.GroupFragment) => {
    if (item.type.kind === api.t.GroupTypeKind.Course) {
      return s("Utbildning");
    } else if (item.type.kind === api.t.GroupTypeKind.Organizational) {
      return item.type.name;
    }
    return "";
  }, []);

  return (
    <SwipeableViews index={index}>
      <ListEx
        onGetItems={onGetGroups}
        getGroup={onGetGroupType}
        getLabel={(item) => item.name}
        onCancel={onCancel}
        filter
        onClick={onSelectGroup}
        height={180}
      />
      <ListEx
        onGetItems={onGetTypes}
        getLabel={(item) => item.name}
        onCancel={onCancel}
        onClick={onSelectType}
        height={180}
      />
    </SwipeableViews>
  );
};
