import * as React from "react";
import * as _ from "lodash";
import { WithQuery } from "../../components/enhancers";
import * as formsApi from "../../../logic/forms";
import * as api from "../../../api";
import * as userApi from "src/logic/user";
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { ButtonEx } from "../../components";
import { s } from "../../../localization";
import produce from "immer";
import { removeInPlace } from "../../../utils/data";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import * as routes from "../../pages/routes";
import * as nav from "../../navigation";

type FormToFill = api.t.GetFormToFillByIdQuery["getFormById"];

const TextQuestion = (props: {
  question: api.t.FormQuestionTextFragment;
  answer: api.t.AnswerInput;
  onAnswer: (answer: api.t.AnswerInput) => void;
}) => {
  const { answer, onAnswer } = props;

  const onChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onAnswer({
        ...answer,
        questionId: props.question.id,
        text: event.target.value,
      });
    },
    [onAnswer, answer, props.question]
  );

  return (
    <TextField
      variant="outlined"
      fullWidth
      value={answer.text || ""}
      placeholder={s("Ange ditt svar...")}
      onChange={onChange}
      multiline
      rows={5}
    />
  );
};

const SingleChoice = (props: {
  question: api.t.FormQuestionSingleChoiceFragment;
  answer: api.t.AnswerInput;
  onAnswer: (answer: api.t.AnswerInput) => void;
}) => {
  const {
    question: { options },
    answer,
    onAnswer,
  } = props;

  const onChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onAnswer({
        ...answer,
        questionId: props.question.id,
        singleChoice: (event.target as HTMLInputElement).value,
      });
    },
    [onAnswer, answer, props.question]
  );

  return (
    <RadioGroup value={answer.singleChoice} onChange={onChange}>
      {options.map((o) => (
        <FormControlLabel
          value={o.id}
          control={<Radio color="primary" />}
          label={o.label}
        />
      ))}
    </RadioGroup>
  );
};

const MultiChoice = (props: {
  question: api.t.FormQuestionMultiChoiceFragment;
  answer: api.t.AnswerInput;
  onAnswer: (answer: api.t.AnswerInput) => void;
}) => {
  const {
    question: { options },
    answer,
    onAnswer,
  } = props;

  const onChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onAnswer({
        ...answer,
        questionId: props.question.id,
        multiChoice: produce(answer.multiChoice || [], (draft) => {
          if (event.target.checked) {
            if (draft.indexOf(event.target.name) === -1) {
              draft.push(event.target.name);
            }
          } else {
            removeInPlace(draft.indexOf(event.target.name), draft);
          }
        }),
      });
    },
    [answer, onAnswer, props.question]
  );

  const set = new Set(answer.multiChoice || []);

  return (
    <FormGroup>
      {options.map((o) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={set.has(o.id)}
              name={o.id}
              onChange={onChange}
              color="primary"
            />
          }
          label={o.label}
        />
      ))}
    </FormGroup>
  );
};

const Options = React.memo(
  (props: {
    question: api.t.FormQuestionFragment;
    onAnswer: (answer: api.t.AnswerInput) => void;
    answer: api.t.AnswerInput;
  }) => {
    const { question, ...otherProps } = props;
    switch (question.__typename) {
      case "FormQuestionSingleChoice": {
        return <SingleChoice question={question} {...otherProps} />;
      }

      case "FormQuestionMultiChoice": {
        return <MultiChoice question={question} {...otherProps} />;
      }

      case "FormQuestionText": {
        return <TextQuestion question={question} {...otherProps} />;
      }

      default: {
        throw new Error("Question type is not supported.");
      }
    }
  }
);

const FormComponent = React.memo(
  (props: {
    form: FormToFill;
    answers: api.t.AnswerInput[];
    onAnswer: (answer: api.t.AnswerInput) => void;
    indexByQuestionId: Map<string, number>;
    onSubmit: () => Promise<void>;
  }) => {
    const { cmpIndex: cmpIndexStr } = useParams<{ cmpIndex: string }>();
    const cmpIndex = parseInt(cmpIndexStr, 10);
    const { form, answers, onAnswer, indexByQuestionId, onSubmit } = props;

    // Validate previous components, and navigate to previous screens if necessary
    React.useEffect(() => {
      for (let i = 0; i < cmpIndex; i++) {
        if (!formsApi.validateAnswers(answers, form.components[i])) {
          nav.navigate(
            {
              path: `${routes.fillForm.path}/:cmpIndex`,
            },
            {
              params: {
                formId: form.id,
                cmpIndex: i,
              },
            }
          );
        }
      }
    }, []);

    const numComponents = form.components.length;
    const component = form.components[cmpIndex];
    const valid = formsApi.validateAnswers(answers, component);

    const next = React.useCallback(() => {
      nav.navigate(
        {
          path: `${routes.fillForm.path}/:cmpIndex`,
        },
        {
          params: {
            formId: form.id,
            cmpIndex: cmpIndex + 1,
          },
        }
      );
    }, [cmpIndex]);

    return (
      <Card elevation={10}>
        <Box p={4}>
          <CardContent>
            <Box display="flex" flexDirection="row">
              <Typography gutterBottom variant="h5">
                {component.title}
              </Typography>
              <Box flex={1} />
            </Box>

            {component.questions.map((q) => {
              return (
                <Box mt={2}>
                  <Divider />
                  <Box mt={2}>
                    <Box mb={1}>
                      <Typography variant="h6">{q.question}</Typography>
                    </Box>
                    <Options
                      question={q}
                      answer={answers[indexByQuestionId.get(q.id)!]}
                      onAnswer={onAnswer}
                    />
                  </Box>
                </Box>
              );
            })}
          </CardContent>
          <Box flexDirection="row" display="flex">
            <Box flex={1} />

            {cmpIndex === numComponents - 1 ? (
              <ButtonEx
                minWidth={100}
                disabled={!valid}
                mt={2}
                onSubmit={onSubmit}
              >
                {s("Skicka in")}
              </ButtonEx>
            ) : (
              <ButtonEx minWidth={100} disabled={!valid} mt={2} onClick={next}>
                {s("Nästa")}
              </ButtonEx>
            )}
          </Box>
        </Box>
      </Card>
    );
  }
);

export const FillForm = React.memo((props: { session: userApi.Session }) => {
  const [form, setForm] = React.useState<FormToFill | null>(null);
  const [answers, setAnswers] = React.useState<api.t.AnswerInput[] | null>(
    null
  );

  const { formId } = useParams<{ formId: string }>();
  const { path } = useRouteMatch();

  React.useEffect(() => {
    (async () => {
      const form = await formsApi.getFormToFillById(formId);
      setForm(form);
      setAnswers(
        _.flatten(
          form.components.map((c) =>
            c.questions.map((q) => formsApi.createAnswerInput(q.id))
          )
        )
      );
    })();
  }, []);

  const onSubmit = React.useCallback(() => {
    return new Promise<void>(async (resolve) => {
      await formsApi.registerAnswers({
        formId: form!.id,
        answers: answers!,
      });

      nav.navigate(
        {
          path: `${path}/success`,
        },
        {
          params: {
            formId,
          },
        }
      );

      resolve();
    });
  }, [form, answers, path]);

  let indexByQuestionId: Map<string, number> = new Map();

  const onAnswer = React.useCallback(
    (answer: api.t.AnswerInput) => {
      if (!answers) {
        return;
      }

      const na = produce(answers, (draft) => {
        draft[indexByQuestionId.get(answer.questionId)!] = answer;
      });

      setAnswers(na);
    },
    [answers, indexByQuestionId]
  );

  React.useEffect(() => {
    if (!form) {
      return;
    }

    let navTo: string | null = null;

    if (form.status.completed.some(({ id }) => id === props.session.user.id)) {
      navTo = `${path}/completed`;
    } else if (
      !form.status.pending.some(({ id }) => id === props.session.user.id)
    ) {
      navTo = `${path}/notfound`;
    }

    if (navTo) {
      nav.navigate(
        {
          path: navTo,
        },
        {
          params: {
            formId,
          },
        }
      );
    }
  }, [form]);

  if (!form || !answers || form.components.length === 0) {
    return null;
  }

  indexByQuestionId = new Map(answers.map((a, index) => [a.questionId, index]));

  return (
    <Box>
      <LinearProgress
        variant="determinate"
        // value={((cmpIndex + 1) / numComponents) * 100}
      />

      <Container maxWidth="md">
        <Box mt={4}>
          <Switch>
            <Route path={`${path}/notfound`} exact>
              <Card elevation={10}>
                <Box p={4}>
                  <CardContent>
                    <Typography variant="h5">
                      {s("Formulär inte tillgängligt")}
                    </Typography>
                    <Box mb={1} />

                    <Typography>
                      {s("Det begärda formuläret är inte tillgänligt.")}
                    </Typography>
                  </CardContent>
                </Box>
              </Card>
            </Route>
            <Route path={`${path}/completed`} exact>
              <Card elevation={10}>
                <Box p={4}>
                  <CardContent>
                    <Typography variant="h5">
                      {s("Formulär besvarat")}
                    </Typography>
                    <Box mb={1} />

                    <Typography>
                      {s("Du har redan besvarat detta formulär.")}
                    </Typography>
                  </CardContent>
                </Box>
              </Card>
            </Route>
            <Route path={`${path}/success`} exact>
              <Card elevation={10}>
                <Box p={4}>
                  <CardContent>
                    <Typography variant="h5">{s("Tack!")}</Typography>
                    <Box mb={1} />

                    <Typography>
                      {s(
                        "Dina svar är registrerade. Nu kan du stänga webbläsaren."
                      )}
                    </Typography>
                  </CardContent>
                </Box>
              </Card>
            </Route>
            <Route path={`${path}/:cmpIndex`}>
              <Box mb={4}>
                <Typography variant="h5" align="center">
                  {form.title}
                </Typography>
              </Box>
              <FormComponent
                onSubmit={onSubmit}
                onAnswer={onAnswer}
                answers={answers}
                form={form}
                indexByQuestionId={indexByQuestionId}
              />
            </Route>
          </Switch>
        </Box>
      </Container>
    </Box>
  );
});
