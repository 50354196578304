import * as React from "react";

export function useKey(key: string) {
  const [down, setDown] = React.useState(false);

  const onDown = React.useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key === key) {
        setDown(true);
      }
    },
    [key]
  );

  const onUp = React.useCallback((ev: KeyboardEvent) => {
    if (ev.key === key) {
      setDown(false);
    }
  }, []);

  React.useEffect(() => {
    window.addEventListener("keydown", onDown);
    window.addEventListener("keyup", onUp);
    return () => {
      window.removeEventListener("keydown", onDown);
      window.removeEventListener("keyup", onUp);
    };
  }, [key]);

  return down;
}
