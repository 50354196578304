import { Config } from "./config";

const config: Config = {
  web: {
    uri: "http://localhost:5000",
  },
  api: {
    // uri: "http://203.151.236.153:8082/graphql"
    uri: "http://localhost:8082/graphql",
  },
  bugsnag: {
    apiKey: "",
    releaseStage: "dev",
  },
};

export default config;
