import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { s } from "src/localization";
import { ButtonEx } from "src/ui/components";
import { DialogProps } from "src/ui/dialog";
import * as api from "src/api";
import * as dateFns from "date-fns";

export interface ConfirmPublishProps extends DialogProps<"cancel" | "confirm"> {
  form: api.t.FormFragment;
}

export const ConfirmPublishDialog = (props: ConfirmPublishProps) => {
  const { form } = props;

  const cancel = React.useCallback(() => {
    props.onClose("cancel");
  }, [props.onClose]);

  const confirm = React.useCallback(() => {
    props.onClose("confirm");
  }, [props.onClose]);

  const future = new Date(form.startAt) > new Date();

  return (
    <Dialog
      open
      onClose={props.onClose}
      disableBackdropClick={!props.dismissable}
      disableEnforceFocus
    >
      <DialogTitle>{s("Bekräfta publicering")}</DialogTitle>
      <DialogContent dividers>
        <Box mb={3}>
          <DialogContentText>
            {s(
              "När du publicerar ett formulär kommer det att låsas för redigering. Dessutom kommer nedanstående målgrupper att bjudas in via e-post."
            )}
          </DialogContentText>
        </Box>

        <DialogContentText>{s("Målgrupp(er):")}</DialogContentText>
        <Box ml={5} mb={4}>
          {form.targets.map((t, index) => (
            <ul>
              <li>
                <Typography variant="body1">
                  {t.group.name} ({t.type.name})
                </Typography>
              </li>
            </ul>
          ))}
        </Box>
        {(future || form.startAfter) && (
          <Box mb={4}>
            <DialogContentText>
              {s(
                "OBS: Inbjudningar skickas först efter att följande kriterie(r) är uppfyllt:"
              )}
            </DialogContentText>
            <Box ml={5}>
              <ul>
                {future && (
                  <li>
                    <Typography variant="body1">
                      {s("Dagens datum är {date} eller senare", {
                        date: dateFns.format(
                          new Date(form.startAt),
                          "yyyy-MM-dd"
                        ),
                      })}
                    </Typography>
                  </li>
                )}
                {form.startAfter && (
                  <li>
                    <Typography variant="body1">
                      {s("{days} dagar efter att mottagaren har fyllt i:", {
                        days: form.startAfter.afterDays,
                      })}
                      <b> {form.startAfter.form.title}</b>
                    </Typography>
                  </li>
                )}
              </ul>
            </Box>
          </Box>
        )}
        <DialogContentText>
          {s("Vill du fortsätta att publicera formuläret?")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonEx onClick={cancel} variant="text" color="primary">
          {s("Avbryt")}
        </ButtonEx>
        <ButtonEx onClick={confirm}>{s("Ja, publicera formuläret")}</ButtonEx>
      </DialogActions>
    </Dialog>
  );
};
