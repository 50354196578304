import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import * as React from "react";
import { s } from "../../localization";
import { DialogProps } from "../dialog";
import { ButtonEx } from "./SubmitButton";

export interface ErrorDialogProps extends DialogProps<"cancel" | "confirm"> {
  title: string;
  description: string;
}

export const ErrorDialog = (props: ErrorDialogProps) => {
  const cancel = React.useCallback(() => {
    props.onClose("cancel");
  }, [props.onClose]);

  return (
    <Dialog
      open
      onClose={props.onClose}
      disableBackdropClick={!props.dismissable}
      disableEnforceFocus
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{props.description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonEx onClick={cancel} variant="text" color="primary">
          {s("Tillbaka")}
        </ButtonEx>
      </DialogActions>
    </Dialog>
  );
};
